import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../../constant";
import { AbortTransferRequestAPI, AddTransferSuggestAPI, BookTransferRequestAPI, DoneTransferRequestAPI, GetTransferRequestsAPI, GetTransferRequestsLogAPI, SuccessTransferRequestAPI } from "../../Api";

export const GetTransferRequestsAction = createAsyncThunk("cms/transferRequest/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetTransferRequestsAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
            + "&status=" + info.status);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DoneTransferRequestsAction = createAsyncThunk("cms/transferRequest/done", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(DoneTransferRequestAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddTransferSuggestAction = createAsyncThunk("cms/transfer/suggest/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddTransferSuggestAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const SuccessTransferRequestAction = createAsyncThunk("cms/transfer/success", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(SuccessTransferRequestAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AbortTransferRequestAction = createAsyncThunk("cms/transfer/abort", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AbortTransferRequestAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const BookTransferRequestAction = createAsyncThunk("cms/transfer/book", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(BookTransferRequestAPI + info.id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetTransferRequestsLogAction = createAsyncThunk("cms/transfer/log", async (info, { rejectWithValue }) => {
    try {
        let route;
        if (info.phone) {
            route = GetTransferRequestsLogAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
                + "&status=" + info.status + "&phone_number=" + info.phone
        }
        else {
            route = GetTransferRequestsLogAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
                + "&status=" + info.status
        }
        let { data } = await axiosInstance.get(route);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
