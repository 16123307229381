import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";

const Header = ({ header, createModal, path }) => {
  return path === undefined ? (
    <div className="page-header">
      <h5>{header}</h5>
      <i className="pi pi-plus" onClick={() => createModal(true)} />
    </div>
  ) : (
    <div className="page-header">
      <h5>{header}</h5>
      <Link to={path}>
        <i className="pi pi-list" />
      </Link>
    </div>
  );
};

export default Header;
