import { createSlice } from '@reduxjs/toolkit';
import { AddAdminAction, ChangeAdminPasswordAction, DeleteAdminAction, GetAdminsAction, UpdateAdminAction } from '../actions/AdminAction';
export const adminReducer = createSlice({
    name: "Admin Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        admins: [],
        adminInfo: [],
    },
    reducers: {
        adminUnmount: (state) => {
            state.admins = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAdminsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetAdminsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.admins = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetAdminsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddAdminAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddAdminAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddAdminAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateAdminAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateAdminAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateAdminAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteAdminAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteAdminAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteAdminAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(ChangeAdminPasswordAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(ChangeAdminPasswordAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(ChangeAdminPasswordAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            
    }
});

export default adminReducer.reducer;
export const { adminUnmount } = adminReducer.actions;
