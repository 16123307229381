import { createSlice } from '@reduxjs/toolkit';
import { AddCountryAction, DeleteCountryAction, GetAllCitiesAction, GetCitiesByCountryAction, GetCountriesAction, UpdateCountryAction } from '../actions/CountryAction';
export const countryReducer = createSlice({
    name: "Country Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        countries: [],
        cities: [],
        countryInfo: [],
        allCities: []
    },
    reducers: {
        countryUnmount: (state) => {
            state.countries = [];
        }, cityUnmount: (state) => {
            state.cities = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetCountriesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetCountriesAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                try {
                    if (payload.data.data === undefined) {
                        state.countries = payload.data;
                    } else {
                        state.countries = payload.data.data;
                    }
                    state.totalItems = payload.data.total;
                    state.currentPage = payload.data.current_page;
                } catch (err) {
                    // console.log(err)
                }
            })
            .addCase(GetCountriesAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetCitiesByCountryAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetCitiesByCountryAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.cities = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetCitiesByCountryAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddCountryAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddCountryAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddCountryAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateCountryAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateCountryAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateCountryAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteCountryAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteCountryAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteCountryAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetAllCitiesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetAllCitiesAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.allCities = payload.data
            })
            .addCase(GetAllCitiesAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
    }
});

export default countryReducer.reducer;
export const { countryUnmount, cityUnmount } = countryReducer.actions;
