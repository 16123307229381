import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/Sidebar";
import Login from "./pages/login/Login";
import NotFound from "./components/unauthorized/NotFound";
import Groups from "./pages/groups/Groups";
import ShowGroup from "./pages/groups/actions/ShowGroup";
import CarCategories from "./pages/car-category/CarCategories";
import Cars from "./pages/car/Cars";
import Admins from "./pages/admin/Admins";
import Country from "./pages/country/Country";
import City from "./pages/city/City";
import Airports from "./pages/airport/Airports";
import Visas from "./pages/visa/Visas";
// import Insurances from "./pages/health-insurance/Insurances";
import Transfers from "./pages/transfer/Transfers";
import Hotels from "./pages/hotels/Hotels";
import TransfersRequests from "./pages/transfer-requests/TransfersRequests";
import ShowTransferRequest from "./pages/transfer-requests/actions/ShowTransferRequest";
import VisaRequests from "./pages/visa-requests/VisaRequests";
import ShowVisaRequest from "./pages/visa-requests/actions/ShowVisaRequest";
import TravelInsuranceRequests from "./pages/health-insurance-requests/TravelInsuranceRequests";
import ShowTravelInsuranceRequest from "./pages/health-insurance-requests/actions/ShowTravelInsuranceRequest";
import HotelRequests from "./pages/hotel-requests/HotelRequests";
import ShowHotelRequest from "./pages/hotel-requests/actions/ShowHotelRequest";
import FlightsRequests from "./pages/flight-requests/FlightsRequests";
import ShowFlightRequest from "./pages/flight-requests/actions/ShowFlightRequest";
import Notification from "./components/notification/Notification";
import Feedback from "./pages/feedback/Feedback";
import VisaRequetsLog from "./pages/visa-log/VisaRequetsLog";
import InsuranceRequetsLog from "./pages/health-insurance-log/InsuranceRequetsLog";
import TransferRequetsLog from "./pages/transfer-log/TransferRequetsLog";
import TransferLogInfo from "./pages/transfer-log/TransferLogInfo";
import HotelRequetsLog from "./pages/hotel-log/HotelRequetsLog";
import FlightRequetsLog from "./pages/flight-log/FlightRequetsLog";
import Offers from "./pages/offer/Offers";
import HotelLogInfo from "./pages/hotel-log/HotelLogInfo";
import TravelInsuranceLogInfo from "./pages/health-insurance-log/TravelInsuranceLogInfo";
import VisaLogInfo from "./pages/visa-log/VisaLogInfo";
import FlightRequestLog from "./pages/flight-log/FlightRequestLog";
import Users from "./pages/users/Users";
import About from "./pages/about/About";
import Welcome from "./pages/welcome/Welcome";
import Notifications from "./pages/notifications/Notifications";
import UserProfile from "./pages/user-profile/UserProfile";
import Statistics from "./pages/statistics/Statistics";
import PushNotification from "./pages/push-notification/PushNotification";


const AppRoutes = ({ toast }) => {

  const isLoginPage = window.location.pathname === '/' || window.location.pathname === '/login';

  return (
    <Router basename="/">
      {!isLoginPage && <Sidebar />}
      <div className="container">
        <Notification />
        {!isLoginPage && <Navbar toast={toast} />}
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Login toast={toast} />} />
          <Route path="/login" element={<Login toast={toast} />} />
          <Route path="/statistics" element={<Statistics toast={toast} />} />
          <Route path="/welcome" element={<Welcome toast={toast} />} />
          <Route path="/about" element={<About toast={toast} />} />
          <Route path="/admins" element={<Admins toast={toast} />} />
          <Route path="/feedback" element={<Feedback toast={toast} />} />
          <Route path="/notifications" element={<Notifications toast={toast} />} />
          <Route path="/offers" element={<Offers toast={toast} />} />
          <Route path="/push-notification" element={<PushNotification toast={toast} />} />

          <Route path='/users'>
            <Route index element={<Users toast={toast} />} />
            <Route path=':userId' element={<UserProfile toast={toast} />} />
          </Route>

          <Route path='/groups'>
            <Route index element={<Groups toast={toast} />} />
            <Route path=':groupId' element={<ShowGroup toast={toast} />} />
          </Route>

          <Route path='/car-categories'>
            <Route index element={<CarCategories toast={toast} />} />
            <Route path=':categoryId' element={<Cars toast={toast} />} />
          </Route>

          <Route path='/countries'>
            <Route index element={<Country toast={toast} />} />
            <Route path='cities/:countryId' element={<City toast={toast} />} />
            <Route path='cities/:countryId/airports/:cityId' element={<Airports toast={toast} />} />
          </Route>

          <Route path='/visa'>
            <Route index element={<Visas toast={toast} />} />
          </Route>

          <Route path='/visa-requests'>
            <Route index element={<VisaRequests toast={toast} />} />
            <Route path=':visaId' element={<ShowVisaRequest toast={toast} />} />
          </Route>

          <Route path='/visa-requests-log'>
            <Route index element={<VisaRequetsLog toast={toast} />} />
            <Route path=':visaId' element={<VisaLogInfo toast={toast} />} />
          </Route>

          {/*  <Route path='/travel-insurance'>
            <Route index element={<Insurances toast={toast} />} />
          </Route> */}

          <Route path='/travel-insurance-requests'>
            <Route index element={<TravelInsuranceRequests toast={toast} />} />
            <Route path=':insuranceId' element={<ShowTravelInsuranceRequest toast={toast} />} />
          </Route>

          <Route path='/travel-insurance-requests-log'>
            <Route index element={<InsuranceRequetsLog toast={toast} />} />
            <Route path=':insuranceId' element={<TravelInsuranceLogInfo toast={toast} />} />
          </Route>

          <Route path='/transfer'>
            <Route index element={<Transfers toast={toast} />} />
          </Route>

          <Route path='/transfer-requests'>
            <Route index element={<TransfersRequests toast={toast} />} />
            <Route path=':transferId' element={<ShowTransferRequest toast={toast} />} />
          </Route>

          <Route path='/transfer-requests-log'>
            <Route index element={<TransferRequetsLog toast={toast} />} />
            <Route path=':transferId' element={<TransferLogInfo toast={toast} />} />
          </Route>

          <Route path='/hotels'>
            <Route index element={<Hotels toast={toast} />} />
          </Route>

          <Route path='/hotel-requests'>
            <Route index element={<HotelRequests toast={toast} />} />
            <Route path=':hotelId' element={<ShowHotelRequest toast={toast} />} />
          </Route>

          <Route path='/hotel-requests-log'>
            <Route index element={<HotelRequetsLog toast={toast} />} />
            <Route path=':hotelId' element={<HotelLogInfo toast={toast} />} />
          </Route>

          <Route path='/flight-requests'>
            <Route index element={<FlightsRequests toast={toast} />} />
            <Route path=':flightId' element={<ShowFlightRequest toast={toast} />} />
          </Route>

          <Route path='/flight-requests-log'>
            <Route index element={<FlightRequetsLog toast={toast} />} />
            <Route path=':flightId' element={<FlightRequestLog toast={toast} />} />
          </Route>
        </Routes>
      </div>
    </Router>
  );
};

export default AppRoutes;
