import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import LoadingFS from "../../components/loading/LoadingFS";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CityDT from "./CityDT";
import AddCity from "./actions/AddCity";
import DeleteCity from "./actions/DeleteCity";
import UpdateCity from "./actions/UpdateCity";
import { GetCountriesAction } from "../../redux/actions/CountryAction";
import { showError, showSuccess } from "../../utils/ToastService";
import { unwrapResult } from "@reduxjs/toolkit";

const City = ({ toast }) => {
  const dispatch = useDispatch();
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);
  const { loading } = useSelector((state) => state.countries);
  const { countryId } = useParams();
  const getCountriesHandler = () => {
    let info = { isPaginate: 0 };
    dispatch(GetCountriesAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="sticky-container">
      {loading && <LoadingFS />}
      <CityDT
        countryId={countryId}
        toast={toast}
        createModal={(visible, rowData, basicRows) => {
          setCreateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        deleteModal={(visible, rowData, basicRows) => {
          setDeleteModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        updateModal={(visible, rowData, basicRows) => {
          setUpdateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />

      <Dialog
        header="Add City"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={createModal}
        onHide={() => setCreateModal(false)}
      >
        <AddCity
          countryId={countryId}
          basicRows={basicRows}
          createModal={(e) => setCreateModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Update City"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
        onShow={getCountriesHandler}
      >
        <UpdateCity
          countryId={countryId}
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          updateModal={(e) => setUpdateModal(e)}
        />
      </Dialog>

      <Dialog
        closeOnEscape
        header="Delete City"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
        onShow={() => {}}
      >
        <DeleteCity
          countryId={countryId}
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          deleteModal={(e) => setDeleteModal(e)}
        />
      </Dialog>
    </div>
  );
};

export default City;
