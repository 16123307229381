export const formatNumberWithCommas = (number) => {
    if (number)
        return number.toLocaleString();
    return null
}

export const convertSyrianPhoneNumber = (phoneNumber) => {
    if (phoneNumber?.startsWith('09')) {
        return ('+963' + phoneNumber.substring(1))
    }
    if (phoneNumber?.startsWith('9')) {
        return ('+963' + phoneNumber)
    }
    return phoneNumber;
}
