import { createSlice } from '@reduxjs/toolkit';
import { AbortTransferRequestAction, AddTransferSuggestAction, DoneTransferRequestsAction, GetTransferRequestsAction, GetTransferRequestsLogAction, SuccessTransferRequestAction } from '../../actions/transfer/TransferRequestAction';

export const transferRequestReducer = createSlice({
    name: "Transfer Requests Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        transferRequests: [],
        log: [],
    },
    reducers: {
        transfersUnmount: (state) => {
            state.log = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetTransferRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetTransferRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.transferRequests = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetTransferRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetTransferRequestsLogAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetTransferRequestsLogAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.log = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetTransferRequestsLogAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })


            .addCase(AddTransferSuggestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddTransferSuggestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddTransferSuggestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DoneTransferRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DoneTransferRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DoneTransferRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(SuccessTransferRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(SuccessTransferRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(SuccessTransferRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AbortTransferRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AbortTransferRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AbortTransferRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
    }
});
export default transferRequestReducer.reducer;
export const { transfersUnmount } = transferRequestReducer.actions;
