import React from "react";
import LoadingFS from "../../components/loading/LoadingFS";
import { useSelector } from "react-redux";
import FeedbackDT from "./FeedbackDT";

const Feedback = ({ toast }) => {
  const { loading } = useSelector((state) => state.feedback);

  return (
    <div className="sticky-container">
      {loading && <LoadingFS />}
      <FeedbackDT toast={toast} />
    </div>
  );
};

export default Feedback;
