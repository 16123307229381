import { Button } from "primereact/button";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import {
  GetTransferAction,
  UpdateTransferAction,
} from "../../../redux/actions/transfer/TransferAction";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { unwrapResult } from "@reduxjs/toolkit";

const UpdateTransfer = ({
  rowData,
  basicRows,
  updateModal,
  toast,
  allCities,
}) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.transfers);
  const [transferPrice, setTransferPrice] = useState(undefined);
  const { carCategories } = useSelector((state) => state.carCategory);
  const [fromCity, setFromCity] = useState(rowData.city_from);
  const [toCity, setToCity] = useState(rowData.city_to);
  const [carCategory, setCarCategory] = useState(rowData.car_category);

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("price", transferPrice ?? rowData.price);
    obj.append("city_from_id", fromCity.id);
    obj.append("city_to_id", toCity.id);
    obj.append("car_category_id", carCategory.id);

    let data = { id: rowData.id, obj: obj };
    dispatch(UpdateTransferAction(data))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        updateModal(false);
        dispatch(GetTransferAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  const groupedItemTemplate = (option) => {
    return (
      <div className="country">
        <h3>{option.name}</h3>
      </div>
    );
  };
  return (
    <div className="container">
      <form className="add-transfer" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputNumber
            className="custom-number-input"
            value={rowData.price}
            placeholder="Transfer Price"
            onChange={(e) => setTransferPrice(e.value)}
          />
        </div>

        <div className="input-wrapper">
          <h2>From</h2>
          <Dropdown
            appendTo={document.querySelector(".container")}
            value={fromCity}
            onChange={(e) => setFromCity(e.value)}
            options={allCities}
            optionLabel="name"
            optionGroupLabel="name"
            optionGroupChildren="cities"
            valueTemplate={fromCity.name}
            optionGroupTemplate={groupedItemTemplate}
            placeholder="Select a City"
            filter
          />
        </div>

        <div className="input-wrapper">
          <h2>To</h2>
          <Dropdown
            appendTo={document.querySelector(".container")}
            value={toCity}
            onChange={(e) => setToCity(e.value)}
            options={allCities}
            optionLabel="name"
            optionGroupLabel="name"
            optionGroupChildren="cities"
            valueTemplate={toCity.name}
            optionGroupTemplate={groupedItemTemplate}
            placeholder="Select a City"
            filter
          />
        </div>
        <div className="input-wrapper">
          <h2>Car Type</h2>
          <Dropdown
            valueTemplate={carCategory.name}
            value={carCategory}
            onChange={(e) => setCarCategory(e.value)}
            options={carCategories}
            optionLabel="name"
            placeholder="Select a Car Category"
            filter
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            type="button"
            onClick={() => updateModal(false)}
          />
          <Button
            disabled={!fromCity || !toCity || !carCategory}
            size="small"
            label="Update"
            icon="pi pi-check"
            className="info"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateTransfer;
