import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCarCategoryAction,
  GetCarCategoriesAction,
} from "../../../redux/actions/car/CarGategoryAction";
import { showError, showSuccess } from "../../../utils/ToastService";
import { unwrapResult } from "@reduxjs/toolkit";

const AddCategory = ({ basicRows, createModal, toast }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.carCategory);
  const [category, setCategoryName] = useState("");
  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("name", category);

    dispatch(AddCarCategoryAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        createModal(false);
        dispatch(GetCarCategoriesAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="add-category" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            unstyled
            className="custom-input"
            type="text"
            placeholder="Car Category Name"
            onChange={(e) => setCategoryName(e.target.value)}
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="button"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default AddCategory;
