import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddHotelSuggest from "./AddHotelSuggest";
import { Image } from "primereact/image";
import { imageURL } from "../../../redux/Api";
import { Button } from "primereact/button";
import { Rating } from "primereact/rating";
import { formatNumberWithCommas } from "../../../utils/Formatters";
import "../../../assets/pages/show-request/ShowRequest.scss";
import { decodeBase64 } from "../../../utils/EncodeDecode";

const ShowHotelRequest = ({ toast }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const encodedData = params.get("data");
  const decodedData = JSON.parse(decodeBase64(encodedData));
  const navigate = useNavigate();
  const serviceType = () => {
    switch (decodedData?.type) {
      case 0:
        return "Room";
      case 1:
        return "Suite";
      case 2:
        return "Duplex";
      default:
        break;
    }
  };
  return (
    <div className="show-request">
      <div className="image-containers">
        <Button
          onClick={() => {
            navigate(-1);
          }}
          icon="pi pi-arrow-left"
          className="backward"
          rounded
          label="Back"
          text
          aria-label="Filter"
          severity="info"
        />
        <div className="info service">
          <strong>Service Type:</strong> {serviceType()}
        </div>
        <div className="title">
          <h3>Booking Date</h3>
        </div>
        <div className="info">
          <strong>Start Date:</strong> {decodedData.start}
        </div>
        <div className="info">
          <strong>End Date:</strong> {decodedData.end}
        </div>
        <div className="title ">
          <h3>Booking Information</h3>
        </div>
        <div className="info">
          <strong>Request Price: </strong>
          {decodedData.price === null
            ? "Not Assigned"
            : formatNumberWithCommas(decodedData.price)}
        </div>
        <div className="info">
          <strong>Target Place:</strong> {decodedData?.city?.country?.name},{" "}
          {decodedData?.city?.name}
        </div>
        <div className="info">
          <strong>Number of Rooms:</strong> {decodedData?.room_number}
        </div>
        <div className="info">
          <strong>Number of Adults:</strong> {decodedData.adults_number}
        </div>
        <div className="info">
          <strong>Number of Children:</strong> {decodedData.children_number}
        </div>
        <div className="title ">
          <h3>Hotel Information</h3>
        </div>

        {decodedData?.hotel_name ? (
          <div className="info">
            <strong>Hotel Name:</strong> {decodedData?.hotel_name}
          </div>
        ) : (
          <div className="info">
            <strong>Required Rate:</strong>
            <Rating value={decodedData?.rate} readOnly cancel={false} />
          </div>
        )}

        <div className="title">
          <h3>User Information</h3>
        </div>
        <div className="info">
          <strong>User:</strong> {decodedData.user?.first_name}{" "}
          {decodedData.user?.last_name}
        </div>
        <div className="info">
          <strong>User Phone Number:</strong> {decodedData.user?.phone_number}
        </div>
        {decodedData?.notes && (
          <div className="info">
            <strong>Notes:</strong> {decodedData.notes}
          </div>
        )}

        {decodedData.ticket_images.length !== 0 && (
          <div className="ticket-images">
            <h3>Document Images</h3>
            {decodedData.ticket_images.map((item, index) => {
              return (
                <Image
                  key={index}
                  className="image"
                  src={imageURL + item.path}
                  alt="document_page"
                  loading="lazy"
                  preview
                  downloadable
                  children={document.querySelector(".passport-image")}
                />
              );
            })}
          </div>
        )}
        {decodedData.is_valid === null && decodedData.admin !== null && (
          <AddHotelSuggest
            id={decodedData.id}
            toast={toast}
            isValid={decodedData.is_valid}
            type={decodedData.type}
          />
        )}
      </div>
    </div>
  );
};

export default ShowHotelRequest;
