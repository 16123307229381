import { createSlice } from '@reduxjs/toolkit';
import { AddCarAction, DeleteCarAction, GetCarsAction, UpdateCarAction } from '../../actions/car/CarAction';
export const carReducer = createSlice({
    name: "Car Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        cars: [],
    },
    reducers: {
        carsUnmount: (state) => {
            state.cars = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetCarsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetCarsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                try {
                    if (payload.data.data === undefined) {
                        state.cars = payload.data;
                    } else {
                        state.cars = payload.data.data;
                    }
                    state.totalItems = payload.data.total;
                    state.currentPage = payload.data.current_page;
                } catch (err) {
                    // console.log(err)
                }
            })
            .addCase(GetCarsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddCarAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddCarAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddCarAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateCarAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateCarAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateCarAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteCarAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteCarAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteCarAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })


    }
});
export default carReducer.reducer;
export const { carsUnmount } = carReducer.actions;
