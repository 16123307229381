import React, { useState } from "react";
import "./Statistics.scss";
import ProfitCard from "../../components/profit-card/ProfitCard";
import TwoLevelPieChart from "../../components/chart/TwoLevelPieChart";
import visaIcon from "../../assets/images/visa_notification.png";
import transferIcon from "../../assets/images/transfer_notification.png";
import hotelIcon from "../../assets/images/hotel_notification.png";
import flightIcon from "../../assets/images/flight_notification_icon.png";
import insuranceIcon from "../../assets/images/insurance_notification.png";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetLineChartAction,
  GetStatisticsAction,
} from "../../redux/actions/StatisticAction";
import LoadingFS from "../../components/loading/LoadingFS";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { showError, showSuccess } from "../../utils/ToastService";
import LineChart from "../../components/line-chart/LineChart";
import { Dropdown } from "primereact/dropdown";
import { Message } from "primereact/message";
import { unwrapResult } from "@reduxjs/toolkit";

const Statistics = ({ toast }) => {
  const dispatch = useDispatch();
  const {
    loading,
    hotelStatistic,
    flightStatistic,
    insuranceStatistic,
    transferStatistic,
    visaStatistic,
  } = useSelector((state) => state.statistics);
  const [selectedOption, setSelectedOption] = useState();
  const { lineChartData, barChartData } = useSelector(
    (state) => state.statistics
  );

  const options = [
    { label: "Visa", value: "1" },
    { label: "Transfer", value: "2" },
    { label: "Insurance", value: "3" },
    { label: "Hotel", value: "4" },
    { label: "Flight", value: "5" },
  ];
  const handleSelect = (e) => {
    setSelectedOption(e.value);
  };
  useEffect(() => {
    dispatch(GetStatisticsAction())
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  }, [dispatch, toast]);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFromDateChange = (e) => {
    setFromDate(e.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.value);
  };

  const submitChart = (e) => {
    e.preventDefault();
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    const formattedFrom = new Date(fromDate)
      .toLocaleDateString("en-GB", options)
      .replace(/(^|\/)0+/g, "$1");
    const formattedTo = new Date(toDate)
      .toLocaleDateString("en-GB", options)
      .replace(/(^|\/)0+/g, "$1");
    // console.log(formattedFrom, formattedTo);
    let formData = new FormData();
    formData.append("start_date", formattedFrom);
    formData.append("end_date", formattedTo);
    let info = {
      obj: formData,
      type: selectedOption,
    };
    dispatch(GetLineChartAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <>
      {loading && <LoadingFS />}
      <div className="statistics">
        <div className="charts">
          <TwoLevelPieChart toast={toast} />
        </div>
        <div className="line-charts-container">
          <form className="date-pickers" onSubmit={submitChart}>
            <Calendar
              required
              value={fromDate}
              dateFormat="dd/mm/yy"
              onChange={handleFromDateChange}
              placeholder="From"
            />
            <Calendar
              required
              value={toDate}
              dateFormat="dd/mm/yy"
              onChange={handleToDateChange}
              placeholder="To"
            />
            <Dropdown
              required
              value={selectedOption}
              options={options}
              onChange={handleSelect}
              placeholder="Select an option"
            />
            <Button
              icon="pi pi-arrow-right"
              severity="info"
              aria-label="Cancel"
              type="submit"
            />
          </form>
          {barChartData.length !== 0 ? (
            <div className="chart-container">
              <LineChart toast={toast} data={lineChartData} type={0} />
              <LineChart toast={toast} data={barChartData} type={1} />
            </div>
          ) : (
            <Message severity="warn" text="Nothing To Show" />
          )}
        </div>
        <div className="profit-cards-container">
          <ProfitCard
            number={hotelStatistic?.num}
            name="Hotel"
            revenueVal={hotelStatistic?.rateProfit}
            lossVal={hotelStatistic?.rateLoss}
            logoUrl={hotelIcon}
          />
          <ProfitCard
            number={visaStatistic?.num}
            name="Visa"
            revenueVal={visaStatistic?.rateProfit}
            lossVal={visaStatistic?.rateLoss}
            logoUrl={visaIcon}
          />
          <ProfitCard
            number={flightStatistic?.num}
            name="Flight"
            revenueVal={flightStatistic?.rateProfit}
            lossVal={flightStatistic?.rateLoss}
            logoUrl={flightIcon}
          />
          <ProfitCard
            number={transferStatistic?.num}
            name="Transfer"
            revenueVal={transferStatistic?.rateProfit}
            lossVal={transferStatistic?.rateLoss}
            logoUrl={transferIcon}
          />
          <ProfitCard
            number={insuranceStatistic?.num}
            name="Insurance"
            revenueVal={insuranceStatistic?.rateProfit}
            lossVal={insuranceStatistic?.rateLoss}
            logoUrl={insuranceIcon}
          />
        </div>
      </div>
    </>
  );
};

export default Statistics;
