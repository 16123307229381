import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../../constant";
import { AddInsuranceAPI, DeleteInsuranceAPI, GetInsurancesAPI, UpdateInsuranceAPI } from "../../Api";

export const GetInsuranceAction = createAsyncThunk("cms/Insurance/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetInsurancesAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddInsuranceAction = createAsyncThunk("cms/Insurance/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddInsuranceAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateInsuranceAction = createAsyncThunk("cms/Insurance/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(UpdateInsuranceAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteInsuranceAction = createAsyncThunk("cms/Insurance/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteInsuranceAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
