import { Button } from "primereact/button";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { Dropdown } from "primereact/dropdown";
import {
  AddTransferAction,
  GetTransferAction,
} from "../../../redux/actions/transfer/TransferAction";
import { InputNumber } from "primereact/inputnumber";
import { unwrapResult } from "@reduxjs/toolkit";

const AddTransfer = ({ basicRows, createModal, toast, allCities }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.transfers);
  const { carCategories } = useSelector((state) => state.carCategory);
  const [transferPrice, setTransferPrice] = useState("");
  const [fromCity, setFromCity] = useState(null);
  const [toCity, setToCity] = useState(null);
  const [carCategory, setCarCategory] = useState(null);

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("price", transferPrice);
    obj.append("city_from_id", fromCity.id);
    obj.append("city_to_id", toCity.id);
    obj.append("car_category_id", carCategory.id);

    dispatch(AddTransferAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        createModal(false);
        dispatch(GetTransferAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  const groupedItemTemplate = (option) => {
    return (
      <div className="country">
        <h3>{option.name}</h3>
      </div>
    );
  };

  return (
    <div className="container">
      <form className="add-transfer" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputNumber
            className="custom-number-input"
            required
            value={transferPrice}
            placeholder="Transfer Price"
            onValueChange={(e) => setTransferPrice(e.value)}
          />
        </div>

        <div className="input-wrapper">
          <h2>From</h2>
          <Dropdown
            appendTo={document.querySelector(".container")}
            value={fromCity}
            onChange={(e) => setFromCity(e.value)}
            options={allCities}
            optionLabel="name"
            optionGroupLabel="name"
            optionGroupChildren="cities"
            optionGroupTemplate={groupedItemTemplate}
            placeholder="Select a City"
            filter
          />
        </div>

        <div className="input-wrapper">
          <h2>To</h2>
          <Dropdown
            appendTo={document.querySelector(".container")}
            value={toCity}
            onChange={(e) => setToCity(e.value)}
            options={allCities}
            optionLabel="name"
            optionGroupLabel="name"
            optionGroupChildren="cities"
            optionGroupTemplate={groupedItemTemplate}
            placeholder="Select a City"
            filter
          />
        </div>

        <div className="input-wrapper">
          <h2>Car Type</h2>
          <Dropdown
            required
            value={carCategory}
            onChange={(e) => setCarCategory(e.value)}
            options={carCategories}
            optionLabel="name"
            placeholder="Select a Car Category"
            filter
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="button"
          />
          <Button
            disabled={!fromCity || !toCity || !carCategory}
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default AddTransfer;
