import { createSlice } from '@reduxjs/toolkit';
import { AddOfferAction, DeleteOfferAction, GetOffersAction } from '../actions/OfferAction';
export const offerReducer = createSlice({
    name: "Offer Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        offers: [],
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(GetOffersAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetOffersAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                try {
                    if (payload.data.data === undefined) {
                        state.offers = payload.data;
                    } else {
                        state.offers = payload.data.data;
                    }
                    state.totalItems = payload.data.total;
                    state.currentPage = payload.data.current_page;
                } catch (err) {
                    // console.log(err)
                }
            })
            .addCase(GetOffersAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddOfferAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddOfferAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddOfferAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteOfferAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteOfferAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteOfferAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

    }
});
export default offerReducer.reducer; 