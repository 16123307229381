import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {
  GetCarsAction,
  UpdateCarAction,
} from "../../../redux/actions/car/CarAction";
import { unwrapResult } from "@reduxjs/toolkit";

const UpdateCar = ({ categoryId, rowData, basicRows, updateModal, toast }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.cars);
  const [car, setCategory] = useState(undefined);
  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = {
      categoryId: categoryId,
      size: basicRows,
      page: currentPage,
      isPaginate: 1,
    };
    obj.append("name", car ?? rowData.name);
    // console.log(rowData.id);
    let data = { id: rowData.id, obj: obj };
    dispatch(UpdateCarAction(data))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        updateModal(false);
        dispatch(GetCarsAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="add-group" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            unstyled
            className="custom-input"
            defaultValue={rowData.name}
            type="text"
            placeholder="Car Type"
            onChange={(e) => setCategory(e.target.value)}
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            type="button"
            onClick={() => updateModal(false)}
          />
          <Button
            size="small"
            label="Update"
            icon="pi pi-check"
            className="info"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateCar;
