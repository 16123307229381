import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { showError, showSuccess } from "../../../utils/ToastService";
import {
  BookTransferRequestAction,
  GetTransferRequestsAction,
} from "../../../redux/actions/transfer/TransferRequestAction";
import { unwrapResult } from "@reduxjs/toolkit";

const BookTransferRequest = ({ rowData, basicRows, toast, bookModal }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector(
    (state) => state.transferRequests
  );

  const submitHandler = (e) => {
    e.preventDefault();
    let info = {
      size: basicRows,
      page: currentPage,
      isPaginate: 1,
      status: window.location.hash.split("#")[1],
    };
    dispatch(BookTransferRequestAction(rowData))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        bookModal(false);
        dispatch(GetTransferRequestsAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="book-request" onSubmit={submitHandler}>
        <h5>Do you like to book this request ?</h5>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            type="button"
            icon="pi pi-times"
            onClick={() => bookModal(false)}
          />
          <Button
            size="small"
            className="danger"
            label="Book"
            icon="pi pi-bookmark"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default BookTransferRequest;
