import React, { useState } from "react";
import LoadingFS from "../../components/loading/LoadingFS";
import { useSelector } from "react-redux";
import TravelInsuranceRequestsDT from "./TravelInsuranceRequestsDT";
import { Dialog } from "primereact/dialog";
import AbortTravelInsuranceRequest from "./actions/AbortTravelInsuranceRequest";
import BookTravelInsuranceRequest from "./actions/BookTravelInsuranceRequest";

const TravelInsuranceRequests = ({ toast }) => {
  const { loading } = useSelector((state) => state.insurancesRequests);
  const [abortModal, setAbortModal] = useState(false);
  const [bookModal, setBookModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);

  return (
    <div className="sticky-container">
      {loading && <LoadingFS />}
      {/* <Header
        header={"Insurance Requests Log"}
        path={"/travel-insurance-requests/log"}
      /> */}

      <TravelInsuranceRequestsDT
        toast={toast}
        abortModal={(visible, rowData, basicRows) => {
          setAbortModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        bookModal={(visible, rowData, basicRows) => {
          setBookModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />
      <Dialog
        header="Abort Visa Request"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={abortModal}
        onHide={() => setAbortModal(false)}
      >
        <AbortTravelInsuranceRequest
          rowData={rowData}
          basicRows={basicRows}
          abortModal={(e) => setAbortModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Book Travel Insurance Request"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={bookModal}
        onHide={() => setBookModal(false)}
      >
        <BookTravelInsuranceRequest
          rowData={rowData}
          basicRows={basicRows}
          bookModal={(e) => setBookModal(e)}
          toast={toast}
        />
      </Dialog>
    </div>
  );
};

export default TravelInsuranceRequests;
