import { createSlice } from '@reduxjs/toolkit';
import { AddCarCategoryAction, DeleteCarCategoryAction, GetCarCategoriesAction, UpdateCarCategoryAction } from '../../actions/car/CarGategoryAction';
export const carGategoryReducer = createSlice({
    name: "Car Gategory Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        carCategories: [],
    },
    reducers: {
        // carCategoriesUnmount: (state) => {
        //     state.groupInfo = [];
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetCarCategoriesAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetCarCategoriesAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                try {
                    if (payload.data.data === undefined) {
                        state.carCategories = payload.data;
                    } else {
                        state.carCategories = payload.data.data;
                    }
                    state.totalItems = payload.data.total;
                    state.currentPage = payload.data.current_page;
                } catch (err) {
                    // console.log(err)
                }
            })
            .addCase(GetCarCategoriesAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddCarCategoryAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddCarCategoryAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddCarCategoryAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateCarCategoryAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateCarCategoryAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateCarCategoryAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteCarCategoryAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteCarCategoryAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteCarCategoryAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })


    }
});
export default carGategoryReducer.reducer;
