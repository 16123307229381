import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import { GetChartStatisticsAction } from "../../redux/actions/StatisticAction";
import { showError, showSuccess } from "../../utils/ToastService";
import PieCharts from "./PieCharts";
import "./TwoLevelPieChart.scss";
import { unwrapResult } from "@reduxjs/toolkit";

const TwoLevelPieChart = ({ toast }) => {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFromDateChange = (e) => {
    setFromDate(e.value);
  };

  // Handler for selecting to date
  const handleToDateChange = (e) => {
    setToDate(e.value);
  };

  const submitChart = (e) => {
    e.preventDefault();
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    const formattedFrom = new Date(fromDate)
      .toLocaleDateString("en-GB", options)
      .replace(/(^|\/)0+/g, "$1");
    const formattedTo = new Date(toDate)
      .toLocaleDateString("en-GB", options)
      .replace(/(^|\/)0+/g, "$1");
    // console.log(formattedFrom, formattedTo);
    let formData = new FormData();
    formData.append("start_date", formattedFrom);
    formData.append("end_date", formattedTo);
    dispatch(GetChartStatisticsAction(formData))
      .then(unwrapResult)
      .then((payload) => {
        // console.log(payload);
        if (payload?.data) {
          showSuccess(payload.message, toast);
        } else {
          showError(payload?.message, toast);
        }
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <div className="pie-chart">
      <div className="header">Statistics Chart</div>
      <form className="date-pickers" onSubmit={submitChart}>
        <Calendar
          value={fromDate}
          dateFormat="dd/mm/yy"
          onChange={handleFromDateChange}
          placeholder="From"
        />
        <Calendar
          value={toDate}
          dateFormat="dd/mm/yy"
          onChange={handleToDateChange}
          placeholder="To"
        />
        <Button
          icon="pi pi-arrow-right"
          severity="info"
          aria-label="Cancel"
          type="submit"
        />
      </form>
      <PieCharts />
    </div>
  );
};

export default TwoLevelPieChart;
