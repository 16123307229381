import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import { useSelector } from "react-redux";
import "./PieCharts.scss";
import { formatNumberWithCommas } from "../../utils/Formatters";
import { Message } from "primereact/message";

const PieChartDemo = () => {
  const { simpleChart } = useSelector((state) => state.statistics);
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);

    const chartsData = simpleChart.map((item, index) => ({
      mainLabel: [item.name],
      profit: item.profit,
      loss: item.loss,
      labels: ["Profit", "Loss"],
      datasets: [
        {
          data: [item.profit, item.loss],
          backgroundColor: [
            documentStyle.getPropertyValue("--cyan-500"),
            documentStyle.getPropertyValue("--red-500"), // Assuming red is used for loss
          ],
          hoverBackgroundColor: [
            documentStyle.getPropertyValue("--cyan-600"),
            documentStyle.getPropertyValue("--red-600"),
          ],
        },
      ],
    }));

    const options = {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        },
      },
    };

    setChartData(chartsData);
    setChartOptions(options);
  }, [simpleChart]);

  const totalLoss = chartData.reduce((total, data) => total + data.loss, 0);
  const totalProfit = chartData.reduce((total, data) => total + data.profit, 0);
  const allZero = simpleChart.every((item) => item.profit + item.loss === 0);

  return (
    <div className="prime-pie-chart">
      {chartData.map((data, index) => (
        <div className="pie-container" key={index}>
          {data.total !== 0 && (
            <>
              <Chart
                key={index}
                type="pie"
                data={data}
                options={chartOptions}
                style={{ height: "250px", width: "250px" }}
              />
              <div className="details">
                <span>Profit: {formatNumberWithCommas(data.profit)}</span>
                <span>Loss: {formatNumberWithCommas(data.loss)}</span>
              </div>
              <h4>{data.mainLabel}</h4>
            </>
          )}
        </div>
      ))}
      {!allZero && (
        <div className="report">
          <div className="profit">
            <i className="bi bi-graph-down-arrow"></i>
            <span>Total Loss: {formatNumberWithCommas(totalLoss)}</span>
          </div>
          <div className="loss">
            <i className="bi bi-graph-up-arrow"></i>
            <span>Total Profit: {formatNumberWithCommas(totalProfit)}</span>
          </div>
        </div>
      )}
      {allZero && <Message severity="warn" text="Nothing To Show" />}
    </div>
  );
};

export default PieChartDemo;
