import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dtTranstion, paginatorSize } from "../../redux/constant";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Tag } from "primereact/tag";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import { GetInsuraceRequestsLogAction } from "../../redux/actions/insurance/InsuranceRequestsActions";
import LoadingFS from "../../components/loading/LoadingFS";
import { InputText } from "primereact/inputtext";
import { formatNumberWithCommas } from "../../utils/Formatters";
import { getSeverity } from "../../utils/Severity";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError } from "../../utils/ToastService";
import { encodeBase64 } from "../../utils/EncodeDecode";

const InsuranceRequetsLog = ({ toast }) => {
  const dispatch = useDispatch();
  const { totalItems, log, loading } = useSelector(
    (state) => state.insurancesRequests
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const [phoneFilter, setPhoneFilter] = useState("");

  useEffect(() => {
    dispatch(
      GetInsuraceRequestsLogAction({
        page: currentPage,
        size: paginatorSize,
        isPaginate: 1,
        status: 10,
      })
    )
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  }, [dispatch, currentPage, toast]);

  const acitonBodyTemplate = (rowData) => {
    const encodedData = encodeBase64(JSON.stringify(rowData));
    return (
      <>
        <Link
          to={{
            pathname: `${rowData.id}`,
            search: `data=${encodedData}`,
          }}
        >
          <Button
            size="small"
            icon="pi pi-eye"
            className="p-button-rounded p-button-text p-button-success"
            aria-label="Submit"
          />
        </Link>
      </>
    );
  };

  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };
  const setStatusHandler = (e) => {
    switch (e) {
      case 0:
        return "Aborted";
      case 1:
        return "Succeeded";
      case 2:
        return "Rejected";
      default:
        break;
    }
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={setStatusHandler(rowData.status)}
        severity={getSeverity(rowData.status, true)}
      ></Tag>
    );
  };
  const searchHandler = (event) => {
    if (event.key === "Enter") {
      let info = {
        page: currentPage,
        size: paginatorSize,
        isPaginate: 1,
        status: 10,
        phone: phoneFilter,
      };
      dispatch(GetInsuraceRequestsLogAction(info))
        .then(unwrapResult)
        .catch((error) => {
          showError(error, toast);
        });
    }
  };

  const renderSearch = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <InputText
            unstyled
            className="custom-input"
            value={phoneFilter}
            onChange={(e) => {
              setPhoneFilter(e.target.value);
            }}
            placeholder="Number Search"
            onKeyDown={searchHandler}
          />
        </span>
      </div>
    );
  };

  const header = (
    <div className="header">
      <span className="title">Insurance Requests Log</span>
      {renderSearch()}
    </div>
  );

  return (
    <div className="sticky-container">
      {loading && <LoadingFS />}
      <div className={`datatable ${dtTranstion}`}>
        <DataTable stripedRows header={header} value={log}>
          <Column field="country_name" header="Country"></Column>
          <Column
            body={(e) => {
              return formatNumberWithCommas(e.price);
            }}
            header="Price"
          ></Column>
          <Column
            body={(e) => {
              return e.admin_name;
            }}
            header="Admin"
          ></Column>
          <Column
            body={(e) => {
              return e.admin_phone_number;
            }}
            header="Admin Number"
          ></Column>
          <Column header="User Number" field="user_phone_number"></Column>
          <Column header="Action Date" field="created_at"></Column>
          <Column header="Status" body={statusBodyTemplate}></Column>
          <Column
            align={"center"}
            body={acitonBodyTemplate}
            header="Actions"
          ></Column>
        </DataTable>
        <Paginator
          first={basicFirst}
          rows={basicRows}
          totalRecords={totalItems}
          onPageChange={onBasicPageChange}
        ></Paginator>
      </div>
    </div>
  );
};

export default InsuranceRequetsLog;
