import { Button } from "primereact/button";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { Dropdown } from "primereact/dropdown";
import {
  AddVisaAction,
  GetVisaAction,
} from "../../../redux/actions/visa/VisaAction";
import { InputNumber } from "primereact/inputnumber";
import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import { unwrapResult } from "@reduxjs/toolkit";

const AddVisa = ({ basicRows, createModal, toast, countries }) => {
  const dispatch = useDispatch();
  const { loading, currentPage, visaTypes } = useSelector(
    (state) => state.visas
  );
  const [visaPrice, setVisaPrice] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedType, setSelectedtype] = useState(0);
  const [period, setPeriod] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("price", visaPrice);
    obj.append("city_id", selectedCountry.id);
    obj.append("visa_type_id", selectedType.id);
    if (period) {
      obj.append("period", period + " " + value);
    }
    dispatch(AddVisaAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        createModal(false);
        dispatch(GetVisaAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  const changeTypeHandler = (e) => {
    setSelectedtype(e.value);
  };

  const [value, setValue] = useState("days");
  const options = [
    { value: "days", label: "Days" },
    { value: "months", label: "Months" },
    { value: "years", label: "Years" },
  ];

  return (
    <div className="container">
      <form className="add-visa" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputNumber
            className="custom-number-input"
            required
            value={visaPrice}
            placeholder="Visa Price"
            onValueChange={(e) => setVisaPrice(e.value)}
          />
        </div>

        <div className="input-wrapper">
          <Dropdown
            required
            value={selectedCountry}
            onChange={(e) => setSelectedCountry(e.value)}
            options={countries}
            optionLabel="name"
            placeholder="Select a Country"
            filter
          />
        </div>

        <div className="input-wrapper">
          <Dropdown
            required
            value={selectedType}
            onChange={changeTypeHandler}
            options={visaTypes}
            optionLabel="name"
            placeholder="Select a Visa Type"
          />
        </div>

        {selectedType.have_period === 1 && (
          <div className="p-inputgroup input-wrapper">
            <InputNumber
              required
              placeholder="Input a number"
              onChange={(e) => setPeriod(e.value)}
            />
            <MultiStateCheckbox
              value={value}
              onChange={(e) => setValue(e.value)}
              options={options}
              optionValue="value"
              iconTemplate={(e) => {
                return <h6>{e.option?.label}</h6>;
              }}
              empty={false}
            />
          </div>
        )}

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="button"
          />
          <Button
            disabled={!selectedCountry}
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};
export default AddVisa;
