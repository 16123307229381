import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import {
  GetCountriesAction,
  UpdateCountryAction,
} from "../../../redux/actions/CountryAction";
import { unwrapResult } from "@reduxjs/toolkit";

const UpdateCountry = ({
  rowData,
  basicRows,
  updateModal,
  toast,
  countries,
}) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.countries);
  const [nameEn, setNameEn] = useState(undefined);
  const [nameAr, setNameAr] = useState(undefined);

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    obj.append("name", nameEn ?? rowData.name);
    obj.append("name_ar", nameAr ?? rowData.name_ar);

    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    let data = { id: rowData.id, obj: obj };
    dispatch(UpdateCountryAction(data))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        updateModal(false);
        dispatch(GetCountriesAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="add-group" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.name}
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Country Name En"
            onChange={(e) => setNameEn(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.name_ar}
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Country Name Ar"
            onChange={(e) => setNameAr(e.target.value)}
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            type="button"
            onClick={() => updateModal(false)}
          />
          <Button
            size="small"
            label="Update"
            icon="pi pi-check"
            className="info"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateCountry;
