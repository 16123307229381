import React from "react";
import { useSelector } from "react-redux";
import { imageURL } from "../../redux/Api";
import MiniLoading from "../../components/mini-loading/MiniLoading";
import { Image } from "primereact/image";

const UserCard = ({ userInfo }) => {
  const { loading } = useSelector((state) => state.users);
  return (
    <div className="user-card-container">
      <div className="user-card">
        <div className="profile-img">
          {loading ? (
            <MiniLoading />
          ) : (
            <Image
              preview
              src={
                userInfo?.image !== null
                  ? imageURL + userInfo?.image
                  : "/Images/avatar.png"
              }
              alt="user-profile"
            />
          )}
        </div>
        <div className="content">
          <div className="name">
            {userInfo?.first_name} {userInfo?.last_name}
          </div>
          <div className="phone-number">
            Phone Number: {userInfo?.phone_number}
          </div>
          <div className="phone-number">
            Registered On: {userInfo?.created_at}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
