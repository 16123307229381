import { createSlice } from '@reduxjs/toolkit';
import { AbortInsuranceRequestAction, AddPriceInsuranceRequestAction, BookInsuranceRequestsAction, DoneInsuranceRequestsAction, GetInsuraceRequestsLogAction, GetInsuranceRequestsAction, SuccessInsuranceRequestAction } from '../../actions/insurance/InsuranceRequestsActions';
export const insuranceRequestReducer = createSlice({
    name: "insurance Request Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        insuranceRequests: [],
        log: [],
    },
    reducers: {
        insurancesUnmount: (state) => {
            state.log = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetInsuranceRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetInsuranceRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.insuranceRequests = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetInsuranceRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetInsuraceRequestsLogAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetInsuraceRequestsLogAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.log = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetInsuraceRequestsLogAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DoneInsuranceRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DoneInsuranceRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DoneInsuranceRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(SuccessInsuranceRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(SuccessInsuranceRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(SuccessInsuranceRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AbortInsuranceRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AbortInsuranceRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AbortInsuranceRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddPriceInsuranceRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddPriceInsuranceRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddPriceInsuranceRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(BookInsuranceRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(BookInsuranceRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(BookInsuranceRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
    }
});
export default insuranceRequestReducer.reducer;
export const { insurancesUnmount } = insuranceRequestReducer.actions;
