import { createSlice } from '@reduxjs/toolkit';
import { AddInsuranceAction, DeleteInsuranceAction, GetInsuranceAction, UpdateInsuranceAction } from '../../actions/insurance/InsuranceAction';
export const insuranceReducer = createSlice({
    name: "Insurance Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        insurances: [],
    },
    reducers: {
        insuranceUnmount: (state) => {
            state.insurances = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetInsuranceAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetInsuranceAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.insurances = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetInsuranceAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddInsuranceAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddInsuranceAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddInsuranceAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateInsuranceAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateInsuranceAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateInsuranceAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteInsuranceAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteInsuranceAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteInsuranceAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

    }
});
export default insuranceReducer.reducer;
export const { insuranceUnmount } = insuranceReducer.actions;
