import React from "react";
import "./notfound.scss";

const NotFound = () => {
  return (
    <div className="  unauthorized">
      <img
        className=" animate__animated animate__zoomInDown"
        src="/Images/404.png"
        alt="authorization-required"
      />
    </div>
  );
};

export default NotFound;
