import { createSlice } from '@reduxjs/toolkit';
import { AbortHotelRequestAction, AddHotelSuggestAction, AddPriceHotelRequestAction, BookHotelRequestAction, DoneHotelRequestsAction, GetHotelRequestsAction, GetHotelRequestsLogAction, SuccessHotelRequestAction } from '../../actions/hotel/HotelRequestAction';
export const hotelRequestReducer = createSlice({
    name: "Hotel Request Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        hotelRequests: [],
        log: [],
    },
    reducers: {
        hotelsUnmount: (state) => {
            state.log = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetHotelRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetHotelRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.hotelRequests = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetHotelRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetHotelRequestsLogAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetHotelRequestsLogAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.log = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetHotelRequestsLogAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })


            .addCase(DoneHotelRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DoneHotelRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DoneHotelRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(SuccessHotelRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(SuccessHotelRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(SuccessHotelRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AbortHotelRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AbortHotelRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AbortHotelRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(BookHotelRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(BookHotelRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(BookHotelRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddHotelSuggestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddHotelSuggestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddHotelSuggestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddPriceHotelRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddPriceHotelRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddPriceHotelRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
    }
});
export default hotelRequestReducer.reducer;
export const { hotelsUnmount } = hotelRequestReducer.actions;
