import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import { imageURL } from "../../redux/Api";
import { Rating } from "primereact/rating";
import ClientsExplore from "../maps/ClientsExplore";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { formatNumberWithCommas } from "../../utils/Formatters";
import "../../assets/pages/transfer-request/ShowRequestLog.scss";
import { decodeBase64 } from "../../utils/EncodeDecode";

const TravelInsuranceLogInfo = ({ toast }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const encodedData = params.get("data");
  const decodedData = JSON.parse(decodeBase64(encodedData));
  const navigate = useNavigate();

  return (
    <div className="show-request-log">
      <div className="image-containers">
        <Button
          onClick={() => {
            navigate(-1);
          }}
          icon="pi pi-arrow-left"
          className="backward"
          rounded
          label="Back"
          text
          aria-label="Filter"
          severity="info"
        />
        <div className="passport-image">
          {decodedData?.description === "R" && (
            <Message severity="warn" text="Rejected By User" />
          )}
          <div className="title">
            <h3>Basic Information</h3>
          </div>
          {decodedData?.description && decodedData?.description !== "R" && (
            <div className="info">
              <strong>Reason of abort:</strong> {decodedData.description}
            </div>
          )}
          <div className="info">
            <span>
              <strong>User Name: </strong>
              {decodedData.user_name}
            </span>
          </div>
          <div className="info">
            <span>
              <strong>User Phone: </strong>
              {decodedData.user_phone_number}
            </span>
          </div>
          <div className="info">
            <span>
              <strong>Country: </strong>
              {decodedData.country_name}
            </span>
          </div>
          <div className="info">
            <span>
              <strong>Price: </strong>
              {formatNumberWithCommas(decodedData.price)}
            </span>
          </div>
          <div className="info">
            <strong>Service Rate:</strong>
            <Rating value={decodedData.rate} readOnly cancel={false} />
          </div>

          <h3>Passport First Image</h3>
          <Image
            className="image"
            src={imageURL + decodedData.passport_first_page}
            alt="passport_first_page"
            loading="lazy"
            preview
            downloadable
            children={document.querySelector(".passport-image")}
          />
        </div>
        <div className="passport-image">
          <h3>Passport Second Image</h3>
          <Image
            className="image"
            src={imageURL + decodedData.passport_second_page}
            alt="passport_second_page"
            loading="lazy"
            preview
            downloadable
            children={document.querySelector(".passport-image")}
          />
        </div>
        {decodedData.ticket_images.length !== 0 && (
          <div className="ticket-images">
            <h3>Document Images</h3>
            {decodedData.ticket_images.map((item, index) => {
              return (
                <Image
                  key={index}
                  className="image"
                  src={imageURL + item.path}
                  alt="document_page"
                  loading="lazy"
                  preview
                  downloadable
                  children={document.querySelector(".passport-image")}
                />
              );
            })}
          </div>
        )}
        {decodedData?.latitude && decodedData?.longitude && (
          <>
            <h3 style={{ textAlign: "center" }}>User Location</h3>
            <ClientsExplore
              places={[
                { lat: decodedData?.latitude, lng: decodedData?.longitude },
              ]}
              toast={toast}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TravelInsuranceLogInfo;
