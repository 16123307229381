import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../../constant";
import { AbortTravelInsuranceAPI, AddPriceInsuranceRequestAPI, BookInsuranceRequestAPI, DoneTravelInsuranceRequestsAPI, GetInsuraceRequestsLogAPI, GetTravelInsuranceRequestsAPI, SuccessTravelInsuranceAPI } from "../../Api";

export const GetInsuranceRequestsAction = createAsyncThunk("cms/insurance-requests/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetTravelInsuranceRequestsAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
            + "&status=" + info.status);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DoneInsuranceRequestsAction = createAsyncThunk("cms/insurance-requests/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(DoneTravelInsuranceRequestsAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const SuccessInsuranceRequestAction = createAsyncThunk("cms/insurance-requests/success", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(SuccessTravelInsuranceAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AbortInsuranceRequestAction = createAsyncThunk("cms/insurance-requests/abort", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AbortTravelInsuranceAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const BookInsuranceRequestsAction = createAsyncThunk("cms/insurance-requests/book", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(BookInsuranceRequestAPI + info.id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddPriceInsuranceRequestAction = createAsyncThunk("cms/insurance-requests/price", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddPriceInsuranceRequestAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetInsuraceRequestsLogAction = createAsyncThunk("cms/insurance-requests/log", async (info, { rejectWithValue }) => {
    try {
        let route;
        if (info.phone) {
            route = GetInsuraceRequestsLogAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
                + "&status=" + info.status + "&phone_number=" + info.phone
        }
        else {
            route = GetInsuraceRequestsLogAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
                + "&status=" + info.status
        }
        let { data } = await axiosInstance.get(route);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
