import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import {
  AbortHotelRequestAction,
  GetHotelRequestsAction,
} from "../../../redux/actions/hotel/HotelRequestAction";
import { unwrapResult } from "@reduxjs/toolkit";

const AbortHotelRequest = ({ basicRows, rowData, abortModal, toast }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.hotelRequests);
  const [description, setDescription] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    let fromData = new FormData();
    let info = {
      size: basicRows,
      page: currentPage,
      isPaginate: 1,
      status: window.location.hash.split("#")[1],
    };
    fromData.append("description", description);
    let obj = {
      id: rowData.id,
      obj: fromData,
    };
    dispatch(AbortHotelRequestAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        dispatch(GetHotelRequestsAction(info));
        abortModal(false);
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <div className="container">
      <form className="abort-visa-request" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Reason of abort"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => abortModal(false)}
            type="button"
          />
          <Button
            disabled={!description}
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default AbortHotelRequest;
