import { createSlice } from '@reduxjs/toolkit';
import { GetChartStatisticsAction, GetLineChartAction, GetStatisticsAction } from '../actions/StatisticAction';
export const statisticReducer = createSlice({
    name: "Statistic Reducer",
    initialState: {
        loading: false,
        hotelStatistic: {},
        flightStatistic: {},
        insuranceStatistic: {},
        visaStatistic: {},
        transferStatistic: {},
        outerChart: [],
        innerChart: [],
        simpleChart: [],
        lineChartData: [],
        barChartData: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(GetStatisticsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetStatisticsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.hotelStatistic = payload.data.booking_hotel
                state.flightStatistic = payload.data.flight
                state.insuranceStatistic = payload.data.health_insurance
                state.transferStatistic = payload.data.transfer
                state.visaStatistic = payload.data.visa
            })
            .addCase(GetStatisticsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetChartStatisticsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetChartStatisticsAction.fulfilled, (state, { payload }) => {
                // console.log(payload.data)
                try {
                    const responseData = payload.data;
                    const simpleChart = [
                        { name: "Hotel Booking", profit: responseData.booking_hotel.profit, loss: responseData.booking_hotel.loss },
                        { name: "Flight", profit: responseData.flight.profit, loss: responseData.flight.loss },
                        { name: "Health Insurance", profit: responseData.health_insurance.profit, loss: responseData.health_insurance.loss },
                        { name: "Transfer", profit: responseData.transfer.profit, loss: responseData.transfer.loss },
                        { name: "Visa", profit: responseData.visa.profit, loss: responseData.visa.loss }
                    ];

                    state.simpleChart = simpleChart
                } catch (err) {
                    // console.log(err)
                }
                state.loading = false;

            })
            .addCase(GetChartStatisticsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetLineChartAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetLineChartAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                const lineChartData = payload.data.map(item => ({
                    date: item.date,
                    sum_request: item.sum_request
                }));
                const barChartData = payload.data.map(item => ({
                    date: item.date,
                    num_request: item.num_request
                }));
                state.barChartData = barChartData
                state.lineChartData = lineChartData
                state.loading = false;
            })
            .addCase(GetLineChartAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
    }
});
export default statisticReducer.reducer; 