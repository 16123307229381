import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Rating } from "primereact/rating";
import ClientsExplore from "../maps/ClientsExplore";
import { Message } from "primereact/message";
import { Button } from "primereact/button";
import { formatNumberWithCommas } from "../../utils/Formatters";
import "../../assets/pages/transfer-request/ShowRequestLog.scss";
import { decodeBase64 } from "../../utils/EncodeDecode";

const TransferLogInfo = ({ toast }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const encodedData = params.get("data");
  const decodedData = JSON.parse(decodeBase64(encodedData));
  const navigate = useNavigate();

  return (
    <div className="show-request-log">
      <div className="transfer-request">
        <Button
          onClick={() => {
            navigate(-1);
          }}
          icon="pi pi-arrow-left"
          className="backward"
          rounded
          label="Back"
          text
          aria-label="Filter"
          severity="info"
        />
        {decodedData?.description && decodedData?.description !== "R" && (
          <div className="info">
            <strong>Reason of abort:</strong> {decodedData.description}
          </div>
        )}
        {decodedData?.description === "R" && (
          <Message severity="warn" text="Rejected By User" />
        )}
        <div className="title">
          <h3>Date Information</h3>
        </div>
        <div className="info">
          <strong>Date:</strong> {decodedData.date}
        </div>
        <div className="info">
          <strong>Time:</strong> {decodedData.time}
        </div>
        <div className="title ">
          <h3>People Information</h3>
        </div>
        <div className="info">
          <strong>Number of Passengers:</strong> {decodedData.passengers_number}
        </div>
        <div className="info">
          <strong>Number of Bags:</strong> {decodedData.bags_number}
        </div>
        <div className="title">
          <h3>User Information</h3>
        </div>
        <div className="info">
          <strong>User Name:</strong> {decodedData.user_name}
        </div>
        <div className="info">
          <strong>User Phone Number:</strong> {decodedData.user_phone_number}
        </div>
        <div className="title">
          <h3>Car Information</h3>
        </div>
        <div className="info">
          <strong>Car Name:</strong>
          {decodedData?.car_name ? decodedData?.car_name : "Not Assigned"}
        </div>
        <div className="info">
          <strong>Car Category:</strong> {decodedData.car_category_name}
        </div>
        <div className="title">
          <h3>Transfer Information</h3>
        </div>
        <div className="info">
          <strong>Transfer Price:</strong>
          {formatNumberWithCommas(decodedData.price)}
        </div>
        <div className="info">
          <strong>Transfer From: </strong> {decodedData.city_from_name}
        </div>
        <div className="info">
          <strong>Transfer To: </strong>
          {decodedData.city_to_name}
        </div>
        <div className="info">
          <strong>Service Rate: </strong>
          <Rating value={decodedData.rate} readOnly cancel={false} />
        </div>
        {decodedData?.latitude && decodedData?.longitude && (
          <>
            <h3 style={{ textAlign: "center" }}>User Location</h3>
            <ClientsExplore
              places={[
                { lat: decodedData?.latitude, lng: decodedData?.longitude },
              ]}
              toast={toast}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TransferLogInfo;
