import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import LoadingFS from "../../components/loading/LoadingFS";
import { useSelector } from "react-redux";
import CarsDT from "./CarsDT";
import AddCar from "./actions/AddCar";
import UpdateCar from "./actions/UpdateCar";
import DeleteCar from "./actions/DeleteCar";
import { useParams } from "react-router-dom";

const Cars = ({ toast }) => {
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);
  const { loading } = useSelector((state) => state.group) ;
  const { categoryId } = useParams();

  return (
    <div className="sticky-container">
      {loading && <LoadingFS />}
      <CarsDT
        categoryId={categoryId}
        toast={toast}
        createModal={(visible, rowData, basicRows) => {
          setCreateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        deleteModal={(visible, rowData, basicRows) => {
          setDeleteModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        updateModal={(visible, rowData, basicRows) => {
          setUpdateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />

      <Dialog
        header="Add Car"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={createModal}
        onHide={() => setCreateModal(false)}
      >
        <AddCar
          categoryId={categoryId}
          basicRows={basicRows}
          createModal={(e) => setCreateModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Update Car"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <UpdateCar
          categoryId={categoryId}
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          updateModal={(e) => setUpdateModal(e)}
        />
      </Dialog>

      <Dialog
        closeOnEscape
        header="Delete Car"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
      >
        <DeleteCar
          categoryId={categoryId}
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          deleteModal={(e) => setDeleteModal(e)}
        />
      </Dialog>
    </div>
  );
};

export default Cars;
