import React, { useEffect, useState } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import "./About.scss";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAboutUsAction,
  PostAboutUsAction,
} from "../../redux/actions/AboutUsAction";
import { showError, showSuccess } from "../../utils/ToastService";
import LoadingFS from "../../components/loading/LoadingFS";
import { unwrapResult } from "@reduxjs/toolkit";
const About = ({ toast }) => {
  const dispatch = useDispatch();
  const { about, loading } = useSelector((state) => state.about);
  const [number, setNumber] = useState(undefined);
  const [firstOfferEn, setFirstOfferEn] = useState(undefined);
  const [secondOfferEn, setSecondOfferEn] = useState(undefined);
  const [firstOfferAr, setFirstOfferAr] = useState(undefined);
  const [secondOfferAr, setSecondOfferAr] = useState(undefined);
  const [whatsappNumbers, setWhatsappNumbers] = useState(undefined);
  const [address, setAddress] = useState(undefined);
  const maxRows = 4;

  useEffect(() => {
    dispatch(GetAboutUsAction())
      .then(unwrapResult)
      .then((payload) => {
        // console.log(payload);
        const numbers = Object.keys(payload.data)
          .filter((key) => key.startsWith("whatsapp"))
          .map((key) => payload.data[key])
          .filter((val) => val)
          .join("\n");
        setWhatsappNumbers(numbers);
      })
      .catch((error) => {
        showError(error, toast);
      });
  }, [dispatch, toast]);

  const handleWhatsappNumbers = (e) => {
    const enteredText = e.target.value;
    const enteredRows = enteredText?.split("\n")?.length;

    if (enteredRows <= maxRows) {
      setWhatsappNumbers(enteredText.trim());
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("telephone", number ?? about?.telephone);
    formData.append("address", address ?? about?.address);
    formData.append(
      "about_title_1_en",
      firstOfferEn ?? about?.about_title_1_en
    );
    formData.append(
      "about_title_1_ar",
      firstOfferAr ?? about?.about_title_1_ar
    );
    formData.append(
      "about_title_2_en",
      secondOfferEn ?? about?.about_title_2_en
    );
    formData.append(
      "about_title_2_ar",
      secondOfferAr ?? about?.about_title_2_ar
    );

    const numbersArray = whatsappNumbers?.split("\n");

    for (let i = 0; i < numbersArray?.length && i < 4; i++) {
      formData.append(`whatsapp[${i}]`, numbersArray[i]);
    }

    dispatch(PostAboutUsAction(formData))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        dispatch(GetAboutUsAction());
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <div className="about sticky-container">
      {loading && <LoadingFS />}
      <form className="about-us-container" onSubmit={submitHandler}>
        <div className="title">
          <h3>About Us</h3>
        </div>

        <div>
          <div className="header">
            <i className="bi bi-telephone"></i>
            <label htmlFor="phoneNumber">Our Number</label>
          </div>
          <InputText
            defaultValue={about?.telephone}
            unstyled
            onChange={(e) => {
              setNumber(e.target.value);
            }}
            className="custom-input"
            id="phoneNumber"
            type="tel"
            placeholder="Phone Number"
          />
        </div>
        <div>
          <div className="header">
            <i className="bi bi-whatsapp"></i>
            <label htmlFor="whatsapp">
              WhatsApp Numbers (Enter up to 4 numbers)
            </label>
          </div>
          <InputTextarea
            defaultValue={whatsappNumbers}
            onChange={handleWhatsappNumbers}
            className="custom-input"
            id="whatsapp"
            autoResize
            placeholder="Whatsapp Numbers"
          />
        </div>

        <div>
          <div className="header">
            <i className="bi bi-geo-alt"></i>
            <label htmlFor="address">Our Address</label>
          </div>
          <InputTextarea
            defaultValue={about?.address}
            onChange={(e) => setAddress(e.target.value)}
            className="custom-input"
            id="address"
            autoResize
            placeholder="Write something about us..."
          />
        </div>
        <div className="header">
          <i className="bi bi-newspaper"></i>
          <label htmlFor="titles">Offer Titles</label>
        </div>

        <div className="p-inputgroup flex-1">
          <InputText
            defaultValue={about?.about_title_1_en}
            unstyled
            onChange={(e) => {
              setFirstOfferEn(e.target.value);
            }}
            className="custom-input"
            id="titles"
            type="text"
            placeholder="First Offer EN"
          />
          <InputText
            defaultValue={about?.about_title_1_ar}
            unstyled
            onChange={(e) => {
              setFirstOfferAr(e.target.value);
            }}
            className="custom-input"
            id="titles"
            type="text"
            placeholder="First Offer AR"
          />
        </div>

        <div className="p-inputgroup flex-1">
          <InputText
            defaultValue={about?.about_title_2_en}
            unstyled
            onChange={(e) => {
              setSecondOfferEn(e.target.value);
            }}
            className="custom-input"
            id="titles"
            type="text"
            placeholder="Second Offer EN"
          />
          <InputText
            defaultValue={about?.about_title_2_ar}
            unstyled
            onChange={(e) => {
              setSecondOfferAr(e.target.value);
            }}
            className="custom-input"
            id="titles"
            type="text"
            placeholder="Second Offer AR"
          />
        </div>

        <div className="done-btn">
          <Button
            loading={loading}
            label="Submit"
            type="submit"
            size="small"
            className="info"
            aria-label="Submit"
          />
        </div>
      </form>
    </div>
  );
};

export default About;
