import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useDispatch, useSelector } from "react-redux";
import { GetUsersAction } from "../../redux/actions/UserAction";
import { InputText } from "primereact/inputtext";
import "./PushNotification.scss";
import { MultiSelect } from "primereact/multiselect";
import { PushNotificationAction } from "../../redux/actions/NotificationsAction";
import { showError, showSuccess } from "../../utils/ToastService";
import { Dropdown } from "primereact/dropdown";
import { unwrapResult } from "@reduxjs/toolkit";

const PushNotification = ({ toast }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const { users } = useSelector((state) => state.users);
  const { loading } = useSelector((state) => state.notifications);
  const [selectedOption, setSelectedOption] = useState("0");
  const options = [
    { label: "Specific", value: "0" },
    { label: "VIP", value: "1" },
    { label: "Broadcast", value: "2" },
  ];
  const handleSelect = (e) => {
    setSelectedOption(e.value);
  };
  useEffect(() => {
    dispatch(GetUsersAction({ isPaginate: 0 }));
  }, [dispatch]);

  const submitHandler = (e) => {
    e.preventDefault();
    // console.log(message, title, selectedUser);
    let info = new FormData();
    info.append("title", title);
    info.append("description", message);
    for (let i = 0; i < selectedUser?.length; i++) {
      info.append(`users[${i}]`, selectedUser[i].id);
    }
    let data = {
      obj: info,
      type: selectedOption,
    };
    dispatch(PushNotificationAction(data))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        setTitle("");
        setMessage("");
        setSelectedUser([]);
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <form className="push-notification" onSubmit={submitHandler}>
      <h3>Push Notifications</h3>

      <div className="check-btn">
        <Dropdown
          value={selectedOption}
          options={options}
          onChange={handleSelect}
          placeholder="Select an option"
        />
      </div>

      <div className="input-section">
        {selectedOption === "0" && (
          <MultiSelect
            appendTo={"self"}
            showSelectAll={false}
            value={selectedUser}
            options={users}
            onChange={(e) => setSelectedUser(e.value)}
            optionLabel={(rowData) =>
              `${rowData.first_name} ${rowData.last_name} | ${rowData.phone_number}`
            }
            placeholder="Users"
            filter
            showClear
          />
        )}
        <div className="input-wrapper">
          <InputText
            value={title}
            unstyled
            className="custom-input"
            type="text"
            placeholder="Notification Title"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <InputTextarea
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Enter your message"
          autoResize
          rows={5}
          cols={30}
          className="custom-input"
        />
        <Button
          label="Send"
          className="info"
          icon="pi pi-send"
          type="submit"
          loading={loading}
        />
      </div>
    </form>
  );
};

export default PushNotification;
