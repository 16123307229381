import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../../constant";
import { AddCarCategoriesAPI, DeleteCarCategoriesAPI, GetCarCategoriesAPI, UpdateCarCategoriesAPI } from "../../Api";


export const GetCarCategoriesAction = createAsyncThunk("admin/car-category/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetCarCategoriesAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddCarCategoryAction = createAsyncThunk("admin/car-category/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddCarCategoriesAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateCarCategoryAction = createAsyncThunk("admin/car-category/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(UpdateCarCategoriesAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteCarCategoryAction = createAsyncThunk("admin/car-category/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteCarCategoriesAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

// export const ShowGroupAction = createAsyncThunk("admin/car-category/show", async (info, { rejectWithValue }) => {
//     try {
//         let { data } = await axiosInstance.get(ShowGroupAPI + info);
//         return data;
//     } catch (error) {
//         if (error.response) {
//             const validationMessage = error.response.data.message;
//             return rejectWithValue(validationMessage);
//         } else {
//             return rejectWithValue(errorMessage);
//         }
//     }
// });

