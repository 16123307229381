import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import {
  AddCountryAction,
  GetCountriesAction,
} from "../../../redux/actions/CountryAction";
import { unwrapResult } from "@reduxjs/toolkit";

const AddCountry = ({ basicRows, createModal, countries, toast }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.countries);
  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("name", nameEn);
    obj.append("name_ar", nameAr);

    dispatch(AddCountryAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        createModal(false);
        dispatch(GetCountriesAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="add-country" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Region Name En"
            onChange={(e) => setNameEn(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <InputText
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Region Name Ar"
            onChange={(e) => setNameAr(e.target.value)}
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="button"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default AddCountry;
