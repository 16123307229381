import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../constant";
import { BlockUserAPI, GetUsersAPI, SearchUserAPI, ShowUserAPI } from "../Api";


export const GetUsersAction = createAsyncThunk("users/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetUsersAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const BlockUserAction = createAsyncThunk("users/block", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(BlockUserAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const ShowUserAction = createAsyncThunk("users/show", async (id, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(ShowUserAPI + id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const SearchUserAction = createAsyncThunk("users/search", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(SearchUserAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});