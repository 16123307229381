import { createSlice } from '@reduxjs/toolkit';
import { AddTransferAction, DeleteTransferAction, GetTransferAction, GetTransfersInRangeAction, UpdateTransferAction } from '../../actions/transfer/TransferAction';
export const transferReducer = createSlice({
    name: "Transfer Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        transfers: [],
        rangedTransfers: [],
    },
    reducers: {
        transferUnmount: (state) => {
            state.transfers = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetTransferAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetTransferAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.transfers = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetTransferAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetTransfersInRangeAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetTransfersInRangeAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.rangedTransfers = payload.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetTransfersInRangeAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddTransferAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddTransferAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddTransferAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateTransferAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateTransferAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateTransferAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteTransferAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteTransferAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteTransferAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

    }
});
export default transferReducer.reducer;
export const { transferUnmount } = transferReducer.actions;
