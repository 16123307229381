import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../constant";
import { GetChartStatisticsAPI, GetLineChartAPI, GetStatisticsAPI } from "../Api";

export const GetStatisticsAction = createAsyncThunk("admin/statistics/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetStatisticsAPI);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetChartStatisticsAction = createAsyncThunk("admin/statistics/chart", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(GetChartStatisticsAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetLineChartAction = createAsyncThunk("admin/line/chart", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(GetLineChartAPI + info.type, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});