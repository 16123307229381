import React, { useEffect, useState } from "react";
import "./Sidebar.scss";
import { Link, useLocation } from "react-router-dom";
import { adminPermissions, isSuperAdmin } from "../../redux/constant";
import { tabs } from "../../utils/Tabs";

const Sidebar = () => {
  const [activeTab, setActiveTab] = useState("");
  const { pathname } = useLocation();
  const pathArray = pathname.split("/").filter(Boolean);
  const baseUrl = pathArray[0];

  useEffect(() => {
    setTab(baseUrl);
  }, [baseUrl]);

  const setTab = (place) => {
    localStorage.setItem("tab", place);
    setActiveTab(place);
  };

  const [collapsedTabs, setCollapsedTabs] = useState({});

  const toggleCollapse = (header) => {
    setCollapsedTabs({
      ...collapsedTabs,
      [header]: !collapsedTabs[header],
    });
  };
  const filteredTabs =
    isSuperAdmin !== "true"
      ? tabs.map((header) => ({
          header: header.header,
          icon: header.icon,
          tabs: header.tabs.filter((tab) =>
            adminPermissions?.includes(tab.permission)
          ),
        }))
      : tabs;
  return (
    <aside className="sidebar animate__animated animate__bounceInLeft">
      <div className="sticky">
        <div className="sidebar-logo">
          <img src={"/Images/logo.png"} alt="Logo" className="logo-image" />
        </div>
        <ul className="sidebar-list ">
          {filteredTabs.map((header) => (
            <div className="collection" key={header.header}>
              {header.tabs.length !== 0 && (
                <div
                  className="header"
                  onClick={() => toggleCollapse(header.header)}
                >
                  <div className="title">
                    <i className={header.icon}></i>
                    <h3>{header.header}</h3>
                  </div>
                  <div className="arrow">
                    <i
                      className={`bi ${
                        !collapsedTabs[header.header]
                          ? "bi-caret-down-fill"
                          : "bi-caret-right-fill"
                      } `}
                    ></i>
                  </div>
                </div>
              )}
              <div className={!collapsedTabs[header.header] ? "" : "collapsed"}>
                {header.tabs.map((tab) => (
                  <div key={tab.label}>
                    <Link
                      to={tab.name}
                      onClick={() => setTab(tab.name.replace(/#[0-9]+$/, ""))}
                    >
                      <li
                        className={`sidebar-item ${
                          activeTab === tab.name.replace(/#[0-9]+$/, "")
                            ? "visited"
                            : ""
                        }`}
                      >
                        {tab.label}
                      </li>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
