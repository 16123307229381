import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddVisaTypeAction,
  DeleteVisaTypeAction,
  GetVisaTypesAction,
} from "../../../redux/actions/visa/VisaAction";
import { showError, showSuccess } from "../../../utils/ToastService";
import MiniLoading from "../../../components/mini-loading/MiniLoading";
import { unwrapResult } from "@reduxjs/toolkit";

const VisaTypes = ({ basicRows, additionModal, toast }) => {
  const { visaTypes, typesLoading } = useSelector((state) => state.visas);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [visaNameEn, setVisaNameEn] = useState("");
  const [visaNameAr, setVisaNameAr] = useState("");

  const addHandler = () => {
    let obj = new FormData();
    obj.append("name", visaNameEn);
    obj.append("name_ar", visaNameAr);
    obj.append("have_period", checked ? 1 : 0);
    dispatch(AddVisaTypeAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        dispatch(GetVisaTypesAction({ isPaginate: 0 }));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  const deleteHandler = (e) => {
    dispatch(DeleteVisaTypeAction(e.id))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        dispatch(GetVisaTypesAction({ isPaginate: 0 }));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <>
      <div className="container">
        <div className="visa-types">
          <div
            className="row"
            style={{
              fontWeight: "bold",
              backgroundColor: "white",
            }}
          >
            <div>En Name</div>
            <div>Ar Name</div>
            <div>Type</div>
            <div>Actions</div>
          </div>

          {!typesLoading && (
            <>
              {visaTypes.map((item, index) => (
                <div key={index} className="row">
                  <div>{item?.name}</div>
                  <div>{item?.name_ar}</div>
                  <div>
                    {item?.have_period === 0 ? "Without Period" : "With Period"}
                  </div>
                  <div>
                    <i
                      className="pi pi-trash"
                      onClick={() => {
                        deleteHandler(item);
                      }}
                    ></i>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        {!typesLoading && (
          <div className="input-wrapper p-inputgroup">
            <span className="p-inputgroup-addon">
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(!checked)}
              />
            </span>
            <InputText
              required
              style={{
                boxShadow: "unset",
                borderColor: "#26a7df",
              }}
              placeholder="Visa Type Name"
              onChange={(e) => setVisaNameEn(e.target.value)}
            />
            <InputText
              required
              style={{
                textAlign: "right",
                boxShadow: "unset",
                borderColor: "#26a7df",
              }}
              placeholder="اسم نوع التأشيرة"
              onChange={(e) => setVisaNameAr(e.target.value)}
            />

            <Button
              icon="pi pi-check"
              className="p-button-info"
              onClick={addHandler}
            />
          </div>
        )}
      </div>
      {typesLoading && <MiniLoading />}
    </>
  );
};

export default VisaTypes;
