import React from "react";
import "./Card.scss";
import { Button } from "primereact/button";
import { paginatorSize } from "../../redux/constant";
import { Link } from "react-router-dom";

const Card = ({ rowData, updateModal, deleteModal }) => {
  return (
    <div className="card ">
      <div className="header">
        <h3>{rowData.name}</h3>
        <div className="actions">
          <Button
            size="small"
            icon="pi pi-trash"
            className="p-button-rounded p-button-text p-button-danger"
            onClick={() => {
              deleteModal(true, rowData, paginatorSize);
            }}
          />
          <Button
            size="small"
            icon="pi pi-pencil"
            className="p-button-rounded p-button-text p-button-primary"
            onClick={() => {
              updateModal(true, rowData, paginatorSize);
            }}
          />
          <Link to={`${rowData.id}`}>
            <Button
              size="small"
              type="submit"
              className="p-button-rounded p-button-text p-button-success"
              icon="pi pi-eye"
            ></Button>
          </Link>
        </div>
      </div>
      <div className="content">
        <p>Updated At : {rowData.updated_at}</p>
      </div>
    </div>
  );
};

export default Card;
