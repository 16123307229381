import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/pages/utils/datatable.scss";
import { Paginator } from "primereact/paginator";
import { useDispatch, useSelector } from "react-redux";
import { dtTranstion, paginatorSize } from "../../redux/constant";
import { GetFeedbacksAction } from "../../redux/actions/FeedbackAction";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError } from "../../utils/ToastService";

const FeedbackDT = ({ toast }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);

  const { totalItems, feedbacks } = useSelector((state) => state.feedback);
  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };
  useEffect(() => {
    let info = { page: currentPage, size: paginatorSize, isPaginate: 1 };
    dispatch(GetFeedbacksAction(info))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  }, [dispatch, currentPage, toast]);

  const header = (
    <div className="header">
      <span className="title">Feedback</span>
    </div>
  );

  return (
    <div className={`datatable ${dtTranstion}`}>
      <DataTable stripedRows header={header} value={feedbacks}>
        <Column
          field="feedback"
          className="hoverable-cell"
          header="Feedback"
        ></Column>
        <Column
          body={(rowData) => {
            return rowData.user.first_name + " " + rowData.user.last_name;
          }}
          header="Client Name"
        ></Column>
        <Column
          body={(rowData) => {
            return rowData.user.phone_number;
          }}
          header="Phone Number"
        ></Column>
        <Column field="created_at" header="Created At"></Column>
      </DataTable>
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={totalItems}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </div>
  );
};

export default FeedbackDT;
