import { createSlice } from '@reduxjs/toolkit';
import { AddGroupAction, DeleteGroupAction, GetGroupsAction, GetPermissionsExceptAction, ShowGroupAction, UpdateGroupAction } from '../actions/GroupAction';
export const groupReducer = createSlice({
    name: "Group Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        groups: [],
        groupInfo: [],
        permissions: [],
    },
    reducers: {
        groupUnmount: (state) => {
            state.groupInfo = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetGroupsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetGroupsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                try {
                    if (payload.data.data === undefined) {
                        state.groups = payload.data;
                    } else {
                        state.groups = payload.data.data;
                    }
                    state.totalItems = payload.data.total;
                    state.currentPage = payload.data.current_page;
                } catch (err) {
                    // console.log(err)
                }
            })
            .addCase(GetGroupsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddGroupAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddGroupAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddGroupAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateGroupAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateGroupAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateGroupAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteGroupAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteGroupAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteGroupAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(ShowGroupAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(ShowGroupAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.groupInfo = payload.data;
            })
            .addCase(ShowGroupAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetPermissionsExceptAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetPermissionsExceptAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.permissions = payload.data;
            })
            .addCase(GetPermissionsExceptAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })


    }
});
export default groupReducer.reducer;
export const { groupUnmount } = groupReducer.actions;
