import { BreadCrumb } from "primereact/breadcrumb";
import { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import "./AppBreadcrumb.scss";

const AppBreadcrumb = () => {
  const location = useLocation();

  const [breadcrumbItems, setBreadcrumbItems] = useState([]);

  const home = {
    icon: "pi pi-home",
    // url: "https://blueocean.sy",
    label: "Blue Ocean",
  };

  const getBreadcrumbItems = useCallback(() => {
    const pathnames = location.pathname
      .split("/")
      .filter((path) => path !== "");
    return pathnames.map((path, index) => {
      if (index === pathnames.length - 1) {
        path += " " + location.hash;
      }
      return {
        label: path.charAt(0).toUpperCase() + path.slice(1),
      };
    });
  }, [location]);

  useEffect(() => {
    const items = getBreadcrumbItems();
    setBreadcrumbItems(items);
  }, [location, getBreadcrumbItems]);

  return (
    <BreadCrumb
      model={breadcrumbItems}
      home={home}
      separatorIcon="pi pi-angle-right"
    />
  );
};

export default AppBreadcrumb;
