import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteCarCategoryAction,
  GetCarCategoriesAction,
} from "../../../redux/actions/car/CarGategoryAction";
import { showError, showSuccess } from "../../../utils/ToastService";
import { Button } from "primereact/button";
import { unwrapResult } from "@reduxjs/toolkit";

const DeleteCategory = ({ rowData, basicRows, toast, deleteModal }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.carCategory);
  const submitHandler = (e) => {
    e.preventDefault();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    dispatch(DeleteCarCategoryAction(rowData.id))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        deleteModal(false);
        dispatch(GetCarCategoriesAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="delete-group" onSubmit={submitHandler}>
        <h5>Do you like to delete this category ?</h5>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            type="button"
            icon="pi pi-times"
            onClick={() => deleteModal(false)}
          />
          <Button
            size="small"
            className="danger"
            label="Delete"
            icon="pi pi-trash"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default DeleteCategory;
