import React, { useState } from "react";
import LoadingFS from "../../components/loading/LoadingFS";
import { useSelector } from "react-redux";
import HotelRequestsDT from "./HotelRequestsDT";
import AbortHotelRequest from "./actions/AbortHotelRequest";
import { Dialog } from "primereact/dialog";
import BookHotelRequest from "./actions/BookHotelRequest";

const HotelRequests = ({ toast }) => {
  const { loading } = useSelector((state) => state.hotelRequests);
  const [abortModal, setAbortModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);
  const [bookModal, setBookModal] = useState(false);

  return (
    <div className="sticky-container">
      {loading && <LoadingFS />}
      {/* <Header header={"Hotel Requests Log"} path={"/hotel-requests/log"} /> */}

      <HotelRequestsDT
        toast={toast}
        abortModal={(visible, rowData, basicRows) => {
          setAbortModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        bookModal={(visible, rowData, basicRows) => {
          setBookModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />

      <Dialog
        header="Abort Hotel Booking"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={abortModal}
        onHide={() => setAbortModal(false)}
      >
        <AbortHotelRequest
          rowData={rowData}
          basicRows={basicRows}
          abortModal={(e) => setAbortModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Book Transfer Request"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={bookModal}
        onHide={() => setBookModal(false)}
      >
        <BookHotelRequest
          rowData={rowData}
          basicRows={basicRows}
          bookModal={(e) => setBookModal(e)}
          toast={toast}
        />
      </Dialog>
    </div>
  );
};

export default HotelRequests;
