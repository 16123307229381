import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import {
  AddOfferAction,
  GetOffersAction,
} from "../../../redux/actions/OfferAction";
import { unwrapResult } from "@reduxjs/toolkit";

const AddOffer = ({ basicRows, createModal, toast }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.visas);
  const [title, setTitle] = useState(undefined);
  const [type, setType] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const { offerTypes } = useSelector((state) => state.about);

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("title", title);
    obj.append("type", type);
    obj.append("image", image);
    dispatch(AddOfferAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        createModal(false);
        dispatch(GetOffersAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="add-visa" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Offer Title"
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <Dropdown
          required
          value={type}
          onChange={(e) => setType(e.value)}
          options={offerTypes}
          optionLabel="label"
          placeholder="Select an offer"
        />

        <div className="input-wrapper file-upload">
          <h4 className="">Offer Image</h4>
          <FileUpload
            mode="basic"
            chooseLabel="Choose an image"
            cancelLabel="Cancel"
            accept="image/*"
            onSelect={(event) => setImage(event.files[0])}
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="button"
          />
          <Button
            disabled={!image || !type || !title}
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default AddOffer;
