import { createSlice } from '@reduxjs/toolkit';
import { AbortFlightRequestAction, AddFlightSuggestAction, BookFlightRequestAction, DoneFlightRequestsAction, GetFlightRequestsAction, GetFlightRequestsLogAction, SuccessFlightRequestAction } from '../actions/FlightRequestAction';

export const flightRequestReducer = createSlice({
    name: "Flight Requests Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        flightRequests: [],
        log: [],
    },
    reducers: {
        flightsUnmount: (state) => {
            state.log = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetFlightRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetFlightRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.flightRequests = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetFlightRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetFlightRequestsLogAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetFlightRequestsLogAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.log = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetFlightRequestsLogAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })


            .addCase(AddFlightSuggestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddFlightSuggestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddFlightSuggestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DoneFlightRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DoneFlightRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DoneFlightRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(BookFlightRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(BookFlightRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(BookFlightRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(SuccessFlightRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(SuccessFlightRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(SuccessFlightRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AbortFlightRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AbortFlightRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AbortFlightRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
    }
});
export default flightRequestReducer.reducer;
export const { flightsUnmount } = flightRequestReducer.actions;
