import React from "react";
import { ProgressBar } from "primereact/progressbar";
import { Card } from "primereact/card";
import "./ProfitCard.scss";

const ProfitCard = ({ name, logoUrl, number, revenueVal, lossVal }) => {
  const header = (
    <div className="profit-card-title">
      <div className="right">
        <img src={logoUrl} alt="logo" className="logo" />
        <h2>{name}</h2>
      </div>
      <div className="left">
        {name} Requests: {number}
      </div>
    </div>
  );

  return (
    <Card className="profit-card" title={header}>
      <div className="progress-container">
        <div className="d-flex">
          <h4>Revenue: {revenueVal}%</h4>
          <ProgressBar
            className="profit"
            showValue={false}
            value={revenueVal}
            displayValueTemplate={() => revenueVal + "%"}
          />
        </div>
        <div className="d-flex">
          <h4>Loss: {lossVal}%</h4>
          <ProgressBar
            className="loss"
            showValue={false}
            value={lossVal}
            displayValueTemplate={() => lossVal + "%"}
          />
        </div>
      </div>
    </Card>
  );
};

export default ProfitCard;
