export const tabs = [
    {
        header: "Main",
        icon: "bi bi-pie-chart",
        tabs: [
            { label: "Statistics", name: "statistics", permission: "api->v1->cms->statistics->get" },
            { label: "About", name: "about", permission: "api->v1->cms->about->get" },
            { label: "Groups", name: "groups", permission: "api->v1->cms->groups->get" },
            { label: "Admins", name: "admins", permission: "api->v1->cms->admins->get" },
            { label: "Users", name: "users", permission: "api->v1->cms->users->get" },
            { label: "Countries", name: "countries", permission: "api->v1->cms->countries->get" },
            {
                label: "Car Categories",
                name: "car-categories",
                permission: "api->v1->cms->car->categories->get->cars",
            },
            { label: "Feedback", name: "feedback", permission: "api->v1->cms->feedback->get" },
            { label: "Offers", name: "offers", permission: "api->v1->cms->offers->get" },]
    },
    {
        header: "Visa",
        icon: "bi bi-passport",
        tabs: [{ label: "Visa", name: "visa", permission: "api->v1->cms->visas->get" },
        { label: "Visa Requests", name: "visa-requests#0", permission: "api->v1->cms->visa->requests->get" },
        { label: "Log", name: "visa-requests-log", permission: "api->v1->cms->visa->logs->get" },]
    },
    {
        header: "Travel",
        icon: "bi bi-suitcase-lg",
        tabs: [
            // { label: "Travel Insurance", name: "travel-insurance", permission: "api->v1->cms->insurances->get" },
            { label: "Travel Insurance Requests", name: "travel-insurance-requests#0", permission: "api->v1->cms->insurance->requests->get" },
            { label: "Log", name: "travel-insurance-requests-log", permission: "api->v1->cms->insurance->logs->get" },]
    },
    {
        header: "Transfer",
        icon: "bi bi-bag",
        tabs: [{ label: "Transfer", name: "transfer", permission: "api->v1->cms->transfers->get" },
        { label: "Transfer Requests", name: "transfer-requests#0", permission: "api->v1->cms->transfer->requests->get" },
        { label: "Log", name: "transfer-requests-log", permission: "api->v1->cms->transfer->logs->get" },]
    },
    {
        header: "Hotel",
        icon: "bi bi-building",
        tabs: [
            // { label: "Hotels", name: "hotels", permission: "api->v1->cms->hotel->get" },
            { label: "Hotel Booking", name: "hotel-requests#0", permission: "api->v1->cms->booking->hotel->get" },
            { label: "Log", name: "hotel-requests-log", permission: "api->v1->cms->booking->hotel->logs->get" },]

    },
    {
        header: "Flights",
        icon: "bi bi-airplane",
        tabs: [{ label: "Flight Requests", name: "flight-requests#0", permission: "api->v1->cms->flights->requests->get" },
        { label: "Log", name: "flight-requests-log", permission: "api->v1->cms->flights->logs->get" },]
    },
];
