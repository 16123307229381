import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../../constant";
import { AbortVisaRequestAPI, AddPriceVisaRequestAPI, BookVisaRequestAPI, DoneVisaRequestsAPI, GetVisaRequestsAPI, GetVisaRequestsLogAPI, SuccessVisaRequestAPI, } from "../../Api";

export const GetVisaRequestsAction = createAsyncThunk("cms/visa-requests/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetVisaRequestsAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
            + "&status=" + info.status);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DoneVisaRequestsAction = createAsyncThunk("cms/visa-requests/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(DoneVisaRequestsAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const SuccessVisaRequestsAction = createAsyncThunk("cms/visa-requests/success", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(SuccessVisaRequestAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AbortVisaRequestsAction = createAsyncThunk("cms/visa-requests/abort", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AbortVisaRequestAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const BookVisaRequestsAction = createAsyncThunk("cms/visa-requests/book", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(BookVisaRequestAPI + info.id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddPriceVisaRequestAction = createAsyncThunk("cms/visa-requests/price", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddPriceVisaRequestAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetVisaRequestsLogAction = createAsyncThunk("cms/visa-requests/log", async (info, { rejectWithValue }) => {
    try {
        let route;
        if (info.phone) {
            route = GetVisaRequestsLogAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
                + "&status=" + info.status + "&phone_number=" + info.phone
        }
        else {
            route = GetVisaRequestsLogAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
                + "&status=" + info.status
        }
        let { data } = await axiosInstance.get(route);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
