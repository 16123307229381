import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import LoadingFS from "../../components/loading/LoadingFS";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AirportsDT from "./AirportsDT";
import AddAirport from "./actions/AddAirport";
import UpdateAirport from "./actions/UpdateAirport";
import DeleteAirport from "./actions/DeleteAirport";
import { GetCountriesAction } from "../../redux/actions/CountryAction";
import { showError, showSuccess } from "../../utils/ToastService";
import { unwrapResult } from "@reduxjs/toolkit";

const Airports = ({ toast }) => {
  const dispatch = useDispatch();
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);
  const { loading } = useSelector((state) => state.airports);
  const { cityId } = useParams();

  const getCountriesHandler = () => {
    let info = { isPaginate: 0 };
    dispatch(GetCountriesAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <div className="sticky-container">
      {loading && <LoadingFS />}
      <AirportsDT
        cityId={cityId}
        toast={toast}
        createModal={(visible, rowData, basicRows) => {
          setCreateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        deleteModal={(visible, rowData, basicRows) => {
          setDeleteModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        updateModal={(visible, rowData, basicRows) => {
          setUpdateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />

      <Dialog
        header="Add Airport"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={createModal}
        onHide={() => setCreateModal(false)}
      >
        <AddAirport
          cityId={cityId}
          basicRows={basicRows}
          createModal={(e) => setCreateModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Update Airport"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
        onShow={getCountriesHandler}
      >
        <UpdateAirport
          cityId={cityId}
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          updateModal={(e) => setUpdateModal(e)}
        />
      </Dialog>

      <Dialog
        closeOnEscape
        header="Delete Airport"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
        onShow={() => {}}
      >
        <DeleteAirport
          cityId={cityId}
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          deleteModal={(e) => setDeleteModal(e)}
        />
      </Dialog>
    </div>
  );
};

export default Airports;
