export const getSeverity = (status, log = false) => {
    if (log) {
        switch (status) {
            case 0:
                return "danger";
            case 1:
                return "success";
            case 2:
                return "warning";
            default:
                break;
        }
    }
    else {
        switch (status) {
            case 0:
                return "info";
            case 1:
                return "warning";
            case 2:
                return "success";
            default:
                break;
        }
    }
};