import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GetTransfersInRangeAction } from "../../../redux/actions/transfer/TransferAction";
import { InputSwitch } from "primereact/inputswitch";
import { GetCarsAction } from "../../../redux/actions/car/CarAction";
import AddTransferSuggest from "./AddTransferSuggest";
import ClientsExplore from "../../maps/ClientsExplore";
import { Button } from "primereact/button";
import { formatNumberWithCommas } from "../../../utils/Formatters";
import "../../../assets/pages/show-request/ShowRequest.scss";
import { decodeBase64 } from "../../../utils/EncodeDecode";

const ShowTransferRequest = ({ toast }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const encodedData = params.get("data");
  const decodedData = JSON.parse(decodeBase64(encodedData));
  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(
      GetTransfersInRangeAction({
        from: decodedData.city_from.id,
        to: decodedData.city_to.id,
        isPaginate: 0,
      })
    );
    dispatch(
      GetCarsAction({
        categoryId: decodedData.car_category?.id,
        isPaginate: 0,
      })
    );
  }, [
    dispatch,
    decodedData.car_category?.id,
    decodedData.city_from.id,
    decodedData.city_to.id,
  ]);

  return (
    <div className="show-request">
      <div className="image-containers">
        <Button
          onClick={() => {
            navigate(-1);
          }}
          icon="pi pi-arrow-left"
          className="backward"
          rounded
          label="Back"
          text
          aria-label="Filter"
          severity="info"
        />
        <div className="title">
          <h3>Date Information</h3>
        </div>
        <div>
          <strong>Date:</strong> {decodedData.date}
        </div>
        <div>
          <strong>Time:</strong> {decodedData.time}
        </div>

        <div className="title ">
          <h3>People Information</h3>
        </div>
        <div>
          <strong>Number of Passengers:</strong> {decodedData.passengers_number}
        </div>
        <div>
          <strong>Number of Bags:</strong> {decodedData.bags_number}
        </div>

        <div className="title">
          <h3>User Information</h3>
        </div>
        <div>
          <strong>User:</strong> {decodedData.user?.first_name}{" "}
          {decodedData.user?.last_name}
        </div>
        <div>
          <strong>User Phone Number:</strong> {decodedData.user?.phone_number}
        </div>

        <div className="title">
          <h3>Car Information</h3>
        </div>
        <div>
          <strong>Car Name:</strong>{" "}
          {decodedData.car?.name ? decodedData.car?.name : "Not Assigned"}
        </div>
        <div>
          <strong>Car Category:</strong> {decodedData.car_category.name}
        </div>

        <div className="title">
          <h3>Transfer Information</h3>
        </div>
        <div>
          <strong>Transfer Price:</strong>{" "}
          {formatNumberWithCommas(decodedData.price)}
        </div>
        <div>
          <strong>Transfer From: </strong>
          {decodedData.city_from.country.name}, {decodedData.city_from.name}
        </div>
        <div>
          <strong>Transfer To: </strong>
          {decodedData.city_to.country.name}, {decodedData.city_to.name}
        </div>
        {decodedData.location && (
          <ClientsExplore places={[decodedData?.location]} toast={toast} />
        )}
        {decodedData.is_valid === null && decodedData.admin !== null && (
          <>
            <div className="check-btn">
              <h4>Valid</h4>
              <InputSwitch
                checked={checked}
                onChange={(e) => setChecked(e.value)}
              />
              <h4>Invalid</h4>
            </div>

            <AddTransferSuggest
              id={decodedData.id}
              toast={toast}
              isValid={decodedData.is_valid}
              checked={checked}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ShowTransferRequest;
