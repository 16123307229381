import { createSlice } from '@reduxjs/toolkit';
import { AbortVisaRequestsAction, AddPriceVisaRequestAction, BookVisaRequestsAction, DoneVisaRequestsAction, GetVisaRequestsAction, GetVisaRequestsLogAction, SuccessVisaRequestsAction } from '../../actions/visa/VisaRequestAction';
export const visaRequestReducer = createSlice({
    name: "Visa Request Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        visaRequests: [],
        log: [],
    },
    reducers: {
        visasUnmount: (state) => {
            state.log = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetVisaRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetVisaRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.visaRequests = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetVisaRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(GetVisaRequestsLogAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetVisaRequestsLogAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.log = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetVisaRequestsLogAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DoneVisaRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DoneVisaRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DoneVisaRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddPriceVisaRequestAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddPriceVisaRequestAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddPriceVisaRequestAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(BookVisaRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(BookVisaRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(BookVisaRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(SuccessVisaRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(SuccessVisaRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(SuccessVisaRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AbortVisaRequestsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AbortVisaRequestsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AbortVisaRequestsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

    }
});
export default visaRequestReducer.reducer;
export const { visasUnmount } = visaRequestReducer.actions;
