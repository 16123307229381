import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../../constant";
import { AddVisaAPI, AddVisaTypeAPI, DeleteVisaAPI, DeleteVisaTypeAPI, GetVisaAPI, GetVisaTypesAPI, UpdateVisaAPI } from "../../Api";

export const GetVisaAction = createAsyncThunk("cms/visa/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetVisaAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddVisaAction = createAsyncThunk("cms/visa/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddVisaAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateVisaAction = createAsyncThunk("cms/visa/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(UpdateVisaAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteVisaAction = createAsyncThunk("cms/visa/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteVisaAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

// * Visa Types *
export const GetVisaTypesAction = createAsyncThunk("cms/visa/types/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetVisaTypesAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddVisaTypeAction = createAsyncThunk("cms/visa/type/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddVisaTypeAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteVisaTypeAction = createAsyncThunk("cms/visa/type/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteVisaTypeAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});