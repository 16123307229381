import { createSlice } from '@reduxjs/toolkit';
import { AddVisaAction, AddVisaTypeAction, DeleteVisaAction, DeleteVisaTypeAction, GetVisaAction, GetVisaTypesAction, UpdateVisaAction } from '../../actions/visa/VisaAction';
export const visaReducer = createSlice({
    name: "Visa Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        visas: [],
        visaTypes: [],
        typesLoading: false
    },
    reducers: {
        visaUnmount: (state) => {
            state.visas = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetVisaAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetVisaAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.visas = payload.data.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetVisaAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddVisaAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddVisaAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddVisaAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateVisaAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateVisaAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateVisaAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteVisaAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteVisaAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteVisaAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })


            // * Visa Types *
            .addCase(GetVisaTypesAction.pending, (state) => {
                state.typesLoading = true;
            })
            .addCase(GetVisaTypesAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.typesLoading = false;
                state.visaTypes = payload.data;
            })
            .addCase(GetVisaTypesAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.typesLoading = false;
            })

            .addCase(AddVisaTypeAction.pending, (state) => {
                state.typesLoading = true;
            })
            .addCase(AddVisaTypeAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.typesLoading = false;
            })
            .addCase(AddVisaTypeAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.typesLoading = false;
            })

            .addCase(DeleteVisaTypeAction.pending, (state) => {
                state.typesLoading = true;
            })
            .addCase(DeleteVisaTypeAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.typesLoading = false;
            })
            .addCase(DeleteVisaTypeAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.typesLoading = false;
            })
    }
});
export default visaReducer.reducer;
export const { visaUnmount } = visaReducer.actions;
