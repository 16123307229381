import React, { useEffect } from "react";
import UserRequestsDT from "./UserRequestsDT";
import UserCard from "./UserCard";
import LoadingFS from "../../components/loading/LoadingFS";
import { useDispatch, useSelector } from "react-redux";
import { ShowUserAction } from "../../redux/actions/UserAction";
import { useLocation, useParams } from "react-router-dom";
import { userUnmount } from "../../redux/reducers/UserReducer";
import "../../assets/pages/user-profile/UserProfile.scss";
import ClientsExplore from "../maps/ClientsExplore";
import MiniLoading from "../../components/mini-loading/MiniLoading";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError } from "../../utils/ToastService";

const UserProfile = ({ toast }) => {
  const dispatch = useDispatch();
  const { userInfo, loading } = useSelector((state) => state.users);
  let { userId } = useParams();
  const { search } = useLocation();
  let phone = search.split("?")[1];
  useEffect(() => {
    dispatch(ShowUserAction(userId))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
    return () => {
      dispatch(userUnmount());
    };
  }, [dispatch, userId, toast]);

  return (
    <>
      {loading && <LoadingFS />}
      <div className="user-profile">
        <UserCard userInfo={userInfo?.user} />
        <UserRequestsDT phone={phone} toast={toast} />
        {userInfo?.locations ? (
          <ClientsExplore places={userInfo?.locations} toast={toast} />
        ) : (
          <MiniLoading />
        )}
      </div>
    </>
  );
};

export default UserProfile;
