import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import {
  GetVisaAction,
  UpdateVisaAction,
} from "../../../redux/actions/visa/VisaAction";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { MultiStateCheckbox } from "primereact/multistatecheckbox";
import { unwrapResult } from "@reduxjs/toolkit";

const UpdateVisa = ({ rowData, basicRows, updateModal, toast, countries }) => {
  const dispatch = useDispatch();
  const { loading, currentPage, visaTypes } = useSelector(
    (state) => state.visas
  );
  const [visaPrice, setVisaPrice] = useState(undefined);
  const [selectedCountry, setSelectedCountry] = useState(rowData.city);
  const [selectedType, setSelectedtype] = useState(rowData.type);
  const [period, setPeriod] = useState();
  const [value, setValue] = useState();
  const options = [
    { value: "days", label: "Days" },
    { value: "months", label: "Months" },
    { value: "years", label: "Years" },
  ];

  useEffect(() => {
    setValue(rowData.period.split(" ")[1]);
    setPeriod(rowData.period.split(" ")[0]);
  }, [rowData.period]);

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("price", visaPrice ?? rowData.price);
    obj.append("city_id", selectedCountry.id);
    obj.append("visa_type_id", selectedType.id);
    if (period) {
      obj.append("period", period + " " + value);
    }
    let data = { id: rowData.id, obj: obj };
    dispatch(UpdateVisaAction(data))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        updateModal(false);
        dispatch(GetVisaAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <div className="container">
      <form className="add-visa" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputNumber
            className="custom-number-input"
            value={rowData.price}
            placeholder="Visa Price"
            onChange={(e) => setVisaPrice(e.value)}
          />
        </div>

        <div className="input-wrapper">
          <Dropdown
            valueTemplate={selectedCountry.name}
            value={selectedCountry}
            onChange={(e) => {
              setSelectedCountry(e.value);
            }}
            options={countries}
            optionLabel="name"
            placeholder="Select a Country"
            filter
          />
        </div>

        <div className="input-wrapper">
          <Dropdown
            required
            value={selectedType}
            onChange={(e) => {
              setSelectedtype(e.value);
              setPeriod(0);
              setValue("days");
            }}
            options={visaTypes}
            optionLabel="name"
            placeholder="Select a Visa Type"
          />
        </div>

        {selectedType.have_period === 1 && (
          <div className="p-inputgroup input-wrapper">
            <InputNumber
              value={period}
              required
              placeholder="Input a number"
              onChange={(e) => setPeriod(e.value)}
            />
            <MultiStateCheckbox
              value={value}
              onChange={(e) => setValue(e.value)}
              options={options}
              optionValue="value"
              iconTemplate={(e) => {
                return <h6>{e.option?.label}</h6>;
              }}
              empty={false}
            />
          </div>
        )}

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            type="button"
            onClick={() => updateModal(false)}
          />
          <Button
            size="small"
            label="Update"
            icon="pi pi-check"
            className="info"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateVisa;
