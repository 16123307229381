import { createSlice } from '@reduxjs/toolkit';
import { GetNotificationsAction, PushNotificationAction, ReadNotificationAction } from '../actions/NotificationsAction';
export const notificationsReducer = createSlice({
    name: "Notifications Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        notifications: [],
    },
    reducers: {
        notificationsUnmount: (state) => {
            state.notifications = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetNotificationsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetNotificationsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.notifications = [
                    ...state.notifications,
                    ...payload.data.data.filter((newNotification) => {
                        return !state.notifications.some((notification) => notification.id === newNotification.id);
                    })
                ];
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetNotificationsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(ReadNotificationAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(ReadNotificationAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(ReadNotificationAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(PushNotificationAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(PushNotificationAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(PushNotificationAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

    }
});
export default notificationsReducer.reducer;
export const { notificationsUnmount } = notificationsReducer.actions;
