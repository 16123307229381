import { createSlice } from '@reduxjs/toolkit';
import { GetAboutUsAction, PostAboutUsAction } from '../actions/AboutUsAction';
export const aboutUsReducer = createSlice({
    name: "About Us Reducer",
    initialState: {
        loading: false,
        about: [],
        offerTypes: [],
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAboutUsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetAboutUsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.about = payload.data;
                state.offerTypes = [
                    { value: 1, label: payload?.data?.about_title_1_en, },
                    { value: 2, label: payload?.data?.about_title_2_en, }
                ]
            })
            .addCase(GetAboutUsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(PostAboutUsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(PostAboutUsAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                state.about = payload.data;
            })
            .addCase(PostAboutUsAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
    }
});
export default aboutUsReducer.reducer; 