import React from "react";
import "./Welcome.scss";
import { groupName, phoneNumber, username } from "../../redux/constant";

const Welcome = () => {
  return (
    <div className="welcome">
      <div className="welcome-container">
        <div className="custom-shape-divider-top">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 120L0 16.48 0 0 1200 0 1200 120z"
              className="shape-fill"
            ></path>
          </svg>
        </div>

        <div className="logo">
          <img src="/Images/oldLogo.png" alt="welcome-logo"></img>
        </div>
        <h1>Welcome to Blue Ocean Dashboard!</h1>
        <p>Your gateway to seamless travel experiences awaits.</p>

        <div className="user">
          <div className="name">
            <i className="bi bi-person-fill"></i>
            {username}
          </div>
          <div className="group">
            <i className="bi bi-people-fill"></i>
            {groupName}
          </div>
          <div className="phone">
            <i className="bi bi-telephone-fill"></i>
            {phoneNumber}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
