import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  AssignPermissionsAction,
  GetPermissionsExceptAction,
  ShowGroupAction,
} from "../../../redux/actions/GroupAction";
import { groupUnmount } from "../../../redux/reducers/GroupReducer";
import { Button } from "primereact/button";
import { showError, showSuccess } from "../../../utils/ToastService";
import { MultiSelect } from "primereact/multiselect";
import "../../../assets/pages/group/ShowGroup.scss";
import { unwrapResult } from "@reduxjs/toolkit";

const ShowGroup = ({ toast }) => {
  const dispatch = useDispatch();

  const { groupId } = useParams();
  const { groupInfo, loading } = useSelector((state) => state.group);
  const [permissions, setPermissions] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [deletedPermissions, setDeletedPermissions] = useState([]);
  useEffect(() => {
    if (groupInfo && groupInfo.permissions) {
      const filteredObjects = groupInfo.permissions.map(({ name, id }) => ({
        name,
        id,
      }));
      setSelectedPermissions(filteredObjects);
    }
  }, [groupInfo, toast]);

  useEffect(() => {
    dispatch(ShowGroupAction(groupId))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
    dispatch(GetPermissionsExceptAction())
      .then(unwrapResult)
      .then((payload) => {
        const filteredObjects = payload.data.map(({ name, id }) => ({
          name,
          id,
        }));
        setPermissions(filteredObjects);
      })
      .catch((error) => {
        showError(error, toast);
      });
    return () => {
      dispatch(groupUnmount());
    };
  }, [dispatch, groupId, toast]);

  const handleMultiSelectChange = (e) => {
    const selectedList = e.value;
    const removedItem = selectedPermissions?.filter(
      (permission) => !selectedList?.includes(permission)
    );

    setSelectedPermissions(selectedList);

    setDeletedPermissions((prevDeletedPermissions) => [
      ...prevDeletedPermissions,
      ...removedItem,
    ]);
  };

  const SubmitHandler = (e) => {
    e.preventDefault();
    let info;
    let formData = new FormData();

    for (let i = 0; i < selectedPermissions.length; i++) {
      for (let j = 0; j < deletedPermissions.length; j++) {
        if (selectedPermissions[i] === deletedPermissions[j]) {
          deletedPermissions.splice(j);
        }
      }
    }

    for (let i = 0; i < selectedPermissions.length; i++) {
      formData.append(`permission[${i}]`, selectedPermissions[i].id);
    }
    for (let i = 0; i < deletedPermissions.length; i++) {
      formData.append(`delete_permission[${i}]`, deletedPermissions[i].id);
    }

    info = { id: groupId, obj: formData };
    dispatch(AssignPermissionsAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        dispatch(ShowGroupAction(groupId));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="show-group">
      <form className="container" onSubmit={SubmitHandler}>
        <div className="header">Group Information</div>
        <div className="permissions-header">
          <h2>Group Permissions</h2>
        </div>

        {groupInfo?.permissions?.length === 0 && (
          <div>No permissions assigned yet</div>
        )}

        <ul>
          {groupInfo?.permissions?.map((permission, index) => (
            <li key={index}>{permission.name}</li>
          ))}
        </ul>

        <h2>{groupInfo?.name}</h2>
        <p>Created At: {groupInfo?.created_at}</p>
        <p>Updated At: {groupInfo?.updated_at}</p>
        <div>
          <h2 className="select-header">Permissions Editing</h2>
          <MultiSelect
            appendTo={"self"}
            showSelectAll={false}
            value={selectedPermissions}
            options={permissions}
            onChange={handleMultiSelectChange}
            optionLabel="name"
            placeholder="Select Permissions"
            filter
            showClear
          />
        </div>

        <div className="actions">
          <Button
            size="small"
            className="info"
            label="Assign"
            type="submit"
            loading={loading}
          />
        </div>
      </form>
    </div>
  );
};

export default ShowGroup;
