import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging'

const firebaseConfig = {
    apiKey: "AIzaSyB76Yz_7CM_R5oVKfQh6_d29nYP7PY7qEI",
    authDomain: "blue-ocean-6bafe.firebaseapp.com",
    projectId: "blue-ocean-6bafe",
    storageBucket: "blue-ocean-6bafe.appspot.com",
    messagingSenderId: "102988084651",
    appId: "1:102988084651:web:1bb7920cafc66fafb14eee",
    measurementId: "G-FX1NCWV7Y8"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const requestPermission = async (retryCount = 3, delay = 1000) => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const currentToken = await getToken(messaging, {
                vapidKey:
                    'BBA4Z4Nz7nLIfLOqL1F1cUftOD7rdUfnPG9OLjUIOktRn-nErY83-1r94WicAlKEQ0PYFHaYcA4bgqIAe2HH3Lw'
            });
            if (currentToken) {
                // console.log("FCM Token: ", currentToken);
                localStorage.setItem("fcmToken", currentToken);
                const fcmWarning = document.getElementById("fcm-warning");
                if (fcmWarning) {
                    fcmWarning.innerHTML = "";
                }
            } else {
                console.log('No registration token available. Request permission to generate one.');
                const fcmWarning = document.getElementById("fcm-warning");
                if (fcmWarning) {
                    fcmWarning.innerHTML = "No registration token available. Request permission to generate one.";
                }
            }
        } else {
            console.log('Notification permission denied');
            const notificationPermission = document.getElementById("notification-permission");
            if (notificationPermission) {
                notificationPermission.innerHTML = "Please Allow Notification Permissions";
            }
        }
    } catch (error) {
        console.log('An error occurred while retrieving token. ', error);
        const fcmWarning = document.getElementById("fcm-warning");
        if (fcmWarning) {
            fcmWarning.innerHTML = "Please Wait ...";
        }
        if (retryCount > 0) {
            console.log(`Retrying... Attempts left: ${retryCount}`);
            // Retry after delay
            await new Promise(resolve => setTimeout(resolve, delay));
            await requestPermission(retryCount - 1, delay);
        } else {
            console.log('Exceeded maximum retry attempts. Giving up.');
            const fcmWarning = document.getElementById("fcm-warning");
            if (fcmWarning) {
                fcmWarning.innerHTML = "Exceeded maximum retry attempts. Giving up.";
            }
            // Optionally, handle case where maximum retry attempts are exceeded
            throw error;
        }
    }
}


export const onMessageListener = (callback) =>
    new Promise(() => {
        const unsubscribe = onMessage(messaging, (payload) => {
            // console.log("payload", payload);
            callback(payload);
        });
        return unsubscribe;
    });