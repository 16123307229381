import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddFlightSuggest from "./AddFlightSuggest";
import { Image } from "primereact/image";
import { imageURL } from "../../../redux/Api";
import ClientsExplore from "../../maps/ClientsExplore";
import { Button } from "primereact/button";
import { formatNumberWithCommas } from "../../../utils/Formatters";
import "../../../assets/pages/show-request/ShowRequest.scss";
import { decodeBase64 } from "../../../utils/EncodeDecode";

const ShowFlightRequest = ({ toast }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const encodedData = params.get("data");
  const decodedData = JSON.parse(decodeBase64(encodedData));
  const navigate = useNavigate();

  const flightTypeHandler = () => {
    switch (decodedData.type) {
      case 0:
        return "One Way";
      case 1:
        return "Round Trip";
      default:
        break;
    }
  };
  const flightClassHandler = () => {
    switch (decodedData.class) {
      case 0:
        return "Economic";
      case 1:
        return "First Class";
      case 2:
        return "Business";
      default:
        break;
    }
  };
  return (
    <div className="show-request">
      <div className="image-containers">
        <Button
          onClick={() => {
            navigate(-1);
          }}
          icon="pi pi-arrow-left"
          className="backward"
          rounded
          label="Back"
          text
          aria-label="Filter"
          severity="info"
        />
        <div className="title">
          <h3>Date Information</h3>
        </div>
        <div className="info">
          <strong>Request Created At:</strong> {decodedData.created_at}
        </div>
        <div className="info">
          <strong>Request Updated At:</strong> {decodedData.updated_at}
        </div>
        <div className="info">
          <strong>Depart Date:</strong> {decodedData.depart_date}
        </div>
        <div className="info">
          <strong>Return Date:</strong> {decodedData.return_date}
        </div>

        <div className="title ">
          <h3>People Information</h3>
        </div>
        <div className="info">
          <strong>Number of Adults:</strong> {decodedData.adults_number}
        </div>
        <div className="info">
          <strong>Number of Infants:</strong> {decodedData.infants_number}
        </div>
        <div className="info">
          <strong>Number of Children:</strong> {decodedData.children_number}
        </div>

        <div className="title">
          <h3>User Information</h3>
        </div>
        <div className="info">
          <strong>User:</strong> {decodedData.user?.first_name}{" "}
          {decodedData.user?.last_name}
        </div>
        <div className="info">
          <strong>User Phone Number:</strong> {decodedData.user?.phone_number}
        </div>

        <div className="title">
          <h3>Flight Information</h3>
        </div>
        <div className="info">
          <strong>Price</strong>
          {decodedData.price
            ? formatNumberWithCommas(decodedData.price)
            : "Not Assigned"}
        </div>
        <div className="info">
          <strong>From:</strong> {decodedData.airport_from.name}
        </div>
        <div className="info">
          <strong>To:</strong> {decodedData.airport_to.name}
        </div>
        <div className="info">
          <strong>Flight Type:</strong> {flightTypeHandler()}
        </div>
        <div className="info">
          <strong>Flight Class:</strong> {flightClassHandler()}
        </div>

        {decodedData.location && (
          <ClientsExplore places={[decodedData?.location]} toast={toast} />
        )}

        {decodedData?.passport_images.map((item, index) => {
          return (
            <div key={index} className="passport-image">
              <h3>Passport for client number {index + 1}</h3>
              <div className="images">
                <Image
                  className="image"
                  src={imageURL + item.path}
                  alt="passport_first_page"
                  loading="lazy"
                  preview
                  downloadable
                  children={document.querySelector(".passport-image")}
                />
                <Image
                  className="image"
                  src={imageURL + item?.second_image?.path}
                  alt="passport_first_page"
                  loading="lazy"
                  preview
                  downloadable
                  children={document.querySelector(".passport-image")}
                />
              </div>
            </div>
          );
        })}
        {decodedData.ticket_images.length !== 0 && (
          <div className="ticket-images">
            <h3>Document Images</h3>
            {decodedData.ticket_images.map((item, index) => {
              return (
                <Image
                  key={index}
                  className="image"
                  src={imageURL + item.path}
                  alt="document_page"
                  loading="lazy"
                  preview
                  downloadable
                  children={document.querySelector(".passport-image")}
                />
              );
            })}
          </div>
        )}
        {decodedData.is_valid === null && decodedData.admin !== null && (
          <AddFlightSuggest
            id={decodedData.id}
            toast={toast}
            isValid={decodedData.is_valid}
            type={decodedData.type}
          />
        )}
      </div>
    </div>
  );
};

export default ShowFlightRequest;
