import React, { useState } from "react";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { GetFetchedCarsAction } from "../../../redux/actions/car/CarAction";
import { AddTransferSuggestAction } from "../../../redux/actions/transfer/TransferRequestAction";
import { showError, showSuccess, showWarn } from "../../../utils/ToastService";
import "../../../assets/pages/transfer-request/AddTransferSuggest.scss";
import { useNavigate } from "react-router-dom";
import { formatNumberWithCommas } from "../../../utils/Formatters";
import { unwrapResult } from "@reduxjs/toolkit";

const AddTransferSuggest = ({ id, toast, checked, isValid }) => {
  const [formData, setFormData] = useState([]);
  const [formCount, setFormCount] = useState(1);
  const dispatch = useDispatch();
  const { rangedTransfers } = useSelector((state) => state.transfers);
  const { loading } = useSelector((state) => state.transferRequests);
  const navigate = useNavigate();
  const [selectedCar, setSelectedCar] = useState(true);
  const { cars } = useSelector((state) => state.cars);

  const handleAddForm = () => {
    setFormCount(formCount + 1);
    const newForm = {
      id: formCount + 1,
      data: { transfer: "" },
      carOptions: [],
    };
    setFormData([...formData, newForm]);
  };

  const handleRemoveForm = (id) => {
    const updatedFormData = formData.filter((form) => form.id !== id);
    setFormData(updatedFormData);
  };

  const handleInputChange = async (e, formId, inputName) => {
    const { value } = e.target || e;
    if (inputName === "transfer") {
      let info = {
        categoryId: value?.car_category?.id,
        isPaginate: 0,
      };
      try {
        const response = await dispatch(GetFetchedCarsAction(info));
        const fetchedCars = response?.payload?.data || [];

        const updatedFormData = formData.map((form) => {
          if (form.id === formId) {
            return {
              ...form,
              data: {
                ...form.data,
                [inputName]: value,
              },
              carOptions: fetchedCars,
            };
          }
          return form;
        });

        setFormData(updatedFormData);
      } catch (error) {
        // console.error("Error:", error);
      }
    } else {
      const updatedFormData = formData.map((form) => {
        if (form.id === formId) {
          return {
            ...form,
            data: {
              ...form.data,
              [inputName]: value,
            },
          };
        }
        return form;
      });

      setFormData(updatedFormData);
    }
  };
  const handleSubmit = () => {
    const formattedFormData = formData.map((form) => {
      const newData = { ...form.data };

      for (let i = 0; i < formData.length; i++) {
        // console.log(formData[i]["data"].transfer);
      }
      // Handle date formatting
      if (newData["date"]) {
        const selectedDate = new Date(newData["date"]);
        const timezoneOffset = selectedDate.getTimezoneOffset() * 60000;
        const dateInLocalTimezone = new Date(selectedDate - timezoneOffset);
        newData["date"] = dateInLocalTimezone.toISOString().split("T")[0];
      }

      // Handle time formatting
      if (newData["time"]) {
        const date = new Date(newData["time"]);
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        newData["time"] = `${hours}:${minutes}`;
      }

      return {
        ...form,
        data: newData,
      };
    });

    const isAnyFieldEmpty = formattedFormData.some((form) => {
      return (
        !form.data["date"] ||
        !form.data["time"] ||
        !form.data["car"]?.id ||
        !form.data["transfer"]?.id
      );
    });

    if (isAnyFieldEmpty) {
      showWarn("Please fill in all fields before submitting.", toast);
      return;
    }

    const newFormData = new FormData();

    formattedFormData.forEach((form, i) => {
      newFormData.append(
        `transfer_suggests[${i}][date]`,
        form.data["date"] || ""
      );
      newFormData.append(
        `transfer_suggests[${i}][time]`,
        form.data["time"] || ""
      );
      newFormData.append(
        `transfer_suggests[${i}][car_id]`,
        form.data["car"]?.id || ""
      );
      newFormData.append(
        `transfer_suggests[${i}][transfer_id]`,
        form.data["transfer"]?.id || ""
      );
    });

    newFormData.append("is_valid", checked ? "0" : "1");
    if (!checked) newFormData.append("car_id", selectedCar.id);

    let info = {
      id: id,
      obj: newFormData,
    };

    dispatch(AddTransferSuggestAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        navigate(-1);
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="item">
        <div className="car">
          <i className="bi bi-car-front"></i>
          <div>{option?.car_category?.name}</div>
        </div>
        <div style={{ padding: "0px 10px" }}>|</div>
        <div className="price">
          <div>{formatNumberWithCommas(option?.price)}</div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        {formData.map((form, index) => (
          <div key={form.id} className="add-transfer-suggest">
            <form className="suggest-form">
              <div className="header">
                <h4 className="suggest-number">Suggest number: {index + 1}</h4>
                <div className="cancel">
                  <Button
                    size="small"
                    icon="bi bi-x-lg"
                    // disabled={!click}
                    className="p-button-rounded p-button-text p-button-danger"
                    type="button"
                    onClick={() => handleRemoveForm(form.id)}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <Calendar
                  required
                  name="date"
                  placeholder="Date"
                  value={form.data["date"] || ""}
                  onChange={(e) => handleInputChange(e, form.id, "date")}
                  dateFormat="yy-mm-dd"
                  showIcon
                  showButtonBar
                />
              </div>

              <div className="input-wrapper">
                <Calendar
                  required
                  name="time"
                  placeholder="Time"
                  value={form.data["time"] || ""}
                  onChange={(e) => handleInputChange(e, form.id, "time")}
                  timeOnly
                  hourFormat="24"
                  showIcon
                />
              </div>

              <div className="input-wrapper">
                <Dropdown
                  name="transfer"
                  appendTo={"self"}
                  itemTemplate={countryOptionTemplate}
                  required
                  value={form.data["transfer"] || ""}
                  onChange={(e) => handleInputChange(e, form.id, "transfer")}
                  options={rangedTransfers}
                  optionLabel={(e) => {
                    return e.price + " | " + e.car_category.name;
                  }}
                  placeholder="Transfer"
                />
              </div>

              <div className="input-wrapper">
                <Dropdown
                  name="car"
                  required
                  value={form.data["car"] || ""}
                  onChange={(e) => handleInputChange(e, form.id, "car")}
                  options={form.carOptions}
                  optionLabel="name"
                  placeholder="Car"
                />
              </div>
            </form>
          </div>
        ))}
        {isValid === null && !checked && (
          <div className="input-wrapper">
            <Dropdown
              name="car"
              required
              value={selectedCar}
              onChange={(e) => setSelectedCar(e.target.value)}
              options={cars}
              optionLabel="name"
              placeholder="Car"
            />
          </div>
        )}
        <div className="actions">
          <Button
            size="small"
            className="warning"
            onClick={handleAddForm}
            label={"Add Suggest"}
            type="button"
          />
        </div>
        {/* {formData.length !== 0 && ( */}
        <div className="done-btn">
          <Button
            loading={loading}
            label="Submit Suggestions"
            type="button"
            size="small"
            className="info"
            aria-label="Submit"
            onClick={handleSubmit}
          />
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default AddTransferSuggest;
