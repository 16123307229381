import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../../constant";
import { AbortHotelRequestAPI, AddHotelSuggestAPI, AddPriceHotelRequestAPI, BookHotelRequestAPI, DoneHotelRequestsAPI, GetHotelRequestsAPI, GetHotelRequestsLogAPI, SuccessHotelRequestAPI } from "../../Api";

export const GetHotelRequestsAction = createAsyncThunk("cms/hotel-requests/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetHotelRequestsAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
            + "&status=" + info.status);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DoneHotelRequestsAction = createAsyncThunk("cms/hotel-requests/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(DoneHotelRequestsAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const SuccessHotelRequestAction = createAsyncThunk("cms/hotel-requests/success", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(SuccessHotelRequestAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const BookHotelRequestAction = createAsyncThunk("cms/hotel-requests/book", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(BookHotelRequestAPI + info.id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddPriceHotelRequestAction = createAsyncThunk("cms/hotel-requests/price", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddPriceHotelRequestAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddHotelSuggestAction = createAsyncThunk("cms/hotel-requests/suggest/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddHotelSuggestAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AbortHotelRequestAction = createAsyncThunk("cms/hotel-requests/abort", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AbortHotelRequestAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetHotelRequestsLogAction = createAsyncThunk("cms/hotel-requests/log", async (info, { rejectWithValue }) => {
    try {
        let route;
        if (info.phone) {
            route = GetHotelRequestsLogAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
                + "&status=" + info.status + "&phone_number=" + info.phone
        }
        else {
            route = GetHotelRequestsLogAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
                + "&status=" + info.status
        }
        let { data } = await axiosInstance.get(route);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
