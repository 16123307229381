import axios from "axios";

export const adminToken = localStorage.getItem("adminToken");
export const errorMessage = "Something went wrong .. please try again later";
export const paginatorSize = 15;
export const dtTranstion = "animate__animated animate__fadeIn";
export const fcmToken = localStorage.getItem("fcmToken");
export const username = localStorage.getItem("username");
export const phoneNumber = localStorage.getItem("phone");
export const groupName = localStorage.getItem("group");
export const adminPermissions = localStorage.getItem("permission")?.split(",");
export const isSuperAdmin = localStorage.getItem("isSuper")?.split(",").toString();

const axiosInstance = axios.create({
  headers: {
    "Authorization": `Bearer ${adminToken}`,
  },
});

// Add a response interceptor to handle 401 responses
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
