import React, { useState } from "react";
import AdminsDT from "./AdminsDT";
import AddAdmin from "./actions/AddAdmin";
import { Dialog } from "primereact/dialog";
import UpdateAdmin from "./actions/UpdateAdmin";
import DeleteAdmin from "./actions/DeleteAdmin";
import LoadingFS from "../../components/loading/LoadingFS";
import { useDispatch, useSelector } from "react-redux";
import { GetGroupsAction } from "../../redux/actions/GroupAction";
import { showError, showSuccess } from "../../utils/ToastService";
import ChgPasswordAdmin from "./actions/ChgPasswordAdmin";
import { unwrapResult } from "@reduxjs/toolkit";

const Admins = ({ toast }) => {
  const dispatch = useDispatch();
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);
  const groupLoading = useSelector((state) => state.group.loading);
  const { loading } = useSelector((state) => state.admins);
  const { groups } = useSelector((state) => state.group);
  const getGroupsHandler = () => {
    let info = { isPaginate: 0 };
    dispatch(GetGroupsAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="sticky-container">
      {groupLoading && <LoadingFS />}
      {loading && <LoadingFS />}

      <AdminsDT
        toast={toast}
        createModal={(visible, rowData, basicRows) => {
          setCreateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        deleteModal={(visible, rowData, basicRows) => {
          setDeleteModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        updateModal={(visible, rowData, basicRows) => {
          setUpdateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        passwordModal={(visible, rowData, basicRows) => {
          setPasswordModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />

      <Dialog
        header="Add An Admin"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={createModal}
        onHide={() => setCreateModal(false)}
        onShow={getGroupsHandler}
      >
        <AddAdmin
          groups={groups}
          basicRows={basicRows}
          createModal={(e) => setCreateModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Update An Admin"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
        onShow={getGroupsHandler}
      >
        <UpdateAdmin
          groups={groups}
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          updateModal={(e) => setUpdateModal(e)}
        />
      </Dialog>

      <Dialog
        closeOnEscape
        header="Delete An Admin"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
      >
        <DeleteAdmin
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          deleteModal={(e) => setDeleteModal(e)}
        />
      </Dialog>

      <Dialog
        closeOnEscape
        header="Change Admin Password"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={passwordModal}
        onHide={() => setPasswordModal(false)}
      >
        <ChgPasswordAdmin
          passwordModal={(e) => setPasswordModal(e)}
          rowData={rowData}
          toast={toast}
        />
      </Dialog>
    </div>
  );
};

export default Admins;
