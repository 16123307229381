import React from "react";
import "./MiniLoading.scss";
const MiniLoading = () => {
  return (
    <div className="mini-loading">
      <i className="pi pi-spin pi-spinner"></i>
    </div>
  );
};

export default MiniLoading;
