
import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../constant";
import { GetNotificationsAPI, PushNotificationAPI, ReadNotificationAPI } from "../Api";

export const GetNotificationsAction = createAsyncThunk("admin/notifications/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetNotificationsAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});


export const ReadNotificationAction = createAsyncThunk("admin/notifications/read", async (id, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(ReadNotificationAPI + id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const PushNotificationAction = createAsyncThunk("admin/notification/push", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(PushNotificationAPI + "?type=" + info.type, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});