import React, { useEffect, useState } from "react";
import NotificationCard from "../../components/notification-card/NotificationCard";
import { useDispatch, useSelector } from "react-redux";
import { GetNotificationsAction } from "../../redux/actions/NotificationsAction";
import { Button } from "primereact/button";
import LoadingFS from "../../components/loading/LoadingFS";
import { notificationsUnmount } from "../../redux/reducers/NotificationsReducer.";
import "./NotificationStyles.scss";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError } from "../../utils/ToastService";

const Notifications = ({ toast }) => {
  const dispatch = useDispatch();
  const { notifications, loading, totalItems } = useSelector(
    (state) => state.notifications
  );
  const [page, setPage] = useState(1);
  const handleLoadMore = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    let info = { size: 10, page: page, isPaginate: 1 };
    dispatch(GetNotificationsAction(info))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  }, [dispatch, page, toast]);

  useEffect(() => {
    return () => {
      dispatch(notificationsUnmount());
    };
  }, [dispatch, toast]);

  return (
    <>
      {loading && <LoadingFS />}
      <div className="notification-container">
        {notifications.map((item, index) => {
          return (
            <NotificationCard
              id={item.id}
              key={index}
              title={item.title}
              message={item.body}
              isValid={item.is_valid}
              type={item.type}
              createdAt={item.created_at}
            />
          );
        })}
        {totalItems !== notifications.length && (
          <Button
            size="small"
            className="info load-more"
            loading={loading}
            type="submit"
            onClick={handleLoadMore}
          >
            Load More
          </Button>
        )}
      </div>
    </>
  );
};

export default Notifications;
