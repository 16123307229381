import { createSlice } from '@reduxjs/toolkit';
import { AddAirportAction, DeleteAirportAction, GetAirportsByCityAction, UpdateAirportAction } from '../actions/AirportAction';
export const airportReducer = createSlice({
    name: "Airport Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        airports: [],
        airportInfo: [],
    },
    reducers: {
        airportUnmount: (state) => {
            state.airportInfo = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAirportsByCityAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetAirportsByCityAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                try {
                    if (payload.data.data === undefined) {
                        state.airports = payload.data;
                    } else {
                        state.airports = payload.data.data;
                    }
                    state.totalItems = payload.data.total;
                    state.currentPage = payload.data.current_page;
                } catch (err) {
                    // console.log(err)
                }
            })
            .addCase(GetAirportsByCityAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddAirportAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddAirportAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddAirportAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateAirportAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateAirportAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateAirportAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteAirportAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteAirportAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteAirportAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

    }
});
export default airportReducer.reducer;
export const { airportUnmount } = airportReducer.actions;
