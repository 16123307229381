import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../constant";
import { DeleteOfferAPI, GetOffersAPI, NewOffersAPI } from "../Api";

export const GetOffersAction = createAsyncThunk("admin/offers/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetOffersAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddOfferAction = createAsyncThunk("admin/offers/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(NewOffersAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteOfferAction = createAsyncThunk("admin/offers/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteOfferAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
