import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../constant";
import { AddCountryAPI, DeleteCountryAPI, GetAllCitiesAPI, GetCitiesByCountryAPI, GetCountriesAPI, SearchCountriesAPI, UpdateCountryAPI } from "../Api";

export const GetCountriesAction = createAsyncThunk("cms/countries/get", async (info, { rejectWithValue }) => {
    try {
        let route;
        if (info.name) {
            route = SearchCountriesAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate + "&name=" + info.name;
        }
        else {
            route = GetCountriesAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate;
        }
        let { data } = await axiosInstance.get(route);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddCountryAction = createAsyncThunk("cms/countries/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddCountryAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateCountryAction = createAsyncThunk("cms/countries/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(UpdateCountryAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteCountryAction = createAsyncThunk("cms/countries/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteCountryAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetCitiesByCountryAction = createAsyncThunk("cms/cities/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetCitiesByCountryAPI + info.id + "?page=" + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetAllCitiesAction = createAsyncThunk("cms/countries/all/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetAllCitiesAPI);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});