import React, { useState } from "react";
import UsersDT from "./UsersDT";
import { Dialog } from "primereact/dialog";
import LoadingFS from "../../components/loading/LoadingFS";
import BlockUser from "./actions/BlockUser";
import { useSelector } from "react-redux";

const Users = ({ toast }) => {
  const [blockModal, setBlockModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);
  const { loading } = useSelector((state) => state.users);

  return (
    <div className="sticky-container">
      {loading && <LoadingFS />}
      <UsersDT
        toast={toast}
        blockModal={(visible, rowData, basicRows) => {
          setBlockModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />

      <Dialog
        closeOnEscape
        header="Block a user"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={blockModal}
        onHide={() => setBlockModal(false)}
      >
        <BlockUser
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          blockModal={(e) => setBlockModal(e)}
        />
      </Dialog>
    </div>
  );
};

export default Users;
