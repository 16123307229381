import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { Dropdown } from "primereact/dropdown";
import {
  GetHotelAction,
  UpdateHotelAction,
} from "../../../redux/actions/hotel/HotelAction";
import { Rating } from "primereact/rating";
import { unwrapResult } from "@reduxjs/toolkit";

const UpdateHotel = ({ rowData, basicRows, updateModal, toast, allCities }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.hotels);
  const [hotelName, setHotelName] = useState(undefined);
  const [rate, setRate] = useState(rowData.rate);
  const [selectedCity, setSelectedCity] = useState(rowData.city);
  const [hotelUrl, setHotelUrl] = useState(undefined);

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("name", hotelName ?? rowData.name);
    obj.append("rate", rate);
    obj.append("hotel_site", hotelUrl ?? rowData.hotel_site);
    obj.append("city_id", selectedCity.id);
    let data = { id: rowData.id, obj: obj };

    dispatch(UpdateHotelAction(data))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        updateModal(false);
        dispatch(GetHotelAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  const groupedItemTemplate = (option) => {
    return (
      <div className="country">
        <h3>{option.name}</h3>
      </div>
    );
  };

  return (
    <div className="container">
      <form className="add-hotel" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.name}
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Hotel Name"
            onChange={(e) => setHotelName(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.hotel_site}
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Hotel URL"
            onChange={(e) => setHotelUrl(e.target.value)}
          />
        </div>

        <div className="input-wrapper">
          <h4>Select a city</h4>
          <Dropdown
            appendTo={document.querySelector(".container")}
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.value)}
            options={allCities}
            optionLabel="name"
            optionGroupLabel="name"
            optionGroupChildren="cities"
            valueTemplate={selectedCity.name}
            optionGroupTemplate={groupedItemTemplate}
            placeholder="Select a City"
            filter
          />
        </div>
        <div className="input-wrapper">
          <h4>Select a rate</h4>
          <Rating
            value={rate}
            onChange={(e) => setRate(e.value)}
            cancel={false}
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => updateModal(false)}
            type="button"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateHotel;
