import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../../constant";
import { AddTransferAPI, DeleteTransferAPI, GetTransfersAPI, GetTransfersInRangeAPI, UpdateTransferAPI } from "../../Api";

export const GetTransferAction = createAsyncThunk("cms/Transfer/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetTransfersAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetTransfersInRangeAction = createAsyncThunk("cms/ranged/Transfer/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetTransfersInRangeAPI + info.from + "/" + info.to + "?page="
            + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddTransferAction = createAsyncThunk("cms/Transfer/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddTransferAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateTransferAction = createAsyncThunk("cms/Transfer/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(UpdateTransferAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteTransferAction = createAsyncThunk("cms/Transfer/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteTransferAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
