import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/pages/utils/datatable.scss";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { dtTranstion, paginatorSize } from "../../redux/constant";
import { GetCitiesByCountryAction } from "../../redux/actions/CountryAction";
import { Link, useLocation } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError } from "../../utils/ToastService";

const CityDT = ({
  createModal,
  deleteModal,
  updateModal,
  countryId,
  toast,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const { totalItems, cities } = useSelector((state) => state.countries);
  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };
  useEffect(() => {
    let info = {
      id: countryId,
      page: currentPage,
      size: paginatorSize,
      isPaginate: 1,
    };
    dispatch(GetCitiesByCountryAction(info))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  }, [dispatch, currentPage, countryId, toast]);

  const location = useLocation();
  const hash = location.hash.split("#")[1];
  const decodedName = decodeURIComponent(hash);

  const header = (
    <div className="header">
      <Button
        size="small"
        icon="pi pi-arrow-left"
        onClick={() => {
          window.history.back();
        }}
        rounded
        text
        raised
        aria-label="Favorite"
      />
      <span className="title">{decodedName} City Guide</span>
      <Button
        size="small"
        icon="pi pi-plus"
        onClick={() => {
          createModal(true, undefined, basicRows);
        }}
        rounded
        text
        raised
        aria-label="Favorite"
      />
    </div>
  );
  const acitonBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          size="small"
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          aria-label="Submit"
          onClick={() => {
            deleteModal(true, rowData, basicRows);
          }}
        />
        <Button
          size="small"
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-primary"
          aria-label="Submit"
          onClick={() => {
            updateModal(true, rowData, basicRows);
          }}
        />
        <Link
          to={{
            pathname: `airports/${rowData.id}`,
            hash: `${rowData.name}`,
          }}
        >
          <Button
            size="small"
            icon="bi bi-airplane"
            className="p-button-rounded p-button-text p-button-primary"
            aria-label="Submit"
            onClick={() => {
              updateModal(true, rowData, basicRows);
            }}
            tooltipOptions={{ position: "top" }}
            tooltip="Airports"
          />
        </Link>
      </>
    );
  };
  return (
    <div className={`datatable ${dtTranstion}`}>
      <DataTable stripedRows header={header} value={cities}>
        <Column sortable field="id" header="ID"></Column>
        <Column field="name" header="Name En"></Column>
        <Column field="name_ar" header="Name Ar"></Column>
        <Column
          align={"center"}
          body={acitonBodyTemplate}
          header="Actions"
        ></Column>
      </DataTable>
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={totalItems}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </div>
  );
};

export default CityDT;
