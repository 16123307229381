import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/pages/utils/datatable.scss";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { dtTranstion, paginatorSize } from "../../redux/constant";
import {
  DoneTransferRequestsAction,
  GetTransferRequestsAction,
  SuccessTransferRequestAction,
} from "../../redux/actions/transfer/TransferRequestAction";
import { Dropdown } from "primereact/dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showError, showSuccess } from "../../utils/ToastService";
import { Tag } from "primereact/tag";
import { confirmDialog } from "primereact/confirmdialog";
import { formatNumberWithCommas } from "../../utils/Formatters";
import { getSeverity } from "../../utils/Severity";
import { unwrapResult } from "@reduxjs/toolkit";
import { encodeBase64 } from "../../utils/EncodeDecode";

const TransfersRequestsDT = ({ toast, abortModal, bookModal }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const { totalItems, transferRequests } = useSelector(
    (state) => state.transferRequests
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(
    location.hash.split("#")[1]
  );

  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  useEffect(() => {
    const getTransferRequests = (status) => {
      dispatch(
        GetTransferRequestsAction({
          page: currentPage,
          size: paginatorSize,
          isPaginate: 1,
          status: status,
        })
      )
        .then(unwrapResult)
        .catch((error) => {
          showError(error, toast);
        });
    };

    switch (selectedOption) {
      case "0":
        getTransferRequests(0);
        break;
      case "1":
        getTransferRequests(1);
        break;
      case "2":
        getTransferRequests(2);
        break;
      case "10":
        getTransferRequests(10);
        break;
      default:
        break;
    }

    if (selectedOption) {
      navigate(`${location.pathname}#${selectedOption}`);
    } else {
      navigate(location.pathname);
    }
  }, [
    selectedOption,
    dispatch,
    currentPage,
    location.pathname,
    navigate,
    toast,
  ]);

  const options = [
    { label: "Pending", value: "0" },
    { label: "Confirm", value: "1" },
    { label: "Done", value: "2" },
    { label: "All", value: "10" },
  ];
  const handleSelect = (e) => {
    setSelectedOption(e.value);
  };
  const header = (
    <div className="header">
      <span className="title">Transfer Requests</span>

      <Dropdown
        value={selectedOption}
        options={options}
        onChange={handleSelect}
        placeholder="Select an option"
      />
    </div>
  );

  const doneTransferHandler = (rowData) => {
    dispatch(DoneTransferRequestsAction(rowData.id))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        dispatch(
          GetTransferRequestsAction({
            page: currentPage,
            size: paginatorSize,
            isPaginate: 1,
            status: selectedOption,
          })
        );
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  const successTransferHandler = (rowData) => {
    dispatch(SuccessTransferRequestAction(rowData.id))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        dispatch(
          GetTransferRequestsAction({
            page: currentPage,
            size: paginatorSize,
            isPaginate: 1,
            status: selectedOption,
          })
        );
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  const confirmPopup = (rowData) => {
    confirmDialog({
      contentClassName: "popup-content",
      message: "Do you want to confirm this request?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => doneTransferHandler(rowData),
    });
  };
  const successPopup = (rowData) => {
    confirmDialog({
      contentClassName: "popup-content",
      message: "Should You mark this request as succeeded?",
      header: "Confirmation",
      icon: "pi pi pi-check",
      accept: () => successTransferHandler(rowData),
    });
  };

  const acitonBodyTemplate = (rowData) => {
    const encodedData = encodeBase64(JSON.stringify(rowData));
    return (
      <>
        <Link
          to={{
            pathname: `${rowData.id}`,
            search: `data=${encodedData}`,
          }}
        >
          <Button
            size="small"
            icon="pi pi-eye"
            className="p-button-rounded p-button-text p-button-success"
            aria-label="Submit"
          />
        </Link>
        {parseInt(selectedOption) === 1 && (
          <Button
            size="small"
            icon="pi pi-check"
            className="p-button-rounded p-button-text p-button-success"
            aria-label="Submit"
            onClick={() => confirmPopup(rowData)}
          />
        )}
        {parseInt(selectedOption) === 2 && (
          <Button
            tooltipOptions={{ position: "top" }}
            tooltip="Success"
            size="small"
            icon="pi pi-check-circle"
            className="p-button-rounded p-button-text p-button-secondary"
            aria-label="Submit"
            onClick={() => successPopup(rowData)}
          />
        )}
        {rowData?.admin ? (
          <Button
            tooltipOptions={{ position: "top" }}
            tooltip="Abort"
            size="small"
            icon="pi pi-times"
            className="p-button-rounded p-button-text p-button-danger"
            aria-label="Submit"
            onClick={() => abortModal(true, rowData, basicRows)}
          />
        ) : (
          <Button
            tooltipOptions={{ position: "top" }}
            tooltip="Book"
            size="small"
            icon="pi pi-bookmark"
            className="p-button-rounded p-button-text p-button-danger"
            aria-label="Submit"
            onClick={() => bookModal(true, rowData, basicRows)}
          />
        )}
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={options[rowData.status].label}
        severity={getSeverity(rowData.status)}
      ></Tag>
    );
  };

  return (
    <div className={`datatable ${dtTranstion}`}>
      <DataTable stripedRows header={header} value={transferRequests}>
        <Column
          header="Price"
          body={(e) => {
            return formatNumberWithCommas(e.price);
          }}
        ></Column>
        <Column
          header="Reserved By"
          body={(rowData) => {
            return rowData?.admin
              ? rowData?.admin?.first_name + " " + rowData?.admin?.last_name
              : "..";
          }}
        ></Column>
        <Column
          header="User"
          body={(e) => {
            return e.user?.first_name + " " + e.user?.last_name;
          }}
        ></Column>
        <Column
          header="Phone Number"
          body={(e) => {
            return e.user?.phone_number;
          }}
        ></Column>
        <Column header="Created At" field="created_at"></Column>
        <Column header="Updated At" field="updated_at"></Column>
        <Column header="Status" body={statusBodyTemplate}></Column>
        <Column
          align={"center"}
          body={acitonBodyTemplate}
          header="Actions"
        ></Column>
      </DataTable>
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={totalItems}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </div>
  );
};

export default TransfersRequestsDT;
