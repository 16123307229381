import React, { useState } from "react";
import TransfersDT from "./TransfersDT";
import AddTransfer from "./actions/AddTransfer";
import { Dialog } from "primereact/dialog";
import UpdateTransfer from "./actions/UpdateTransfer";
import DeleteTransfer from "./actions/DeleteTransfer";
import LoadingFS from "../../components/loading/LoadingFS";
import { useDispatch, useSelector } from "react-redux";
import { GetAllCitiesAction } from "../../redux/actions/CountryAction";
import { GetCarCategoriesAction } from "../../redux/actions/car/CarGategoryAction";
import { showError } from "../../utils/ToastService";
import { unwrapResult } from "@reduxjs/toolkit";

const Transfers = ({ toast }) => {
  const dispatch = useDispatch();
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);
  const { allCities } = useSelector((state) => state.countries);
  const countriesLoading = useSelector((state) => state.countries.loading);
  const { loading } = useSelector((state) => state.transfers);

  const getDataHandler = () => {
    let info = { isPaginate: 0 };
    dispatch(GetAllCitiesAction())
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
    dispatch(GetCarCategoriesAction(info))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <div className="sticky-container">
      {(loading || countriesLoading) && <LoadingFS />}
      <TransfersDT
        toast={toast}
        createModal={(visible, rowData, basicRows) => {
          setCreateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        deleteModal={(visible, rowData, basicRows) => {
          setDeleteModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        updateModal={(visible, rowData, basicRows) => {
          setUpdateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />

      <Dialog
        header="Add Transfer"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={createModal}
        onHide={() => setCreateModal(false)}
        onShow={getDataHandler}
      >
        <AddTransfer
          allCities={allCities}
          basicRows={basicRows}
          createModal={(e) => setCreateModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Update Transfer"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
        onShow={getDataHandler}
      >
        <UpdateTransfer
          allCities={allCities}
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          updateModal={(e) => setUpdateModal(e)}
        />
      </Dialog>

      <Dialog
        closeOnEscape
        header="Delete Transfer"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
      >
        <DeleteTransfer
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          deleteModal={(e) => setDeleteModal(e)}
        />
      </Dialog>
    </div>
  );
};

export default Transfers;
