import React from "react";
import "./Navbar.scss";
import AppBreadcrumb from "../breadcrumb/AppBreadcrumb";
import { LogOutAction } from "../../redux/actions/AuthAction";
import { showError, showSuccess } from "../../utils/ToastService";
import { useDispatch, useSelector } from "react-redux";
import LoadingFS from "../loading/LoadingFS";
import { Link } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

const Navbar = ({ toast }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const logoutHandler = () => {
    dispatch(LogOutAction())
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        window.location.href = "/";
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  return (
    <>
      {loading && <LoadingFS />}
      <nav className="navbar animate__animated animate__bounceInDown">
        <div className="username">
          <Link to="/welcome" className="pi pi-user"></Link>
          <Link to="/notifications" className="pi pi-bell"></Link>
          <Link to="/push-notification" className="pi pi-send"></Link>
        </div>
        <AppBreadcrumb />
        <div className="nav-links">
          <span onClick={logoutHandler}>Logout</span>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
