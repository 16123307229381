import React, { useEffect, useState } from "react";
import Card from "../../components/card/Card";
import "../../assets/pages/car-categories/CarCategories.scss";
import { Paginator } from "primereact/paginator";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import AddCategory from "./actions/AddCategory";
import DeleteCategory from "./actions/DeleteCategory";
import UpdateCategory from "./actions/UpdateCategory";
import { GetCarCategoriesAction } from "../../redux/actions/car/CarGategoryAction";
import LoadingFS from "../../components/loading/LoadingFS";
import Header from "../../components/header/Header";
import { paginatorSize } from "../../redux/constant";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError } from "../../utils/ToastService";

const CarCategories = ({ toast }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const { totalItems, carCategories, loading } = useSelector(
    (state) => state.carCategory
  );
  const [rowData, setRowData] = useState(false);

  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);

  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  useEffect(() => {
    let info = { page: currentPage, size: paginatorSize, isPaginate: 1 };
    dispatch(GetCarCategoriesAction(info))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  }, [dispatch, currentPage, toast]);
  
  return (
    <div className="sticky-container">
      {loading && <LoadingFS />}
      <div className="cards">
        <Header
          header={"Car Category"}
          createModal={(visible) => {
            setCreateModal(visible);
          }}
        />
        {carCategories?.map((item, index) => {
          return (
            <Card
              key={index}
              rowData={item}
              deleteModal={(visible, rowData, basicRows) => {
                // console.log(visible, rowData, basicRows);
                setDeleteModal(visible);
                setRowData(rowData);
                setBasicRows(basicRows);
              }}
              updateModal={(visible, rowData, basicRows) => {
                setUpdateModal(visible);
                setRowData(rowData);
                setBasicRows(basicRows);
              }}
            />
          );
        })}
        <Paginator
          first={basicFirst}
          rows={basicRows}
          totalRecords={totalItems}
          onPageChange={onBasicPageChange}
        ></Paginator>
      </div>

      <Dialog
        header="Add Car Category"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={createModal}
        onHide={() => setCreateModal(false)}
      >
        <AddCategory
          basicRows={basicRows}
          createModal={(e) => setCreateModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Delete Car Category"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
      >
        <DeleteCategory
          basicRows={basicRows}
          deleteModal={(e) => setDeleteModal(e)}
          toast={toast}
          rowData={rowData}
        />
      </Dialog>

      <Dialog
        header="Update Car Category"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
      >
        <UpdateCategory
          rowData={rowData}
          basicRows={basicRows}
          updateModal={(e) => setUpdateModal(e)}
          toast={toast}
        />
      </Dialog>
    </div>
  );
};

export default CarCategories;
