import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { useEffect, useMemo } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";

export const SearchField = (props) => {
  const provider = useMemo(
    () =>
      new OpenStreetMapProvider({
        params: {
          access_token: props.apiKey,
        },
      }),
    [props.apiKey]
  );

  const searchControl = useMemo(
    () =>
      new GeoSearchControl({
        provider: provider,
        autoComplete: true,
        autoCompleteDelay: 250,
        style: "bar",
        showMarker: false,
        showPopup: false,
        autoClose: true,
        searchLabel: "Enter a location",
        marker: {
          icon: new L.Icon.Default(),
          draggable: false,
        },
      }),
    [provider]
  );

  const map = useMap();
  useEffect(() => {
    map.addControl(searchControl);
    map.on("geosearch/showlocation", (e) =>
      props.setPosition(e.location.raw.lat, e.location.raw.lon)
    );

    return () => map.removeControl(searchControl);
  }, [map, props, searchControl]);

  return null;
};
