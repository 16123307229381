import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import { Dropdown } from "primereact/dropdown";
import {
  GetAirportsByCityAction,
  UpdateAirportAction,
} from "../../../redux/actions/AirportAction";
import { unwrapResult } from "@reduxjs/toolkit";

const UpdateAirport = ({ rowData, basicRows, updateModal, toast, cityId }) => {
  const dispatch = useDispatch();
  const { loading, currentPage, cities } = useSelector(
    (state) => state.countries
  );
  const [nameEn, setNameEn] = useState(undefined);
  const [nameAr, setNameAr] = useState(undefined);
  const [selectedCity, setSelectedCity] = useState(rowData.city);

  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    obj.append("name", nameEn ?? rowData.name);
    obj.append("name_ar", nameAr ?? rowData.name_ar);
    obj.append("city_id", selectedCity.id);
    let info = {
      id: cityId,
      size: basicRows,
      page: currentPage,
      isPaginate: 1,
    };
    let data = { id: rowData.id, obj: obj };
    dispatch(UpdateAirportAction(data))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        updateModal(false);
        dispatch(GetAirportsByCityAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="add-group" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.name}
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Airport Name En"
            onChange={(e) => setNameEn(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <InputText
            defaultValue={rowData.name_ar}
            required
            unstyled
            className="custom-input"
            type="text"
            placeholder="Airport Name Ar"
            onChange={(e) => setNameAr(e.target.value)}
          />
        </div>

        <Dropdown
          valueTemplate={selectedCity.name}
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.value)}
          options={cities}
          optionLabel="name"
          placeholder="Select a country"
          filter
        />

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            type="button"
            onClick={() => updateModal(false)}
          />
          <Button
            size="small"
            label="Update"
            icon="pi pi-check"
            className="info"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default UpdateAirport;
