import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../constant";
import { AddAdminAPI, ChangeAdminPasswordAPI, DeleteAdminAPI, GetAdminsAPI, UpdateAdminAPI } from "../Api";


export const GetAdminsAction = createAsyncThunk("admins/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetAdminsAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddAdminAction = createAsyncThunk("admins/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddAdminAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateAdminAction = createAsyncThunk("admins/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(UpdateAdminAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteAdminAction = createAsyncThunk("admins/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteAdminAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const ChangeAdminPasswordAction = createAsyncThunk("admins/password/change", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(ChangeAdminPasswordAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});