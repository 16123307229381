import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../constant";
import { AbortFlightRequestAPI, AddFlightSuggestAPI, BookFlightRequestAPI, DoneFlightRequestAPI, GetFlightRequestsAPI, GetFlightRequestsLogAPI, SuccessFlightRequestAPI } from "../Api";

export const GetFlightRequestsAction = createAsyncThunk("cms/flightRequest/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetFlightRequestsAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
            + "&status=" + info.status);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DoneFlightRequestsAction = createAsyncThunk("cms/flightRequest/done", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(DoneFlightRequestAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddFlightSuggestAction = createAsyncThunk("cms/flight/suggest/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddFlightSuggestAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const SuccessFlightRequestAction = createAsyncThunk("cms/flight/success", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(SuccessFlightRequestAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AbortFlightRequestAction = createAsyncThunk("cms/flight/abort", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AbortFlightRequestAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const BookFlightRequestAction = createAsyncThunk("cms/flight/book", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(BookFlightRequestAPI + info.id);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetFlightRequestsLogAction = createAsyncThunk("cms/flights/log", async (info, { rejectWithValue }) => {
    try {
        let route;
        if (info.phone) {
            route = GetFlightRequestsLogAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
                + "&status=" + info.status + "&phone_number=" + info.phone
        }
        else {
            route = GetFlightRequestsLogAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate
                + "&status=" + info.status
        }
        let { data } = await axiosInstance.get(route);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
