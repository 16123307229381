import React, { useState } from "react";
import LoadingFS from "../../components/loading/LoadingFS";
import { useSelector } from "react-redux";
import TransfersRequestsDT from "./TransfersRequestsDT";
import { Dialog } from "primereact/dialog";
import AbortTransferRequest from "./actions/AbortTransferRequest";
import BookTransferRequest from "./actions/BookTransferRequest";

const TransfersRequests = ({ toast }) => {
  const { loading } = useSelector((state) => state.transferRequests);
  const [abortModal, setAbortModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);
  const [bookModal, setBookModal] = useState(false);

  return (
    <div className="sticky-container">
      {loading && <LoadingFS />}
      {/* <Header
        header={"Transfers Requests Log"}
        path={"/transfer-requests/log"}
      /> */}

      <TransfersRequestsDT
        toast={toast}
        abortModal={(visible, rowData, basicRows) => {
          setAbortModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        bookModal={(visible, rowData, basicRows) => {
          setBookModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />

      <Dialog
        header="Abort Transfer Request"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={abortModal}
        onHide={() => setAbortModal(false)}
      >
        <AbortTransferRequest
          rowData={rowData}
          basicRows={basicRows}
          abortModal={(e) => setAbortModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Book Transfer Request"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={bookModal}
        onHide={() => setBookModal(false)}
      >
        <BookTransferRequest
          rowData={rowData}
          basicRows={basicRows}
          bookModal={(e) => setBookModal(e)}
          toast={toast}
        />
      </Dialog>
    </div>
  );
};

export default TransfersRequests;
