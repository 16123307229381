import React, { useState } from "react";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { showError, showSuccess, showWarn } from "../../../utils/ToastService";
import { AddFlightSuggestAction } from "../../../redux/actions/FlightRequestAction";
import { InputSwitch } from "primereact/inputswitch";
import { useNavigate } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import "../../../assets/pages/transfer-request/AddTransferSuggest.scss";
import { unwrapResult } from "@reduxjs/toolkit";

const AddFlightSuggest = ({ id, toast, type, isValid }) => {
  const [formData, setFormData] = useState([]);
  const [formCount, setFormCount] = useState(1);
  const { loading } = useSelector((state) => state.flightRequests);
  const [price, setPrice] = useState();
  const [checked, setChecked] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddForm = () => {
    setFormCount(formCount + 1);
    const newForm = {
      id: formCount + 1,
      data: "",
    };
    setFormData([...formData, newForm]);
  };
  const classes = [
    { label: "Economic", value: "0" },
    { label: "First Class", value: "1" },
    { label: "Business", value: "2" },
  ];

  const handleRemoveForm = (id) => {
    const updatedFormData = formData.filter((form) => form.id !== id);
    setFormData(updatedFormData);
  };

  const handleInputChange = async (e, formId, inputName) => {
    const { value } = e.target || e;
    // console.log(formData);
    const updatedFormData = formData.map((form) => {
      if (form.id === formId) {
        return {
          ...form,
          data: {
            ...form.data,
            [inputName]: value,
          },
        };
      }
      return form;
    });

    setFormData(updatedFormData);
  };
  const handleSubmit = () => {
    const formattedFormData = formData.map((form) => {
      const newData = { ...form.data };

      if (newData["depart_date"]) {
        const selectedDate = new Date(newData["depart_date"]);
        const timezoneOffset = selectedDate.getTimezoneOffset() * 60000;
        const dateInLocalTimezone = new Date(selectedDate - timezoneOffset);
        newData["depart_date"] = dateInLocalTimezone
          .toISOString()
          .split("T")[0];
      }

      if (newData["return_date"]) {
        const selectedDate = new Date(newData["return_date"]);
        const timezoneOffset = selectedDate.getTimezoneOffset() * 60000;
        const dateInLocalTimezone = new Date(selectedDate - timezoneOffset);
        newData["return_date"] = dateInLocalTimezone
          .toISOString()
          .split("T")[0];
      }

      return {
        ...form,
        data: newData,
      };
    });

    const isAnyFieldEmpty = formattedFormData.some((form) => {
      return (
        !form.data["depart_date"] ||
        (!form.data["return_date"] && type === 1) ||
        !form.data["class"] ||
        !form.data["price"]
      );
    });

    if (isAnyFieldEmpty) {
      showWarn("Please fill in all fields before submitting.", toast);
      return;
    }

    const newFormData = new FormData();

    formattedFormData.forEach((form, i) => {
      newFormData.append(
        `flight_suggests[${i}][depart_date]`,
        form.data["depart_date"] || ""
      );
      if (form.data["return_date"] !== undefined) {
        newFormData.append(
          `flight_suggests[${i}][return_date]`,
          form.data["return_date"] || ""
        );
      }

      newFormData.append(
        `flight_suggests[${i}][class]`,
        form.data["class"] || ""
      );
      newFormData.append(
        `flight_suggests[${i}][price]`,
        form.data["price"] || ""
      );
    });

    newFormData.append("is_valid", checked ? "0" : "1");
    if (!checked) newFormData.append("price", price);

    Array.from(newFormData.entries()).forEach(([key, value]) => {
      // console.log(`${key}: ${value}`);
    });

    let info = {
      id: id,
      obj: newFormData,
    };

    dispatch(AddFlightSuggestAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        navigate(-1);
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  // console.log(formData["price"], price);

  return (
    <div>
      <div>
        {formData.map((form, index) => (
          <div key={form.id} className="add-transfer-suggest">
            <form className="suggest-form">
              <div className="header">
                <h4 className="suggest-number">Suggest number: {index + 1}</h4>
                <div className="cancel">
                  <Button
                    size="small"
                    icon="bi bi-x-lg"
                    className="p-button-rounded p-button-text p-button-danger"
                    type="button"
                    onClick={() => handleRemoveForm(form.id)}
                  />
                </div>
              </div>
              <div className="input-wrapper">
                <Calendar
                  required
                  name="depart_date"
                  placeholder="Depart Date"
                  value={form.data["depart_date"] || ""}
                  onChange={(e) => handleInputChange(e, form.id, "depart_date")}
                  dateFormat="yy-mm-dd"
                  showIcon
                />
              </div>
              {type === 1 && (
                <div className="input-wrapper">
                  <Calendar
                    required
                    name="return_date"
                    placeholder="Return Date"
                    value={form.data["return_date"] || ""}
                    onChange={(e) =>
                      handleInputChange(e, form.id, "return_date")
                    }
                    dateFormat="yy-mm-dd"
                    showIcon
                  />
                </div>
              )}

              <div className="input-wrapper">
                {/* <InputText
                  required
                  type="number"
                  placeholder="Flight Price"
                  className="custom-input"
                  onChange={(e) =>
                    handleInputChange(e.target, form.id, "price")
                  }
                /> */}
                <InputNumber
                  className="custom-number-input"
                  required
                  value={formData["price"]}
                  placeholder="Flight Price"
                  onChange={(e) => handleInputChange(e, form.id, "price")}
                />
              </div>

              <div className="input-wrapper">
                <Dropdown
                  name="class"
                  appendTo={"self"}
                  required
                  value={form.data["class"] || ""}
                  onChange={(e) => handleInputChange(e, form.id, "class")}
                  options={classes}
                  optionLabel="label"
                  placeholder="Class"
                />
              </div>
            </form>
          </div>
        ))}
        {isValid === null && (
          <div className="check-btn">
            <h4>Valid</h4>
            <InputSwitch
              checked={checked}
              onChange={(e) => setChecked(e.value)}
            />
            <h4>Invalid</h4>
          </div>
        )}
        {isValid === null && !checked && (
          <div className="input-wrapper">
            {/* <InputText
              required
              unstyled
              className="custom-input"
              type="number"
              placeholder="Flight Price"
              onChange={(e) => setPrice(e.target.value)}
            /> */}
            <InputNumber
              className="custom-number-input"
              required
              value={price}
              placeholder="Flight Price"
              onChange={(e) => setPrice(e.value)}
            />
          </div>
        )}
        <div className="actions">
          <Button
            size="small"
            className="warning"
            onClick={handleAddForm}
            label={"Add Suggest"}
            type="button"
          />
        </div>
        {/* {formData.length !== 0 && ( */}
        <div className="done-btn">
          <Button
            loading={loading}
            label="Submit Suggestions"
            type="button"
            size="small"
            className="info"
            aria-label="Submit"
            onClick={handleSubmit}
          />
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default AddFlightSuggest;
