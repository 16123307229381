import { createSlice } from '@reduxjs/toolkit';
import { LogInAction, LogOutAction } from '../actions/AuthAction';
export const authReducer = createSlice({
    name: "Auth Reducer",
    initialState: {
        loading: false,
        fcmWarning: false
    },
    reducers: {
        fcmToggle: (state, action) => {
            state.fcmWarning = action.payload; 
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(LogInAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(LogInAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                localStorage.setItem("adminToken", payload.data.access_token)
                state.loading = false;
            })
            .addCase(LogInAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(LogOutAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(LogOutAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                localStorage.removeItem("adminToken")
                localStorage.removeItem("fcmToken")
                state.loading = false;
            })
            .addCase(LogOutAction.rejected, (state) => {
                state.loading = false;
            })
    }
});
export default authReducer.reducer;