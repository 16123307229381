import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/pages/utils/datatable.scss";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { dtTranstion, paginatorSize } from "../../redux/constant";
import { Dropdown } from "primereact/dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { showError, showSuccess } from "../../utils/ToastService";
import { Tag } from "primereact/tag";
import {
  AddPriceInsuranceRequestAction,
  DoneInsuranceRequestsAction,
  GetInsuranceRequestsAction,
  SuccessInsuranceRequestAction,
} from "../../redux/actions/insurance/InsuranceRequestsActions";
import { confirmDialog } from "primereact/confirmdialog";
import { FileUpload } from "primereact/fileupload";
import { formatNumberWithCommas } from "../../utils/Formatters";
import { InputNumber } from "primereact/inputnumber";
import { getSeverity } from "../../utils/Severity";
import { unwrapResult } from "@reduxjs/toolkit";
import { encodeBase64 } from "../../utils/EncodeDecode";

const TravelInsuranceRequestsDT = ({ toast, abortModal, bookModal }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const { totalItems, insuranceRequests } = useSelector(
    (state) => state.insurancesRequests
  );
  const imageDocRef = useRef(null);
  const priceRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(
    location.hash.split("#")[1]
  );

  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  useEffect(() => {
    const getVisaRequests = (status) => {
      dispatch(
        GetInsuranceRequestsAction({
          page: currentPage,
          size: paginatorSize,
          isPaginate: 1,
          status: status,
        })
      )
        .then(unwrapResult)
        .catch((error) => {
          showError(error, toast);
        });
      // .then(unwrapResult)
      // .catch((err) => console.log(err));
    };
    switch (selectedOption) {
      case "0":
        getVisaRequests(0);
        break;
      case "1":
        getVisaRequests(1);
        break;
      case "2":
        getVisaRequests(2);
        break;
      case "10":
        getVisaRequests(10);
        break;
      default:
        break;
    }
    if (selectedOption) {
      navigate(`${location.pathname}#${selectedOption}`);
    } else {
      navigate(location.pathname);
    }
  }, [
    selectedOption,
    dispatch,
    currentPage,
    location.pathname,
    toast,
    navigate,
  ]);

  const options = [
    { label: "Pending", value: "0" },
    { label: "Confirm", value: "1" },
    { label: "Done", value: "2" },
    { label: "All", value: "10" },
  ];

  const handleSelect = (e) => {
    setSelectedOption(e.value);
  };
  const header = (
    <div className="header">
      <span className="title">Travel Insurance Requests</span>

      <Dropdown
        value={selectedOption}
        options={options}
        onChange={handleSelect}
        placeholder="Select an option"
      />
    </div>
  );

  const successInsuranceHandler = (rowData) => {
    dispatch(SuccessInsuranceRequestAction(rowData.id))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        dispatch(
          GetInsuranceRequestsAction({
            page: currentPage,
            size: paginatorSize,
            isPaginate: 1,
            status: selectedOption,
          })
        );
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  const doneInsuranceHandler = (rowData) => {
    let formData = new FormData();
    for (let i = 0; i < imageDocRef.current.length; i++) {
      formData.append(`ticket_images[${i}]`, imageDocRef.current[i]);
    }
    let info = {
      id: rowData.id,
      obj: formData,
    };
    dispatch(DoneInsuranceRequestsAction(info))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        dispatch(
          GetInsuranceRequestsAction({
            page: currentPage,
            size: paginatorSize,
            isPaginate: 1,
            status: selectedOption,
          })
        );
      })
      .catch((error) => {
        showError(error, toast);
      });
  };

  // console.log(priceRef.current);
  const pricingPopup = (rowData) => {
    confirmDialog({
      contentClassName: "popup-content",
      dismissableMask: true,
      message: (
        <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
          <h3>Please enter a price for this travel insurance</h3>
          <InputNumber
            className="custom-number-input"
            required
            placeholder="Insurance Pricing"
            onChange={(e) => (priceRef.current = e.value)}
          />
          <h3>Do you want to confirm this action?</h3>
        </div>
      ),
      header: "Confirmation",
      accept: () => {
        let formData = new FormData();
        formData.append("price", priceRef.current);
        let info = {
          id: rowData.id,
          obj: formData,
        };
        dispatch(AddPriceInsuranceRequestAction(info))
          .then(unwrapResult)
          .then((payload) => {
            showSuccess(payload.message, toast);
            dispatch(
              GetInsuranceRequestsAction({
                page: currentPage,
                size: paginatorSize,
                isPaginate: 1,
                status: selectedOption,
              })
            );
          })
          .catch((error) => {
            showError(error, toast);
          });
      },
    });
  };

  const confirmPopup = (rowData) => {
    confirmDialog({
      contentClassName: "popup-content",
      dismissableMask: true,
      message: (
        <div>
          <h3>Please insert an insurance image</h3>
          <div className="section input-wrapper file-upload">
            <FileUpload
              multiple
              mode="advanced"
              chooseLabel="Choose an image"
              cancelLabel="Cancel"
              accept="image/*"
              onSelect={(event) => {
                imageDocRef.current = event.files;
              }}
            />
          </div>
          <h3>Do you want to confirm this request?</h3>
        </div>
      ),
      header: "Confirmation",
      accept: () => {
        imageDocRef.current !== null && doneInsuranceHandler(rowData);
      },
      onHide: () => {
        imageDocRef.current = null;
      },
    });
  };

  const successPopup = (rowData) => {
    confirmDialog({
      contentClassName: "popup-content",
      message: "Should You mark this request as succeeded?",
      header: "Confirmation",
      icon: "pi pi pi-check",
      accept: () => successInsuranceHandler(rowData),
    });
  };

  const acitonBodyTemplate = (rowData) => {
    const encodedData = encodeBase64(JSON.stringify(rowData));
    return (
      <>
        <Link
          to={{
            pathname: `${rowData.id}`,
            search: `data=${encodedData}`,
          }}
        >
          <Button
            size="small"
            icon="pi pi-eye"
            className="p-button-rounded p-button-text p-button-success"
            aria-label="Submit"
          />
        </Link>
        {rowData?.admin ? (
          <Button
            tooltipOptions={{ position: "top" }}
            tooltip="Abort"
            size="small"
            icon="pi pi-times"
            className="p-button-rounded p-button-text p-button-danger"
            aria-label="Submit"
            onClick={() => abortModal(true, rowData, basicRows)}
          />
        ) : (
          <Button
            tooltipOptions={{ position: "top" }}
            tooltip="Book"
            size="small"
            icon="pi pi-bookmark"
            className="p-button-rounded p-button-text p-button-danger"
            aria-label="Submit"
            onClick={() => bookModal(true, rowData, basicRows)}
          />
        )}
        {parseInt(selectedOption) === 0 &&
          rowData.admin &&
          rowData.price === null && (
            <Button
              tooltipOptions={{ position: "top" }}
              tooltip="Pricing"
              size="small"
              icon="pi pi-dollar"
              className="p-button-rounded p-button-text p-button-info"
              aria-label="Submit"
              onClick={() => pricingPopup(rowData)}
            />
          )}
        {parseInt(selectedOption) === 1 && rowData.admin && (
          <Button
            tooltipOptions={{ position: "top" }}
            tooltip="Done"
            size="small"
            icon="pi pi-check"
            className="p-button-rounded p-button-text p-button-info"
            aria-label="Submit"
            onClick={() => confirmPopup(rowData)}
          />
        )}
        {parseInt(selectedOption) === 2 && (
          <Button
            tooltipOptions={{ position: "top" }}
            tooltip="Success"
            size="small"
            icon="pi pi-check-circle"
            className="p-button-rounded p-button-text p-button-secondary"
            aria-label="Submit"
            onClick={() => successPopup(rowData)}
          />
        )}
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={options[rowData.status].label}
        severity={getSeverity(rowData.status)}
      ></Tag>
    );
  };

  return (
    <div className={`datatable ${dtTranstion}`}>
      <DataTable stripedRows header={header} value={insuranceRequests}>
        <Column
          body={(e) => {
            return e?.price === null ? ".." : formatNumberWithCommas(e?.price);
          }}
          header="Price"
        ></Column>
        <Column
          header="Reserved By"
          body={(rowData) => {
            return rowData?.admin
              ? rowData?.admin?.first_name + " " + rowData?.admin?.last_name
              : "..";
          }}
        ></Column>
        <Column
          body={(e) => {
            return e?.city?.name;
          }}
          header="Country"
        ></Column>
        <Column
          body={(e) => {
            return e.user?.first_name + " " + e.user?.last_name;
          }}
          header="User"
        ></Column>
        <Column
          body={(e) => {
            return e.user?.phone_number;
          }}
          header="Phone Number"
        ></Column>
        <Column header="Created At" field="created_at"></Column>
        <Column header="Updated At" field="updated_at"></Column>
        <Column header="Status" body={statusBodyTemplate}></Column>
        <Column
          align={"center"}
          body={acitonBodyTemplate}
          header="Actions"
        ></Column>
      </DataTable>
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={totalItems}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </div>
  );
};

export default TravelInsuranceRequestsDT;
