import React, { useRef } from 'react';
import AppRoutes from './routes';
import { Toast } from 'primereact/toast';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { LanguageProvider } from './context/LanguageProvider';
import './assets/style.scss'

function App() {
  const toast = useRef(null);
  return (
    <LanguageProvider>
      <div className="app">
        <AppRoutes toast={toast} />
        <ConfirmDialog />
        <Toast ref={toast} position="bottom-right" />
      </div>
    </LanguageProvider>
  );
}

export default App;