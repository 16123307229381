import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { useDispatch, useSelector } from "react-redux";
import { dtTranstion, paginatorSize } from "../../redux/constant";
import { Dropdown } from "primereact/dropdown";
import { Tag } from "primereact/tag";
import { GetVisaRequestsLogAction } from "../../redux/actions/visa/VisaRequestAction";
import { GetInsuraceRequestsLogAction } from "../../redux/actions/insurance/InsuranceRequestsActions";
import { GetTransferRequestsLogAction } from "../../redux/actions/transfer/TransferRequestAction";
import { GetHotelRequestsLogAction } from "../../redux/actions/hotel/HotelRequestAction";
import { GetFlightRequestsLogAction } from "../../redux/actions/FlightRequestAction";
import "../../assets/pages/utils/datatable.scss";
import { flightsUnmount } from "../../redux/reducers/FlightRequestReducer";
import { visasUnmount } from "../../redux/reducers/visa/VisaRequestReducer";
import { insurancesUnmount } from "../../redux/reducers/insurance/InsuranceRequestsReducer";
import { transfersUnmount } from "../../redux/reducers/transfer/TransferRequestReducer";
import { hotelsUnmount } from "../../redux/reducers/hotel/HotelRequestReducer";
import { formatNumberWithCommas } from "../../utils/Formatters";
import { getSeverity } from "../../utils/Severity";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError } from "../../utils/ToastService";

const UserRequestsDT = ({ phone, toast }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const [selectedOption, setSelectedOption] = useState("1");
  let selectorFunction;
  switch (parseInt(selectedOption)) {
    case 1:
      selectorFunction = (state) => state.visaRequest;
      break;
    case 2:
      selectorFunction = (state) => state.insurancesRequests;
      break;
    case 3:
      selectorFunction = (state) => state.transferRequests;
      break;
    case 4:
      selectorFunction = (state) => state.hotelRequests;
      break;
    case 5:
      selectorFunction = (state) => state.flightRequests;
      break;
    default:
      selectorFunction = (state) => state.visaRequest;
      break;
  }
  const { totalItems, log } = useSelector(selectorFunction);

  useEffect(() => {
    let info = {
      page: currentPage,
      size: paginatorSize,
      isPaginate: 1,
      status: 10,
      phone: phone,
    };
    switch (parseInt(selectedOption)) {
      case 1:
        dispatch(GetVisaRequestsLogAction(info))
          .then(unwrapResult)
          .catch((error) => {
            showError(error, toast);
          });
        break;
      case 2:
        dispatch(GetInsuraceRequestsLogAction(info))
          .then(unwrapResult)
          .catch((error) => {
            showError(error, toast);
          });
        break;
      case 3:
        dispatch(GetTransferRequestsLogAction(info))
          .then(unwrapResult)
          .catch((error) => {
            showError(error, toast);
          });
        break;
      case 4:
        dispatch(GetHotelRequestsLogAction(info))
          .then(unwrapResult)
          .catch((error) => {
            showError(error, toast);
          });
        break;
      case 5:
        dispatch(GetFlightRequestsLogAction(info))
          .then(unwrapResult)
          .catch((error) => {
            showError(error, toast);
          });
        break;
      default:
        break;
    }
    return () => {
      dispatch(visasUnmount());
      dispatch(insurancesUnmount());
      dispatch(transfersUnmount());
      dispatch(hotelsUnmount());
      dispatch(flightsUnmount());
    };
  }, [dispatch, selectedOption, currentPage, phone, toast]);

  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  const options = [
    { label: "Visa Requests", value: "1" },
    { label: "Insurance Requests", value: "2" },
    { label: "Transfer Requests", value: "3" },
    { label: "Hotel Requests", value: "4" },
    { label: "Flight Requests", value: "5" },
  ];
  const header = (
    <div className="header">
      <span className="title">{options[selectedOption - 1].label}</span>
      <Dropdown
        value={selectedOption}
        options={options}
        onChange={(e) => {
          setSelectedOption(e.value);
        }}
        placeholder="Select an option"
      />
    </div>
  );
  const valueLabel = ({ status }) => {
    switch (status) {
      case 0:
        return "Aborted";
      case 1:
        return "Succeeded";
      case 2:
        return "Rejected";
      default:
        break;
    }
  };
  const statusBodyTemplate = (rowData) => {
    return (
      <Tag
        value={valueLabel(rowData)}
        severity={getSeverity(rowData.status)}
      ></Tag>
    );
  };

  return (
    <div className={`datatable ${dtTranstion}`}>
      <DataTable stripedRows header={header} value={log}>
        <Column
          body={(e) => {
            return e?.price === null ? ".." : formatNumberWithCommas(e?.price);
          }}
          header="Price"
        ></Column>
        <Column
          header="Admin"
          body={(rowData) => {
            return rowData?.admin_name ? rowData?.admin_name : "..";
          }}
        ></Column>
        <Column
          body={(e) => {
            return e?.admin_phone_number;
          }}
          header="Admin Number"
        ></Column>
        <Column header="Request Date" field="created_at"></Column>
        <Column header="Status" body={statusBodyTemplate}></Column>
      </DataTable>
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={totalItems}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </div>
  );
};

export default UserRequestsDT;
