import { createSlice } from '@reduxjs/toolkit';
import { AddHotelAction, DeleteHotelAction, GetHotelAction, UpdateHotelAction } from '../../actions/hotel/HotelAction';
export const hotelReducer = createSlice({
    name: "Hotel Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        hotels: [],
    },
    reducers: {
        hotelUnmount: (state) => {
            state.hotels = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetHotelAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetHotelAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                try {
                    if (payload.data.data === undefined) {
                        state.hotels = payload.data;
                    } else {
                        state.hotels = payload.data.data;
                    }
                    state.totalItems = payload.data.total;
                    state.currentPage = payload.data.current_page;
                } catch (err) {
                    // console.log(err)
                }
            })
            .addCase(GetHotelAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(AddHotelAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(AddHotelAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(AddHotelAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(UpdateHotelAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(UpdateHotelAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(UpdateHotelAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(DeleteHotelAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(DeleteHotelAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(DeleteHotelAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

    }
});
export default hotelReducer.reducer;
export const { hotelUnmount } = hotelReducer.actions;
