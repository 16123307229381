import { createSlice } from '@reduxjs/toolkit';
import { BlockUserAction, GetUsersAction, SearchUserAction, ShowUserAction } from '../actions/UserAction';
export const userReducer = createSlice({
    name: "User Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        users: [],
        userInfo: [],
    },
    reducers: {
        userUnmount: (state) => {
            state.userInfo = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetUsersAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetUsersAction.fulfilled, (state, { payload }) => {
                // console.log(payload,)
                if (payload.data.data === undefined) {
                    state.users = payload.data;
                } else {
                    state.users = payload.data.data;
                }
                state.loading = false;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetUsersAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(BlockUserAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(BlockUserAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
            .addCase(BlockUserAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(ShowUserAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(ShowUserAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.userInfo = payload.data;
                state.loading = false;
            })
            .addCase(ShowUserAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

            .addCase(SearchUserAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(SearchUserAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.users = payload.data;
                state.loading = false;
            })
            .addCase(SearchUserAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })
    }
});

export default userReducer.reducer;
export const { userUnmount } = userReducer.actions; 
