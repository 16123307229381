import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Image } from "primereact/image";
import { imageURL } from "../../../redux/Api";
import ClientsExplore from "../../maps/ClientsExplore";
import { Button } from "primereact/button";
import { formatNumberWithCommas } from "../../../utils/Formatters";
import "../../../assets/pages/show-request/ShowRequest.scss";
import { decodeBase64 } from "../../../utils/EncodeDecode";

const ShowVisaRequest = ({ toast }) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const encodedData = params.get("data");
  const decodedData = JSON.parse(decodeBase64(encodedData));
  const navigate = useNavigate();

  return (
    <div className="show-request">
      <div className="image-containers">
        <Button
          onClick={() => {
            navigate(-1);
          }}
          icon="pi pi-arrow-left"
          className="backward"
          rounded
          label="Back"
          text
          aria-label="Filter"
          severity="info"
        />
        <div className="passport-image">
          <h3>Visa Information</h3>
          <div className="info">
            <div>
              <strong>Type: </strong> {decodedData.type.name} |{" "}
              {decodedData.type.name_ar}
            </div>
            <div>
              <strong>Period: </strong> {decodedData.period}
            </div>
            <div>
              <strong>Price: </strong>
              {formatNumberWithCommas(decodedData.price)}
            </div>
            <div>
              <strong>User Name: </strong> {decodedData.user?.first_name}{" "}
              {decodedData.user?.last_name}
            </div>
            <div>
              <strong>User Phone: </strong> {decodedData.user?.phone_number}
            </div>
            <div>
              <strong>Country: </strong> {decodedData.city?.name}
            </div>
          </div>
        </div>
        {decodedData.location && (
          <ClientsExplore places={[decodedData?.location]} toast={toast} />
        )}
        <div className="passport-image">
          <h3>Profile Image</h3>
          <Image
            className="image"
            src={imageURL + decodedData.personal_page}
            alt="personal_page"
            loading="lazy"
            preview
            downloadable
            children={document.querySelector(".passport-image")}
          />
        </div>
        <div className="passport-image">
          <h3>Passport First Image</h3>
          <Image
            className="image"
            src={imageURL + decodedData.passport_first_page}
            alt="passport_first_page"
            loading="lazy"
            preview
            downloadable
            children={document.querySelector(".passport-image")}
          />
        </div>
        <div className="passport-image">
          <h3>Passport Second Image</h3>
          <Image
            className="image"
            src={imageURL + decodedData.passport_second_page}
            alt="passport_second_page"
            loading="lazy"
            preview
            downloadable
            children={document.querySelector(".passport-image")}
          />
        </div>
        {decodedData.ticket_images.length !== 0 && (
          <div className="ticket-images">
            <h3>Document Images</h3>
            {decodedData.ticket_images.map((item, index) => {
              return (
                <Image
                  key={index}
                  className="image"
                  src={imageURL + item.path}
                  alt="document_page"
                  loading="lazy"
                  preview
                  downloadable
                  children={document.querySelector(".passport-image")}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ShowVisaRequest;
