import { createSlice } from '@reduxjs/toolkit';
import { GetFeedbacksAction } from '../actions/FeedbackAction';
export const feedbackReducer = createSlice({
    name: "Feedback Reducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        feedbacks: [],
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(GetFeedbacksAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetFeedbacksAction.fulfilled, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
                try {
                    if (payload.data.data === undefined) {
                        state.feedbacks = payload.data;
                    } else {
                        state.feedbacks = payload.data.data;
                    }
                    state.totalItems = payload.data.total;
                    state.currentPage = payload.data.current_page;
                } catch (err) {
                    // console.log(err)
                }
            })
            .addCase(GetFeedbacksAction.rejected, (state, { payload }) => {
                // console.log(payload)
                state.loading = false;
            })

    }
});
export default feedbackReducer.reducer; 