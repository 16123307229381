import React from "react";
import LoginForm from "./LoginForm";
import "./Login.scss";
const Login = ({ toast }) => {
  return (
    <div className="login">
      <LoginForm toast={toast} />
    </div>
  );
};

export default Login;
