import React, { useEffect, useRef, useState } from "react";
import { onMessageListener, requestPermission } from "../../firebase";
import { showSuccessNotification } from "../../utils/ToastService";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const toast = useRef(null);
  const [type, setType] = useState();
  const navigate = useNavigate();

  const notificationRedirection = () => {
    switch (type) {
      case "0":
        navigate("/transfer-requests#10");
        break;
      case "1":
        navigate("/flight-requests#10");
        break;
      case "2":
        navigate("/hotel-requests#10");
        break;
      case "4":
        navigate("/visa-requests#10");
        break;
      case "5":
        navigate("/travel-insurance-requests#10");
        break;
      case "6":
        navigate("/transfer-requests-log");
        break;
      case "7":
        navigate("/flight-requests-log");
        break;
      case "8":
        navigate("/hotel-requests-log");
        break;
      case "9":
        navigate("/travel-insurance-requests-log");
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    requestPermission();
    const handleNotification = (payload) => {
      // console.log(payload);
      setType(payload?.data?.type);
      showSuccessNotification(
        payload?.notification?.title,
        payload?.notification?.body,
        toast
      );
    };

    onMessageListener(handleNotification);
  }, [type]);

  return (
    <Toast
      className="notification"
      ref={toast}
      position="top-right"
      onClick={notificationRedirection}
    />
  );
};

export default Notification;
