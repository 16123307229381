import React, { useState } from "react";
import { MapContainer, Marker, TileLayer, Popup } from "react-leaflet";
import { SearchField } from "./SeacrchField";
import "leaflet-geosearch/dist/geosearch.css";
import { showInfo } from "../../utils/ToastService";
import { Button } from "primereact/button";
import "./ClientExplore.scss";

const ClientsExplore = ({ places, toast }) => {
  const [, setPosition] = useState({ lat: 33.492021, lng: 36.332652 });
  const lightTheme = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
  // Function to generate shareable link
  const generateGoogleMapsLink = (lat, lng) => {
    const baseUrl = "https://www.google.com/maps/search/?api=1";
    const params = new URLSearchParams({ query: `${lat},${lng}` });
    return `${baseUrl}&${params.toString()}`;
  };

  // Function to handle copying link to clipboard
  const handleCopyLink = (lat, lng) => {
    const link = generateGoogleMapsLink(lat, lng);
    navigator.clipboard
      .writeText(link)
      .then(() => {
        showInfo("Link location copied to clipboard!", toast);
      })
      .catch((error) => {
        // console.error("Error copying link to clipboard:", error);
      });
  };

  return (
    <div className="client-explore">
      <div>
        <MapContainer
          center={{ lat: places[0].lat, lng: places[0].lng }}
          zoom={10}
          attributionControl={true}
          zoomControl={true}
          doubleClickZoom={true}
          scrollWheelZoom={true}
          dragging={true}
          animate={true}
          easeLinearity={0.35}
        >
          <TileLayer url={lightTheme} attribution="OpenStreetMap" />
          {places?.map((item, index) => {
            return (
              <Marker
                key={index}
                position={{ lat: item.lat, lng: item.lng }}
                draggable={false}
              >
                <Popup>
                  <div className="card-information">
                    <h6 className="card-title">{item.title}</h6>
                    <Button
                      tooltip="Share Location"
                      className="p-button-rounded p-button-text p-button-info"
                      icon="pi pi-share-alt"
                      size="small"
                      onClick={(e) => {
                        e.preventDefault();
                        handleCopyLink(item.lat, item.lng);
                      }}
                    />
                  </div>
                </Popup>
              </Marker>
            );
          })}

          <SearchField
            setPosition={(e1, e2) => setPosition({ lat: e1, lng: e2 })}
            apiKey={"38ab25a725154cf192acbe16aa4624e4"}
          />
        </MapContainer>
      </div>
    </div>
  );
};

export default ClientsExplore;
