import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { dtTranstion, paginatorSize } from "../../redux/constant";
import { Link } from "react-router-dom";
import { GetCountriesAction } from "../../redux/actions/CountryAction";
import { InputText } from "primereact/inputtext";
import "../../assets/pages/utils/datatable.scss";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError } from "../../utils/ToastService";

const CountryDT = ({ createModal, deleteModal, updateModal, toast }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const [nameFilter, setNameFilter] = useState("");
  const { totalItems, countries } = useSelector((state) => state.countries);
  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  useEffect(() => {
    let info = { page: currentPage, size: paginatorSize, isPaginate: 1 };
    dispatch(GetCountriesAction(info))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  }, [dispatch, currentPage, toast]);

  const searchHandler = (event) => {
    if (event.key === "Enter") {
      let info = {
        page: currentPage,
        size: paginatorSize,
        isPaginate: 0,
        name: nameFilter,
      };
      dispatch(GetCountriesAction(info))
        .then(unwrapResult)
        .catch((error) => {
          showError(error, toast);
        });
    }
  };

  const renderSearch = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <InputText
            unstyled
            className="custom-input"
            value={nameFilter}
            onChange={(e) => {
              setNameFilter(e.target.value);
            }}
            placeholder="Country Search"
            onKeyDown={searchHandler}
          />
        </span>
      </div>
    );
  };

  const header = (
    <div className="header">
      <span className="title">Countries</span>
      {renderSearch()}
      <Button
        size="small"
        icon="pi pi-plus"
        onClick={() => {
          createModal(true, undefined, basicRows);
        }}
        rounded
        text
        raised
        aria-label="Favorite"
      />
    </div>
  );
  const acitonBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          size="small"
          icon="pi pi-trash"
          className="p-button-rounded p-button-text p-button-danger"
          aria-label="Submit"
          onClick={() => {
            deleteModal(true, rowData, basicRows);
          }}
        />
        <Button
          size="small"
          icon="pi pi-pencil"
          className="p-button-rounded p-button-text p-button-primary"
          aria-label="Submit"
          onClick={() => {
            updateModal(true, rowData, basicRows);
          }}
        />
        <Link
          to={{
            pathname: `cities/${rowData.id}`,
            hash: `${rowData.name}`,
          }}
        >
          <Button
            size="small"
            icon="pi pi-eye"
            className="p-button-rounded p-button-text p-button-success"
            aria-label="Submit"
          />
        </Link>
      </>
    );
  };
  return (
    <div className={`datatable ${dtTranstion}`}>
      <DataTable stripedRows header={header} value={countries}>
        <Column sortable field="id" header="ID"></Column>
        <Column field="name" header="Name En"></Column>
        <Column field="name_ar" header="Name Ar"></Column>
        <Column
          align={"center"}
          body={acitonBodyTemplate}
          header="Actions"
        ></Column>
      </DataTable>
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={totalItems}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </div>
  );
};

export default CountryDT;
