import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AddGroupAction,
  GetGroupsAction,
} from "../../../redux/actions/GroupAction";
import { showError, showSuccess } from "../../../utils/ToastService";
import { unwrapResult } from "@reduxjs/toolkit";

const AddGroup = ({ basicRows, createModal, toast }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.group);
  const [grupeName, setGroupName] = useState("");
  const submitHandler = (e) => {
    e.preventDefault();
    let obj = new FormData();
    let info = { size: basicRows, page: currentPage, isPaginate: 1 };
    obj.append("name", grupeName);

    dispatch(AddGroupAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        console.log(payload);
        if (payload?.data) {
          showSuccess(payload.message, toast);
          createModal(false);
          dispatch(GetGroupsAction(info));
        } else {
          showError(payload?.message, toast);
        }
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="add-group" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            unstyled
            className="custom-input"
            type="text"
            placeholder="Group Name"
            onChange={(e) => setGroupName(e.target.value)}
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="button"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default AddGroup;
