import React, { useState } from "react";
import VisasDT from "./VisasDT";
import AddVisa from "./actions/AddVisa";
import { Dialog } from "primereact/dialog";
import UpdateVisa from "./actions/UpdateVisa";
import DeleteVisa from "./actions/DeleteVisa";
import LoadingFS from "../../components/loading/LoadingFS";
import { useDispatch, useSelector } from "react-redux";
import { GetCountriesAction } from "../../redux/actions/CountryAction";
import { showError } from "../../utils/ToastService";
import VisaTypes from "./actions/VisaTypes";
import { GetVisaTypesAction } from "../../redux/actions/visa/VisaAction";
import { unwrapResult } from "@reduxjs/toolkit";
import "./Visas.scss";

const Visas = ({ toast }) => {
  const dispatch = useDispatch();
  const [createModal, setCreateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [additionModal, setAdditionModal] = useState(false);
  const [basicRows, setBasicRows] = useState("");
  const [rowData, setRowData] = useState(false);
  const { countries } = useSelector((state) => state.countries);
  const countriesLoading = useSelector((state) => state.countries.loading);
  const { loading } = useSelector((state) => state.visas);

  const getCountriesHandler = () => {
    let info = { isPaginate: 0 };
    dispatch(GetCountriesAction(info))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });

    getVisaTypesHandler();
  };
  const getVisaTypesHandler = () => {
    dispatch(GetVisaTypesAction({ isPaginate: 0 }))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="sticky-container visas">
      {(loading || countriesLoading) && <LoadingFS />}
      <VisasDT
        toast={toast}
        additionModal={(visible, rowData, basicRows) => {
          setAdditionModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        createModal={(visible, rowData, basicRows) => {
          setCreateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        deleteModal={(visible, rowData, basicRows) => {
          setDeleteModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
        updateModal={(visible, rowData, basicRows) => {
          setUpdateModal(visible);
          setRowData(rowData);
          setBasicRows(basicRows);
        }}
      />

      <Dialog
        header="Add Visa"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={createModal}
        onHide={() => setCreateModal(false)}
        onShow={getCountriesHandler}
      >
        <AddVisa
          countries={countries}
          basicRows={basicRows}
          createModal={(e) => setCreateModal(e)}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Update Visa"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={updateModal}
        onHide={() => setUpdateModal(false)}
        onShow={getCountriesHandler}
      >
        <UpdateVisa
          countries={countries}
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          updateModal={(e) => setUpdateModal(e)}
        />
      </Dialog>

      <Dialog
        closeOnEscape
        header="Delete Visa"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={deleteModal}
        onHide={() => setDeleteModal(false)}
      >
        <DeleteVisa
          rowData={rowData}
          basicRows={basicRows}
          toast={toast}
          deleteModal={(e) => setDeleteModal(e)}
        />
      </Dialog>

      <Dialog
        header="Visa Types"
        dismissableMask
        resizable={false}
        appendTo={"self"}
        visible={additionModal}
        onHide={() => setAdditionModal(false)}
        onShow={getVisaTypesHandler}
      >
        <VisaTypes
          basicRows={basicRows}
          additionModal={(e) => setAdditionModal(e)}
          toast={toast}
        />
      </Dialog>
    </div>
  );
};

export default Visas;
