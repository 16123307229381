import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../constant";
import { AddGroupAPI, AssignPermissionsAPI, DeleteGroupAPI, GetGroupsAPI, GetPermissionsExceptAPI, ShowGroupAPI, UpdateGroupAPI, } from "../Api";


export const GetGroupsAction = createAsyncThunk("admin/group/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetGroupsAPI + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddGroupAction = createAsyncThunk("admin/group/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddGroupAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateGroupAction = createAsyncThunk("admin/group/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(UpdateGroupAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteGroupAction = createAsyncThunk("admin/group/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteGroupAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const ShowGroupAction = createAsyncThunk("admin/group/show", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(ShowGroupAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AssignPermissionsAction = createAsyncThunk("admin/group/assign", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AssignPermissionsAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetPermissionsExceptAction = createAsyncThunk("admin/group/get/except", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(GetPermissionsExceptAPI, info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});