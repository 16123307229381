import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./NotificationCard.scss";
import visaIcon from "../../assets/images/visa_notification.png";
import transferIcon from "../../assets/images/transfer_notification.png";
import hotelIcon from "../../assets/images/hotel_notification.png";
import flightIcon from "../../assets/images/flight_notification_icon.png";
import insuranceIcon from "../../assets/images/insurance_notification.png";
import {
  GetNotificationsAction,
  ReadNotificationAction,
} from "../../redux/actions/NotificationsAction";
import { useDispatch } from "react-redux";
import { paginatorSize } from "../../redux/constant";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError } from "../../utils/ToastService";

const NotificationCard = ({
  id,
  title,
  message,
  type,
  isValid,
  createdAt,
  toast,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [icon, setIcon] = useState("");
  useEffect(() => {
    switch (type) {
      case 0:
      case 6:
        setIcon(transferIcon);
        break;
      case 1:
      case 7:
        setIcon(flightIcon);
        break;
      case 2:
      case 8:
        setIcon(hotelIcon);
        break;
      case 4:
        setIcon(visaIcon);
        break;
      case 5:
      case 9:
        setIcon(insuranceIcon);
        break;
      default:
        break;
    }
  }, [type]);

  const notificationRedirection = () => {
    switch (type) {
      case 0:
        return "/transfer-requests#10";
      case 1:
        return "/flight-requests#10";
      case 2:
        return "/hotel-requests#10";
      case 4:
        return "/visa-requests#10";
      case 5:
        return "/travel-insurance-requests#10";
      case 6:
        return "/transfer-requests-log";
      case 7:
        return "/flight-requests-log";
      case 8:
        return "/hotel-requests-log";
      case 9:
        return "/travel-insurance-requests-log";
      default:
        break;
    }
  };
  const showRequest = () => {
    dispatch(ReadNotificationAction(id))
      .then(unwrapResult)
      .then((_) => {
        let info = { size: paginatorSize, page: 0, isPaginate: 1 };
        dispatch(GetNotificationsAction(info))
          .then(unwrapResult)
          .catch((error) => {
            showError(error, toast);
          });
      })
      .catch((error) => {
        showError(error, toast);
      });
    navigate(`${notificationRedirection()}`);
  };
  return (
    <div
      className={`notification-card ${
        parseInt(isValid) === 1 ? "unread" : "read"
      }`}
    >
      <div className="header">
        <div className="left">
          <div className="circle">.</div>
          <div className="title-icon">
            <img src={icon} alt="blue-ocean-ico" />
            <h2 className="title">{title}</h2>
          </div>
        </div>
        <Button
          onClick={showRequest}
          size="small"
          icon="pi pi-eye"
          className="p-button-rounded p-button-text p-button-primary"
          aria-label="Submit"
        />
      </div>
      <p className="notification-card__message">{message}</p>
      <div className="date">
        {/* <i className="pi pi-clock"></i> */}
        <p>{createdAt}</p>
      </div>
    </div>
  );
};

export default NotificationCard;
