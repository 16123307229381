import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../assets/pages/utils/datatable.scss";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { dtTranstion, paginatorSize } from "../../redux/constant";
import {
  GetUsersAction,
  SearchUserAction,
} from "../../redux/actions/UserAction";
import { Tag } from "primereact/tag";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { getSeverity } from "../../utils/Severity";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError } from "../../utils/ToastService";

const UsersDT = ({ blockModal, toast }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [basicFirst, setBasicFirst] = useState(0);
  const [basicRows, setBasicRows] = useState(paginatorSize);
  const [phoneFilter, setPhoneFilter] = useState("");
  const { totalItems, users } = useSelector((state) => state.users);
  const onBasicPageChange = (event) => {
    let currentPage = event.page + 1;
    setCurrentPage(currentPage);
    setBasicFirst(event.first);
    setBasicRows(event.rows);
  };

  useEffect(() => {
    let info = { page: currentPage, size: paginatorSize, isPaginate: 1 };
    dispatch(GetUsersAction(info))
      .then(unwrapResult)
      .catch((error) => {
        showError(error, toast);
      });
  }, [dispatch, currentPage, toast]);

  const searchHandler = (event) => {
    if (event.key === "Enter") {
      dispatch(SearchUserAction(phoneFilter))
        .then(unwrapResult)
        .catch((error) => {
          showError(error, toast);
        });
    }
  };

  const renderSearch = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <InputText
            unstyled
            className="custom-input"
            value={phoneFilter}
            onChange={(e) => {
              setPhoneFilter(e.target.value);
            }}
            placeholder="Number Search"
            onKeyDown={searchHandler}
          />
        </span>
      </div>
    );
  };

  const header = (
    <div className="header">
      <span className="title">Users</span>
      {renderSearch()}
    </div>
  );

  const acitonBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          size="small"
          tooltip={rowData.blocked ? "Unblock" : "Block"}
          tooltipOptions={{ position: "top" }}
          icon="pi pi-ban"
          className="p-button-rounded p-button-text p-button-danger"
          aria-label="Submit"
          onClick={() => {
            blockModal(true, rowData, basicRows);
          }}
        />
        <Link to={{ pathname: `${rowData.id}`, search: rowData.phone_number }}>
          <Button
            size="small"
            icon="pi pi-eye"
            className="p-button-rounded p-button-text p-button-success"
            aria-label="Submit"
          />
        </Link>
      </>
    );
  };
  const blockStatusHandler = (rowData) => {
    switch (rowData.blocked) {
      case 0:
        return (
          <Tag value="Active" severity={getSeverity(rowData.blocked)}></Tag>
        );
      case 1:
        return (
          <Tag value="Blocked" severity={getSeverity(rowData.blocked)}></Tag>
        );
      default:
        break;
    }
  };
  return (
    <div className={`datatable ${dtTranstion}`}>
      <DataTable stripedRows header={header} value={users}>
        <Column
          body={(e) => {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {e.vip ? (
                  <i
                    className="bi bi-star-fill"
                    style={{
                      color: "gold",
                      margin: "5px",
                    }}
                  ></i>
                ) : null}
                <h5>{e.id}</h5>
              </div>
            );
          }}
          header="ID"
          sortable
        ></Column>
        <Column field="phone_number" header="Phone Number"></Column>
        <Column field="first_name" header="First Name"></Column>
        <Column field="last_name" header="Last Name"></Column>
        <Column body={blockStatusHandler} header="State"></Column>
        <Column
          align={"center"}
          body={acitonBodyTemplate}
          header="Actions"
        ></Column>
      </DataTable>
      <Paginator
        first={basicFirst}
        rows={basicRows}
        totalRecords={totalItems}
        onPageChange={onBasicPageChange}
      ></Paginator>
    </div>
  );
};

export default UsersDT;
