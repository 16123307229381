import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showError, showSuccess } from "../../../utils/ToastService";
import {
  AddCarAction,
  GetCarsAction,
} from "../../../redux/actions/car/CarAction";
import { unwrapResult } from "@reduxjs/toolkit";

const AddCar = ({ categoryId, basicRows, createModal, toast }) => {
  const dispatch = useDispatch();
  const { loading, currentPage } = useSelector((state) => state.cars);
  const [name, setName] = useState("");
  const submitHandler = (e) => {
    e.preventDefault();
    let formData = new FormData();
    let info = {
      categoryId: categoryId,
      size: basicRows,
      page: currentPage,
      isPaginate: 1,
    };
    formData.append("name", name);
    let obj = { id: categoryId, obj: formData };
    dispatch(AddCarAction(obj))
      .then(unwrapResult)
      .then((payload) => {
        showSuccess(payload.message, toast);
        createModal(false);
        dispatch(GetCarsAction(info));
      })
      .catch((error) => {
        showError(error, toast);
      });
  };
  return (
    <div className="container">
      <form className="add-category" onSubmit={submitHandler}>
        <div className="input-wrapper">
          <InputText
            unstyled
            className="custom-input"
            type="text"
            placeholder="Car Type"
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <span className="actions">
          <Button
            size="small"
            className="faded"
            label="Cancel"
            icon="pi pi-times"
            onClick={() => createModal(false)}
            type="button"
          />
          <Button
            size="small"
            className="info"
            label="Add"
            icon="pi pi-check"
            type="submit"
            loading={loading}
          />
        </span>
      </form>
    </div>
  );
};

export default AddCar;
