import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance, { errorMessage } from "../../constant";
import { AddCarAPI, DeleteCarAPI, GetCarsAPI, UpdateCarAPI } from "../../Api";

export const GetCarsAction = createAsyncThunk("admin/cars/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetCarsAPI + info.categoryId + "?page=" + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetFetchedCarsAction = createAsyncThunk("admin/fetched/cars/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(GetCarsAPI + info.categoryId + "?isPaginate=" + info.isPaginate);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const AddCarAction = createAsyncThunk("admin/car/add", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(AddCarAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const UpdateCarAction = createAsyncThunk("admin/car/update", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(UpdateCarAPI + info.id, info.obj);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const DeleteCarAction = createAsyncThunk("admin/car/delete", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.delete(DeleteCarAPI + info);
        return data;
    } catch (error) {
        if (error.response) {
            const validationMessage = error.response.data.message;
            return rejectWithValue(validationMessage);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});