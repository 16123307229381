import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
const LineChart = ({ toast, data, type }) => {
  return (
    <div className="line-chart">
      <ResponsiveContainer aspect={6 / 2}>
        <AreaChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Area
            type={type === 0 ? "monotone" : "linear"}
            dataKey={type === 0 ? "sum_request" : "num_request"}
            stroke="#26a7df"
            fill="#26a7df"
            name={type === 0 ? "Revenues" : "Requests Number"}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineChart;
